import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconLabelSet from '../_components/IconLabelSet';
import { RT } from '../_constants';
import { FileTab, FolderTab } from '../Utils/SvgIcons';

export default function FileTabs() {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    if (path === RT.FOLDERLIST) {
      return (
        location.pathname.includes(RT.FOLDERLIST) || location.pathname.includes(`${RT.FILELIST}/`)
      );
    }
    if (path === RT.FILELIST) {
      return location.pathname === `/${RT.FILESYSTEM}/${RT.FILELIST}`;
    }
    return false;
  };

  return (
    <section className='file_tabs' style={{ borderBottom: '1px solid var(--border-color)' }}>
      {/* <div className='file-tab-line' /> */}
      <div className='file-tab-contain'>
        <div
          style={{ zIndex: '2', padding: '1px 1px 13px 1px' }}
          className={`${isActive(RT.FILELIST) ? 'tab_active' : 'tab_inactive'}`}
          onClick={() => navigate(`/${RT.FILESYSTEM}/${RT.FILELIST}`)}
        >
          <IconLabelSet
            label={'Files'}
            color={`${isActive(RT.FILELIST) ? '#2A2E34' : '#87909E'}`}
            icon={<FileTab color={`${isActive(RT.FILELIST) ? 'var(--icon-light)' : '#87909E'}`} />}
          />
        </div>
        <div
          style={{ zIndex: '2', padding: '1px 1px 13px 1px' }}
          className={`${isActive(RT.FOLDERLIST) ? 'tab_active' : 'tab_inactive'}`}
          onClick={() => navigate(`/${RT.FILESYSTEM}/${RT.FOLDERLIST}`)}
        >
          <IconLabelSet
            label={'Folder'}
            color={`${isActive(RT.FOLDERLIST) ? '#2A2E34' : '#87909E'}`}
            icon={<FolderTab color={`${isActive(RT.FOLDERLIST) ? 'var(--icon-light)' : '#87909E'}`} />}
          />
        </div>
      </div>
    </section>
  );
}
