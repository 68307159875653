/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { Calendar, Check, Hourglass, Minus, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import { Popover } from '../../_components/Popover';
import ToolTip from '../../_components/Tooltip/ToolTip';
import UserDropDown from '../../_components/UserDropDown';
import { apiConstants, MENU } from '../../_constants';
import { VALIDATION_VALUES } from '../../_constants/template.constants';
import useComponentInteractionHandler from '../../Hook/useComponentInteractionHandler';
import { GlobalContext } from '../../Utils';
import { Close, Description, Label, Priority } from '../../Utils/SvgIcons';
import {
  CustomEditor,
  CustomSelectModal,
  CustomTimeInput,
  CustomVariableInput,
  SearchInput,
} from '../TaskComponents/CustomInputs';
import { formMode, taskValidationConstant } from '../taskConstants';
import {
  getHours,
  getPriorityByContextAPI,
  getStartDateMilliSecond,
  validateHours,
} from '../taskValidation';

export function ModifiedInlineTaskAdd({
  tableRef,
  taskData,
  setTaskData,
  templateData, // selectedTemplate
  onAdd = () => {},
  onCancel = () => {},
  onActiveForm,
  isRelatedTask = false,
  setIsAddRowActive = () => {},
  componentMode = formMode.FORM,

  editExisting = false,
  isUpdateButtonVisible = false,
  isLoading = false,
  // lists for select
  userList = [],
  labelList = [],
  userSearch = '',
  setSearch, // update search key of user list
  setLabelSearch,
  labelSearch = '',
  verifyUserData = [], // accepts {label,value,...} pairs
  validationData = [],
  isMenuLabelOn = false,
  isMenuSubtaskOn = false,
  onAssigneeChange = () => {},
  clientList = [],
  setClientSearch,
  clientDetails,
}) {
  const validation_fields = templateData?.validation_fields;
  const [isAddRowVisible, setIsAddRowVisible] = useState(false); //state for add menu visibility
  // const [validationData, setValidationData] = useState([]); //state for add tags visibility
  const priorityLabel = getPriorityByContextAPI(); // to fetch the priority  label from context api
  const { makeAlert } = useContext(GlobalContext);

  const [error, setError] = useState(false);

  const updateFocusTime = useCallback(() => {
    setTaskData((prev) => ({
      ...prev,
      task_start_date: moment().valueOf(),
      task_end_date: moment().endOf('day').valueOf(),
    }));
    // return moment();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const { data: focusTime } = useQuery({
    queryKey: ['lab'],
    queryFn: updateFocusTime,
    // refetchInterval: 1000, // Update every second when focused
    //  refetchIntervalInBackground: false, // Don't update when not focused
    refetchOnWindowFocus: true, // Refetch when window regains focus
  });

  // const [isAddTemplateVisible, setIsAddTemplateVisible] = useState(false); //state for add menu visibility
  const hasRequestTime =
    Boolean(parseInt(taskData?.expected_hours)) || Boolean(parseInt(taskData?.expected_minutes));

  // Extract query parameters outside of useEffect
  const queryParams = new URLSearchParams(location.search);
  const list_Type = queryParams.get('list_type'); // Extract 'list_type'

  useEffect(() => {
    if (onActiveForm) {
      setIsAddRowVisible(true);
      setIsAddRowActive(true);
    }

    if (taskData?.template_id || list_Type) {
      setIsAddRowVisible(false);
      setIsAddRowActive(false);
    }
  }, [onActiveForm, taskData?.template_id, list_Type]); // 'list_Type' added as dependency

  const handleChange = (e) => {
    let { name, value, type } = e.target;
    if (name == 'task_start_date' || name == 'task_end_date') {
      value = getStartDateMilliSecond(value);
    } else {
      if (name == 'expected_hours') {
        // to validate the hours according to the start - end dates
        const isValidHour = validateHours(
          taskData?.task_start_date,
          taskData?.task_end_date,
          value,
        );
        if (isValidHour) {
          let time = parseInt(value);
          value = time;
        } else {
          setError(true);
          makeAlert(taskValidationConstant.INVALID_TASK_DATES);
          errorTimeout();
          return;
        }
      } else if (name == 'expected_minutes') {
        let time = parseInt(value);
        value = time;
      }
    }
    if (onActiveForm && isRelatedTask && !editExisting) {
      setTaskData({ [name]: value });
      return;
    }

    if (name === 'label') {
      setTaskData((prev) => {
        const currentLabelDetails = prev.labelDetails || [];
        const currentLabels = prev.label || [];
        const isLabelSelected = currentLabels.includes(value.id);
        const updatedLabels = isLabelSelected
          ? currentLabels.filter((id) => id !== value.id)
          : [...currentLabels, value.id];
        const updatedLabelDetails = isLabelSelected
          ? currentLabelDetails.filter((item) => item.id !== value.id)
          : [...currentLabelDetails, value];
        return { ...prev, label: updatedLabels, labelDetails: updatedLabelDetails };
      });
      return;
    }
    setTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const errorTimeout = () => {
    setTimeout(() => {
      // setValidationData([]);
      setError(false);
    }, 3000);
  };
  // to add new task row - for related tasks
  const addNewRow = () => {
    if (taskData?.expected_hours || taskData?.expected_minutes) {
      taskData.expected_hours = getHours(taskData); // get hours by hours and minutes
    }
    if (clientDetails && !taskData.client_id) {
      taskData.client_id = clientDetails;
    }
    const isValidHour = validateHours(
      taskData?.task_start_date,
      taskData?.task_end_date,
      taskData.expected_hours,
    ); // to validate the hours according to the start - end dates
    if (isValidHour) {
      let time = parseInt(taskData.expected_hours);
      taskData.expected_hours = time;
    } else {
      makeAlert(taskValidationConstant.INVALID_EXPECTED_TIME);
      return;
    }
    onAdd(setIsAddRowVisible, setIsAddRowActive);
  };

  // to cancel add modal remove the validated value
  const onClickCancel = () => {
    setIsAddRowVisible(false);
    setIsAddRowActive(false);
    // setValidationData([]);
    onCancel();
  };

  const filteredLabelList = labelList?.filter(
    (item) =>
      item.name.toLowerCase().includes(labelSearch.toLowerCase()) &&
      !taskData.labelDetails?.some((selectedItem) => selectedItem.id === item.id),
  );

  // for get selected user info
  const selectedUserIsMale = taskData?.assigned_user?.gender === 1;
  const selectedUserIsNeutral = taskData?.assigned_user?.gender === 3;
  const url = taskData?.assigned_user?.image_url;
  const selectedUserImageFinalUrl = url ? apiConstants.imgUrlS3 + url : null;

  const blocks = taskData?.note?.blocks;
  const descriptionValue = blocks
    ? blocks?.map((block) => (!block?.text?.trim() && '\n') || block?.text).join('\n')
    : ' ';

  const imgUrl = taskData?.assigned_user?.image_url;
  const finalImgUrl = imgUrl ? apiConstants.imgUrlS3 + imgUrl : null;

  if (isAddRowVisible) {
    return (
      <form>
        <div className='d-flex flex-wrap gap-1 my-1 '>
          <div className='tab-click' tabIndex='0'>
            <CustomVariableInput
              name='name'
              placeholder='Enter Task Name'
              value={taskData?.name}
              onChange={(event) => handleChange(event)}
              className={`inline-task-name-box fz-13px-rem tab-input-focus
                ${validationData?.includes('name') ? ' has-error' : ''}`}
            />
          </div>

          {/* description section  */}

          <InlineCustomEditor
            descriptionValue={descriptionValue}
            handleChange={handleChange}
            tableRef={tableRef}
            taskData={taskData}
            validationData={validationData}
            validation_fields={validation_fields}
          />

          {/* section of priority  */}

          <InlinePriorityDropDown
            handleChange={handleChange}
            priorityLabel={priorityLabel}
            setTaskData={setTaskData}
            tableRef={tableRef}
            taskData={taskData}
            validationData={validationData}
            validation_fields={validation_fields}
          />

          {/* end of section  priority  */}

          {/* date selection section  */}

          <InlineDatePicker
            handleChange={handleChange}
            tableRef={tableRef}
            taskData={taskData}
            validationData={validationData}
            validation_fields={validation_fields}
          />

          {/* assign user section  */}

          <InlineAssigneeUserPicker
            handleChange={onAssigneeChange}
            selectedUserImageFinalUrl={selectedUserImageFinalUrl}
            selectedUserIsMale={selectedUserIsMale}
            selectedUserIsNeutral={selectedUserIsNeutral}
            setSearch={setSearch}
            tableRef={tableRef}
            taskData={taskData}
            userList={userList}
            userSearch={userSearch}
            validationData={validationData}
          />

          {/* contact selection section  */}

          {validation_fields?.client_id == VALIDATION_VALUES.REQUIRED && (
            <InlineContactPicker
              handleChange={handleChange}
              setSearch={setClientSearch}
              tableRef={tableRef}
              taskData={taskData}
              clientList={clientList}
              validationData={validationData}
            />
          )}

          {/* section for time  */}

          <InlineExpectedTimePicker
            error={error}
            handleChange={handleChange}
            hasRequestTime={hasRequestTime}
            tableRef={tableRef}
            taskData={taskData}
            validationData={validationData}
            validation_fields={validation_fields}
          />

          {/* label selection section  */}
          {isMenuLabelOn && (
            <InlineLabelPicker
              error={error}
              filteredLabelList={filteredLabelList}
              handleChange={handleChange}
              setLabelSearch={setLabelSearch}
              tableRef={tableRef}
              taskData={taskData}
            />
          )}

          <div className='col me-1 ms-3' hidden={componentMode == formMode.INLINE}>
            {/* <div className='col mx-1' > */}

            <div className='d-flex gap-1 justify-content-end'>
              <ToolTip tooltipText={'Close'} scrollRef={tableRef} popOver isModern>
                <div
                  tabIndex='0'
                  className='tab-click inline-task-item-container'
                  onClick={() => onClickCancel()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onClickCancel();
                    }
                  }}
                >
                  <Close />
                </div>
              </ToolTip>

              <ToolTip tooltipText={'Submit'} scrollRef={tableRef} popOver isModern>
                <div
                  tabIndex='0'
                  className={`tab-click inline-task-item-container inline-submit ${
                    isLoading ? 'disabled' : ''
                  }`}
                  style={{}}
                  onClick={() => {
                    if (!isLoading) {
                      addNewRow();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (!isLoading) {
                        addNewRow();
                      }
                    }
                  }}
                >
                  <Check size={20} />
                </div>
              </ToolTip>
            </div>
          </div>
        </div>
      </form>
    );
  }

  return (
    <div className='w-100 d-flex align-items-center '>
      <ToolTip tooltipText={'Add Inline task'} scrollRef={tableRef} popOver isModern>
        <div
          role='button'
          onClick={() => {
            setIsAddRowVisible(true);
            setIsAddRowActive(true);
          }}
          className='pe-1 py-1 w-fit-content d-flex align-items-center gap-1 btn-wrapper my-1 '
        >
          <Plus strokeWidth={1.5} color='#879096' size={18} />
        </div>
      </ToolTip>
    </div>
  );
}

const InlineCustomEditor = ({
  taskData,
  handleChange,
  validationData,
  descriptionValue,
  validation_fields,
  tableRef,
}) => {
  const {
    isOpen,
    textEditorRef,
    setIsOpen,
    handleKeyDown,
    handleBlur,
    handleClick,
    handleFocus,
    selectRef,
  } = useComponentInteractionHandler({ disableCloseOnKeyDown: true, disableCloseOnClick: true });

  return (
    <ToolTip tooltipText={'Description'} popOver isModern hideTooltip={isOpen} scrollRef={tableRef}>
      <div
        tabIndex='0'
        ref={selectRef}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onClick={handleClick}
        role='button'
        className={`tab-click col-md-1 inline-task-item-container custom-icon ${
          validationData?.includes('note')
            ? ' has-error'
            : descriptionValue.trim()
            ? 'has-filled'
            : ''
        }`}
        hidden={validation_fields?.note == VALIDATION_VALUES.HIDE}
        style={{
          border: isOpen ? '1px solid #3454D1' : '  ',
        }}
      >
        <Description width={12} height={15} color={isOpen ? '#3454D1' : '#87909E'} />

        {isOpen && (
          <Popover.PopoverItem
            onClose={() => setIsOpen(false)}
            positionLeft={(width, childWidth) => width - childWidth}
            positionTop={() => 5}
            scrollRef={tableRef}
            // isVisible={isOpen}
          >
            <div ref={textEditorRef} className={`inline-curve-shadow inline-description `}>
              <CustomEditor
                wrapperClassName='custom-editor'
                className='inline-task-description tab-input-focus'
                contentState={taskData?.note}
                handleChange={handleChange}
              />
            </div>
          </Popover.PopoverItem>
        )}
      </div>
    </ToolTip>
  );
};

const InlinePriorityDropDown = ({
  validationData,
  taskData,
  validation_fields,
  priorityLabel,
  setTaskData,
  handleChange,
  tableRef,
}) => {
  const { isOpen, handleBlur, handleClick, handleFocus, handleKeyDown, selectRef, setIsOpen } =
    useComponentInteractionHandler();

  return (
    <ToolTip tooltipText={'Priority'} scrollRef={tableRef} popOver isModern hideTooltip={isOpen}>
      <div
        tabIndex='0'
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        ref={selectRef}
        role='button'
        className={`tab-click col-md-1 inline-task-item-container custom-icon ${
          validationData?.includes('priority')
            ? ' has-error'
            : taskData?.priority > 0
            ? 'has-filled'
            : ''
        }`}
        hidden={validation_fields?.priority == VALIDATION_VALUES.HIDE}
        // hidden={validation_fields?.note == VALIDATION_VALUES.HIDE}
        style={{ border: isOpen ? '1px solid #3454D1' : '  ' }}
      >
        <Priority height={13} width={9} color={isOpen ? '#3454D1' : '#87909E'} />

        {/* {priorityLabelList && ( */}
        {isOpen && (
          <Popover.PopoverItem
            onClose={() => setIsOpen(false)}
            positionLeft={(width) => width}
            positionTop={() => 5}
            scrollRef={tableRef}
            isVisible={isOpen}
          >
            <div className={`inline-context-container  menu_popup inline-priority-list   `}>
              {priorityLabel.map((item, index) => (
                <div
                  key={index}
                  className='sort-item'
                  tabIndex={'0'}
                  style={{
                    height: 28,
                    gap: 7,
                    background: taskData?.priority == item.id ? '#e2fbf0' : '',
                  }}
                  onClick={() => {
                    handleChange({
                      target: {
                        name: 'priority',
                        value: item?.value,
                      },
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleChange({
                        target: {
                          name: 'priority',
                          value: item?.value,
                        },
                      });
                    }
                  }}
                >
                  <Priority height={13} width={8} color='var(--icon-light)' />
                  {item?.label}
                </div>
              ))}
              <span
                className='clear-button-list'
                style={{ color: '#87909E' }}
                onClick={() => {
                  setTaskData((prev) => ({
                    ...prev,
                    priority: 0,
                  }));
                }}
              >
                {' '}
                <Minus size={17} />
                Clear
              </span>
            </div>
          </Popover.PopoverItem>
        )}
        {/* )} */}
      </div>
    </ToolTip>
  );
};

const InlineDatePicker = ({
  validation_fields,
  validationData,
  taskData,
  handleChange,
  tableRef,
}) => {
  const {
    handleBlur,
    handleClick,
    handleFocus,
    textEditorRef: datePickerRef,
    handleKeyDown,
    isOpen,
    selectRef,
    setIsOpen,
  } = useComponentInteractionHandler();

  const currentDate = moment().valueOf();

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    handleChange({ target: { name: 'task_start_date', value: startDate } });
    handleChange({ target: { name: 'task_end_date', value: endDate } });
  };

  return (
    <ToolTip tooltipText={'Date'} scrollRef={tableRef} popOver isModern hideTooltip={isOpen}>
      <div
        role='button'
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        ref={selectRef}
        tabIndex='0'
        className={`tab-click inline-task-item-container custom-icon ${
          validation_fields?.task_start_date == VALIDATION_VALUES.HIDE &&
          validation_fields?.task_end_date == VALIDATION_VALUES.HIDE &&
          'd-none'
        } ${
          validationData?.includes('task_start_date') ||
          validationData?.includes('task_end_date') ||
          moment(taskData?.task_end_date).startOf('day').isBefore(moment().startOf('day')) ||
          moment(taskData?.task_start_date).startOf('day').isBefore(moment().startOf('day'))
            ? 'has-error'
            : 'has-filled'
        }`}
        // checkDate
        style={{ border: isOpen ? '1px solid #3454D1' : '  ' }}
      >
        <Calendar height={15} width={14} color={isOpen ? '#3454D1' : '#87909E'} />
        {/* <span>
          {taskData?.task_start_date ? formatDate(taskData.task_start_date) : 'Start Date'} -{' '}
          {taskData?.task_end_date ? formatDate(taskData.task_end_date) : 'End Date'}
        </span> */}
        {isOpen && (
          <Popover.PopoverItem
            onClose={() => {
              // setIsOpen(false);
            }}
            positionLeft={(width) => width}
            positionTop={() => 5}
            scrollRef={tableRef}
            isVisible={isOpen}
          >
            <div
              ref={datePickerRef}
              className={`inline-context-container custom-field position-right inline-time p-2 pb-0 calender-pop-up`}
              style={{ zIndex: 102 }}
            >
              <span className='fz-14px-rem inline-task-sub-head '>Timeline</span>
              <div className='date-range-container mt-2'>
                {/* <div className='date-range-label'>
                  <label htmlFor='start-date' className='col-md-6'>
                    Start Date
                  </label>
                  <label htmlFor='end-date' className='col-md-6'>
                    Due Date
                  </label>
                </div> */}
                <DateRange
                  className='date-range-picker'
                  editableDateInputs={true}
                  onChange={handleSelect}
                  moveRangeOnFirstSelection={false}
                  minDate={new Date(currentDate)}
                  ranges={[
                    {
                      startDate: taskData?.task_start_date
                        ? new Date(taskData.task_start_date)
                        : new Date(),
                      endDate: taskData?.task_end_date
                        ? new Date(taskData.task_end_date)
                        : new Date(),
                      key: 'selection',
                    },
                  ]}
                  ariaLabels={{
                    dateInput: {
                      startDate: 'Start date input',
                      endDate: 'End date input',
                    },
                  }}
                />
              </div>
            </div>
          </Popover.PopoverItem>
        )}
      </div>
    </ToolTip>
  );
};

// const InlineDatePicker = ({
//   validation_fields,
//   validationData,
//   taskData,
//   handleChange,
//   tableRef,
// }) => {
//   const {
//     handleBlur,
//     handleClick,
//     handleFocus,
//     textEditorRef: datePickerRef,
//     handleKeyDown,
//     isOpen,
//     selectRef,
//     setIsOpen,
//   } = useComponentInteractionHandler();
//   const [dateState, setDateState] = useState([
//     {
//       startDate: taskData?.task_start_date,
//       endDate: taskData?.task_end_date,
//       key: 'selection',
//     },
//   ]);

//   const formatDate = (timestamp) => {
//     const date = new Date(timestamp);
//     return date.toLocaleDateString('en-GB', {
//       day: 'numeric',
//       month: 'long',
//       // year: 'numeric',
//     });
//   };

//   const handleSelect = (item) => {
//     const startDate = item.selection.startDate;
//     const endDate = item.selection.endDate;
//     setDateState([{ startDate: startDate, endDate: endDate, key: 'selection' }]);
//   };

//   return (
//     <ToolTip tooltipText={'Date'} scrollRef={tableRef} popOver isModern hideTooltip={isOpen}>
//       <div
//         role='button'
//         onBlur={handleBlur}
//         onClick={handleClick}
//         onFocus={handleFocus}
//         onKeyDown={handleKeyDown}
//         ref={selectRef}
//         tabIndex='0'
//         className={`tab-click inline-task-item-container custom-icon ${
//           validation_fields?.task_start_date == VALIDATION_VALUES.HIDE &&
//           validation_fields?.task_end_date == VALIDATION_VALUES.HIDE &&
//           'd-none'
//         } ${
//           validationData?.includes('task_start_date') || validationData?.includes('task_end_date')
//             ? ' has-error'
//             : 'has-filled'
//         }`}
//         style={{ border: isOpen ? '1px solid #3454D1' : '  ' }}
//       >
//         <Calendar height={15} width={14} color={isOpen ? '#3454D1' : '#87909E'} />

//         {isOpen && (
//           <Popover.PopoverItem
//             onClose={() => {
//               setIsOpen(false);
//             }}
//             positionLeft={(width) => width}
//             positionTop={() => 5}
//             scrollRef={tableRef}
//             isVisible={isOpen}
//           >
//             <div
//               ref={datePickerRef}
//               className={`inline-context-container custom-field position-right inline-time   `}
//             >
//               {console.log(taskData?.task_start_date, 'truth')}
//               <div>
//                 <span className='fz-14px-rem inline-task-sub-head mx-2'>Add Date</span>
//                 {/* <div
//                   className='d-flex py-2'
//                   hidden={
//                     validation_fields?.task_start_date == VALIDATION_VALUES.HIDE &&
//                     validation_fields?.task_end_date == VALIDATION_VALUES.HIDE
//                   }
//                 >
//                   <div
//                     className='col'
//                     hidden={validation_fields?.task_start_date == VALIDATION_VALUES.HIDE}
//                   >
//                     <div className=' px-2 '>
//                       <label className='fz-13px-rem'>Start Date</label>
//                       <input
//                         type='date'
//                         className={`input-box mb-2 fz-13px-rem inline-task-content`}
//                         id='task_start_date'
//                         name='task_start_date'
//                         min={today}
//                         placeholder={today}
//                         value={
//                           taskData?.task_start_date
//                             ? moment(taskData?.task_start_date).format('YYYY-MM-DD')
//                             : today
//                         }
//                         onChange={handleChange}
//                         onFocus={(e) => e.target.showPicker()}
//                       />
//                     </div>
//                   </div>
//                   <div
//                     className='col'
//                     hidden={validation_fields?.task_end_date == VALIDATION_VALUES.HIDE}
//                   >
//                     <div className=' px-2 '>
//                       <label className='fz-13px-rem'>End Date</label>

//                       <input
//                         type='date'
//                         className={`input-box  mb-2 fz-13px-rem inline-task-content`}
//                         id='task_end_date'
//                         name='task_end_date'
//                         min={moment(taskData?.task_start_date).format('YYYY-MM-DD')}
//                         placeholder={today}
//                         value={
//                           taskData?.task_end_date
//                             ? moment(taskData?.task_end_date).format('YYYY-MM-DD')
//                             : today
//                         }
//                         onChange={handleChange}
//                         onFocus={(e) => e.target.showPicker()}
//                       />
//                     </div>
//                   </div>
//                 </div> */}
//                 {/* <DatePicker
//                   onDateChange={handleChange}
//                   initialEndDate={taskData?.task_end_date}
//                   initialStartDate={taskData?.task_start_date}
//                   isEditable={true}
//                 /> */}

//               </div>
//             </div>
//           </Popover.PopoverItem>
//         )}
//         {/* )} */}
//       </div>
//     </ToolTip>
//   );
// };

const InlineAssigneeUserPicker = ({
  validationData,
  taskData,
  handleChange,
  // selectedUserImageFinalUrl,
  // selectedUserIsNeutral,
  // selectedUserIsMale,
  setSearch,
  tableRef,
  userList,
  userSearch,
}) => {
  const {
    isOpen,
    setIsOpen,
    handleBlur,
    handleClick,
    handleFocus,
    handleKeyDown,
    textEditorRef: dropDownRef,
    selectRef,
  } = useComponentInteractionHandler();

  const selectedUser =
    userList.find((item) => item.id == taskData?.assignee_id) ?? taskData?.assigned_user;
  const selectedUserImageFinalUrl = apiConstants.imgUrlS3 + selectedUser?.image_url;
  const selectedUserIsMale = selectedUser?.gender == 'male';
  const selectedUserIsNeutral = selectedUser?.gender == 'neutral';
  const fullName = selectedUser?.label;

  return (
    <ToolTip tooltipText={'Assignee'} scrollRef={tableRef} popOver isModern hideTooltip={isOpen}>
      <div
        tabIndex='0'
        ref={selectRef}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        role='button'
        className={`tab-click col-md-1 inline-assign-user-container custom-icon ${
          validationData?.includes('assignee_id')
            ? 'has-error'
            : taskData?.assignee_id
            ? 'has-filled'
            : ''
        }`}
        style={{ border: isOpen ? '1px solid #3454D1' : '  ' }}
      >
        <div className='d-flex justify-content-center align-items-center'>
          <CircularAvatar
            size={20}
            className='fz-none zoom-in employee-img-with-low-border'
            source={selectedUserImageFinalUrl}
            isMale={selectedUserIsMale}
            isNeutral={selectedUserIsNeutral}
            key={taskData?.assignee_id}
          />
          <span className='placeholderUser' style={{ marginRight: 5 }}>
            {fullName ? fullName : 'Select User'}
          </span>
        </div>
        {/* {contextAssignUser && ( */}
        {isOpen && (
          <Popover.PopoverItem
            positionLeft={(width) => -width + 20}
            scrollRef={tableRef}
            onClose={() => setIsOpen(false)}
          >
            <div ref={dropDownRef}>
              <CustomSelectModal
                listData={userList}
                menuCssPosition={'static'}
                setSearch={(value) => setSearch(value)}
                selectedValueVisibility={false} // show the selected value
                search={userSearch}
                handleChange={(value) => {
                  setSearch('');
                  handleChange(value);
                  // handleChange({
                  //   target: {
                  //     value: parseInt(value?.id),
                  //     name: 'assignee_id',
                  //   },
                  // });

                  // handleChange({
                  //   target: { value: value, name: 'assigned_user' },
                  // });

                  setIsOpen(false);
                }}
                // valueId={taskData?.assignee_id}
                formatOptionLabel={(data, metaData) => (
                  <UserDropDown size={19} gap={14} data={data} metaData={metaData} />
                )} // Show formatted label for users
              />
            </div>
          </Popover.PopoverItem>
        )}
        {/* )} */}
      </div>
    </ToolTip>
  );
};

const InlineContactPicker = ({
  validationData,
  taskData,
  handleChange,
  tableRef,
  clientList,
  setSearch,
}) => {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const {
    isOpen,
    setIsOpen,
    handleBlur,
    handleClick,
    handleFocus,
    handleKeyDown,
    textEditorRef: dropDownRef,
    selectRef,
  } = useComponentInteractionHandler();

  return (
    <ToolTip
      tooltipText={LABEL_OF_CLIENT}
      scrollRef={tableRef}
      popOver
      isModern
      hideTooltip={isOpen}
    >
      <div
        tabIndex='0'
        ref={selectRef}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        role='button'
        className={`tab-click col-md-1 inline-assign-user-container custom-icon ${
          validationData?.includes('client_id')
            ? 'has-error'
            : taskData?.client_id
            ? 'has-filled'
            : ''
        }`}
        style={{ border: isOpen ? '1px solid #3454D1' : '  ' }}
      >
        <div className='d-flex justify-content-center align-items-center'>
          <span className='placeholderUser fz-14px w-fit' style={{ marginRight: 5 }}>
            {taskData?.client_id?.id ? taskData?.client_id?.name : `Select ${LABEL_OF_CLIENT}`}
          </span>
        </div>
        {/* {contextAssignUser && ( */}
        {isOpen && (
          <Popover.PopoverItem
            positionLeft={(width) => -width + 20}
            scrollRef={tableRef}
            onClose={() => setIsOpen(false)}
          >
            <div ref={dropDownRef}>
              <CustomSelectModal
                listData={clientList}
                menuCssPosition={'static'}
                setSearch={(value) => {
                  setSearch(value);
                }}
                selectedValueVisibility={false} // show the selected value
                handleChange={(value) => {
                  setSearch('');
                  handleChange({
                    target: {
                      name: 'client_id',
                      value: value,
                    },
                  });
                  setIsOpen(false);
                }}
                formatOptionLabel={(data) => data?.name}
              />
            </div>
          </Popover.PopoverItem>
        )}
        {/* )} */}
      </div>
    </ToolTip>
  );
};

const InlineExpectedTimePicker = ({
  taskData,
  validation_fields,
  validationData,
  hasRequestTime,
  error,
  tableRef,
  handleChange,
}) => {
  const {
    handleBlur,
    handleClick,
    handleFocus,
    textEditorRef: timePicker,
    handleKeyDown,
    isOpen,
    selectRef,
    setIsOpen,
  } = useComponentInteractionHandler();

  return (
    <ToolTip tooltipText={'Time'} scrollRef={tableRef} popOver isModern hideTooltip={isOpen}>
      <div
        tabIndex='0'
        onBlur={handleBlur}
        onClick={handleClick}
        ref={selectRef}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        role='button'
        className={`tab-click inline-task-item-container custom-icon
    ${validation_fields?.expected_hours == VALIDATION_VALUES.HIDE && 'd-none'}
    ${
      (validationData?.includes('expected_hours') && !hasRequestTime) || error
        ? ' has-error'
        : taskData?.expected_hours || taskData?.expected_minutes
        ? 'has-filled'
        : ''
    }`}
        style={{ border: isOpen ? '1px solid #3454D1' : '  ', paddingTop: 2 }}
      >
        <Hourglass width={15} height={15} color={isOpen ? '#3454D1' : '#87909E'} />

        {isOpen && (
          <Popover.PopoverItem
            onClose={() => setIsOpen(false)}
            positionLeft={(width) => width + 10}
            positionTop={() => 10}
            scrollRef={tableRef}
            isVisible={isOpen}
          >
            <div ref={timePicker} className={`inline-context-container inline-time custom-field  `}>
              <CustomTimeInput
                key={taskData?.expected_hours}
                className='tab-input-focus'
                handleChange={(e) => handleChange(e)}
                value={{
                  expected_hours: taskData?.expected_hours,
                  expected_minutes: taskData?.expected_minutes,
                }}
                focusField
              />
            </div>
          </Popover.PopoverItem>
        )}
      </div>
    </ToolTip>
  );
};

const InlineLabelPicker = ({
  tableRef,
  taskData,
  error,
  handleChange,
  filteredLabelList,
  setLabelSearch,
}) => {
  const {
    handleBlur,
    handleClick,
    handleFocus,
    handleKeyDown,
    textEditorRef: labelListRef,
    isOpen,
    selectRef,
    setIsOpen,
  } = useComponentInteractionHandler();
  return (
    <ToolTip tooltipText={'Label'} scrollRef={tableRef} popOver isModern hideTooltip={isOpen}>
      <div
        role='button'
        ref={selectRef}
        tabIndex='0'
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        className={`tab-click col-md-1 inline-task-item-container custom-icon ${
          // error ? ' has-error' :
          taskData?.label?.length > 0 ? 'has-filled' : ''
        }`}
        style={{ border: isOpen ? '1px solid #3454D1' : ' ' }}
      >
        <Label width={13} height={13} color={isOpen ? '#3454D1' : '#87909E'} />
        {isOpen && (
          <Popover.PopoverItem
            onClose={() => setIsOpen(false)}
            positionLeft={(width) => width}
            positionTop={() => 5}
            scrollRef={tableRef}
            isVisible={isOpen}
          >
            <div
              className={`inline-context-container menu_popup inline-priority-list `}
              ref={labelListRef}
            >
              <div className='search-list-container'>
                <div className='inline-task-label-container'>
                  {taskData?.labelDetails?.map((item, idx) => (
                    <div
                      key={idx}
                      className='label-item-task-list'
                      tabIndex={'0'}
                      style={{
                        background: item?.label_color,
                        color: item?.text_color,
                        margin: '0 10px 10px 0',
                        outline: `1px solid ${item?.text_color}`,
                      }}
                      onClick={() => handleChange({ target: { value: item, name: 'label' } })}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleChange({ target: { value: item, name: 'label' } });
                        }
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
                <SearchInput
                  className='tab-input-focus'
                  handleChange={(value) => setLabelSearch(value)}
                />
                <div className='inline-task-label-container'>
                  {filteredLabelList?.length === 0 && (
                    <span style={{ color: 'lightgray' }}>no data</span>
                  )}
                  {filteredLabelList.map((item, idx) => (
                    <div
                      key={idx}
                      className='label-item-task-list'
                      tabIndex={'0'}
                      style={{
                        background: item?.label_color,
                        color: item?.text_color,
                        margin: '0 10px 10px 0',
                        border: taskData?.labelDetails?.includes(item)
                          ? `1px solid ${item?.text_color}`
                          : '',
                      }}
                      onClick={() => handleChange({ target: { value: item, name: 'label' } })}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleChange({ target: { value: item, name: 'label' } });
                        }
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Popover.PopoverItem>
        )}
      </div>
    </ToolTip>
  );
};
