import React, { useEffect, useRef, useState } from 'react';

import { POSITION } from '../../_constants/contextMenu.constants';
import { StackedComponents } from './StackedAvatarComponents';
import './avatar.css';

/***
 * @callback toggleSelectCallback
 * @param {object} user - THe user to be removed or added.
 */

/***
 * @callback getSourceCallBack
 * @param {object} user - THe user details
 * @param {number[]} activeIds - The selected user's id
 */
/***
 * @callback renderExceedCallback
 * @param {object} props
 * @param {number} props.exceedCount - The number of users overflowing
 * @param {object[]} props.exceededMembers - The object list of the overflowed users
 * @param {number[]} props.selectedIds - Contains all the selected users including the one showing
 * @param {updateSelectionCallback} props.updateSelection
 * @param {toggleSelectCallback} props.toggleSelect
 */

/***
 * @typedef {Object} SelectProps
 * @property {object[]} props.initialValue - THe initial loaded value of the list
 * @property {string} props.queryKey - The key used for querying the function
 * @property {functionName?} props.queryFn - The function to be called on search
 * @property {(searchKey:string)=>{}?} props.getFunctionParams - A function to generate the function params
 * @property {boolean?} props.isCustomFunction - If true will take the queryFn and params from teh provided function, false by default
 * @property {GetCallBack} props.getOptionLabel
 * @property {GetCallBack} props.getOptionValue
 * @property {FormalLabelCallBack} props.formatOptionLabel
 */
/***
 * For displaying or interacting multiple uses places as a stack
 * @component
 * @param {object} props  - The props
 * @param {number} props.size  - THe size of the avatar
 * @param {number} props.dropdownSize  - THe size of the dropdown
 * @param {number} props.isClearable  - Whether avatar is clearable or not
 * @param {number} props.tooltipPortal  - Flag for popping up the tooltip outside of the container
 * @param {number} props.tooltipPopOver  - Flag for popping up the tooltip
 * @param {number} props.scrollRefForTooltip  - THe scrollRef for tooltip for adjusting it's position on container scroll. Add the scrolling container ref
 * @param {number} props.imageList  - THe user list
 * @param {number[]} props.initialActiveIds - The ids to be selected on load of this component
 * @param {getSourceCallBack | null} props.getSourceByKey - The callback to get the key
 * @param {getGenderCallBack | null} props.getGenderByKey - The callback to get the gender
 * @param {getGenderCallBack | null} props.getValueByKey - The callback to get the value
 * @param {getNameCallBack | null} props.getNameByKey - The callback to get the name of the user
 * @param {boolean} props.showInitials - FOr showing the initial names of the users without profile pic instead of placeholder image.
 * @param {number} props.limit - THe limit of numbers to be shown
 * @param {boolean} props.isClickable - Make the component interactive with the avatars.
 * @param {renderExceedCallback | null} props.renderExceedList - Component for rendering the exceeded
 * @param {string} props.tooltipPosition - Component for rendering the exceeded
 * @param {boolean} props.isAddable - To make this component addable
 * @param {any} props.onAdd - Callback called when adding new user
 * @param {any} props.options - Array of data to be listed in the selection dropdown
 * @param {any} props.addableLimit - For restricting the addable number of items
 * @param {boolean} props.hideTooltip - For hiding the tooltip conditionally
 * @param {SelectProps} props.selectProps - The additional props of react select
 * @param {(user:Object,selectedUserIds:number[])=>void} props.onAvatarClick - Will call when selecting the avatar
 * @param {(user:Object,selectedUserIds:number[])=>void} props.onAvatarDeselect - Will call when deselecting the avatar
 * @param {(user:Object)=>void} props.onAvatarRemove - Will call when an avatar is removed in addable select
 */
const StackedAvatars = ({
  size = 20,
  tooltipPortal = false,
  dropdownSize = null,
  isClearable = () => false,
  imageList = [],
  scrollRefForTooltip = null,
  selectProps = {},
  initialActiveIds = [],
  tooltipPopOver = false,
  getGenderByKey = null,
  getValueByKey = null,
  getNameByKey = null,
  showInitials = true,
  limit = 3,
  exceedCount = null,
  isClickable = false,
  isAddable = false,
  addableLimit = null,
  onAdd = () => {},
  options = [],
  tooltipPosition = POSITION.TOP,
  justifyPosition = 'justify-content-center',
  getSourceByKey = null,
  onAvatarClick = () => {},
  onAvatarDeselect = () => {},
  onAvatarRemove = () => {},
  renderExceedList = null,
  setShrinkSize = (size) => size / 1.21,
  hideTooltip = false,
  renderCustomOptionLabel = () => null,
  zIndex = 1,
  searchParams,
}) => {
  const [activeIds, setActiveIds] = useState(initialActiveIds);
  const [isOpen, setIsOpen] = useState(false);
  const rootContainerRef = useRef(null);

  // Temp solution: Syncing initialActiveIds with activeIds state
  useEffect(() => {
    setActiveIds(initialActiveIds);
  }, [initialActiveIds?.length]);

  if (!isAddable && (!imageList || imageList.length <= 0)) {
    return null;
  }

  const totalMembers = imageList.length;
  const exceededUserCount = exceedCount
    ? exceedCount
    : totalMembers > limit && !isAddable
    ? totalMembers - limit
    : 0;
  const exceededMembers =
    exceededUserCount && !isAddable ? imageList.slice(-exceededUserCount) : [];

  const nonExceededMembers =
    exceededUserCount && !isAddable
      ? imageList.slice(0, totalMembers - exceededUserCount)
      : imageList;

  const selectedOption = imageList?.[0] ?? null;
  const customOption = renderCustomOptionLabel(selectedOption, { context: 'value' });

  return (
    <div
      className={`d-flex align-items-center ${justifyPosition} user-select-none`}
      ref={rootContainerRef}
    >
      <div className='preview-list-container position-relative'>
        {imageList
          .filter((_, index) => (isAddable ? true : index < limit))
          .map((image, index) => {
            const source = getSourceByKey ? getSourceByKey(image) : null;
            const isMale = getGenderByKey ? getGenderByKey(image) : false;
            const name = getNameByKey ? getNameByKey(image) : null;
            return (
              <>
                {customOption ? (
                  customOption
                ) : (
                  <StackedComponents.SingleAvatarWithName
                    isClearable={() => isClearable(image)}
                    user={image}
                    index={index}
                    popOverPortal={tooltipPortal}
                    isClickable={isClickable}
                    onAvatarRemove={() => onAvatarRemove(image)}
                    source={source}
                    showInitials={showInitials}
                    scrollRefForTooltip={scrollRefForTooltip}
                    popOver={tooltipPopOver}
                    isMale={isMale}
                    size={size}
                    name={name}
                    isAddable={isAddable}
                    tooltipPosition={tooltipPosition}
                    key={image.id}
                    onAvatarClick={onAvatarClick}
                    onAvatarDeselect={onAvatarDeselect}
                    activeIds={activeIds}
                    setActiveIds={setActiveIds}
                    hideTooltip={hideTooltip}
                  />
                )}
              </>
            );
          })}
      </div>

      {Boolean(exceededUserCount) && (
        <StackedComponents.ExceedAvatarWithName
          size={size}
          exceededMembers={exceededMembers}
          exceedCount={exceededUserCount}
          getNameByKey={getNameByKey}
          renderExceedList={renderExceedList}
          activeIds={activeIds}
          setActiveIds={setActiveIds}
          isClickable={isClickable}
          hideTooltip={hideTooltip}
          scrollRef={scrollRefForTooltip}
          nonExceededUserList={nonExceededMembers}
          searchParams={searchParams}
        />
      )}

      {isAddable && (
        <StackedComponents.AddableUserDropDown
          onAdd={onAdd}
          size={size}
          selectProps={selectProps}
          options={options}
          dropdownSize={dropdownSize}
          tooltipPortal={tooltipPortal}
          getNameByKey={getNameByKey}
          selectedList={imageList}
          getValueByKey={getValueByKey}
          addableLimit={addableLimit}
          renderCustomOptionLabel={renderCustomOptionLabel}
          showInitials={showInitials}
          openState={[isOpen, setIsOpen]}
          rootContainerRef={rootContainerRef}
          setShrinkSize={setShrinkSize}
          zIndex={zIndex}
        />
      )}
    </div>
  );
};

export default StackedAvatars;
