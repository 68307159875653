/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EDITOR_TOOLBAR_OPTIONS } from '../_constants/template.constants';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import ReactSelect from 'react-select';
import { Button } from '../_components';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { CommonDropDownStyle } from '../_constants';
import { emailTemplateService } from '../_services/emailTemplate.service';
import { convertHtmlToRawContent } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { ACTION_TYPE, mailTemplateOptions } from '../Workflow/workflow.constants';
import './mailTemplate.css';

const EmailTemplateForm = ({ selectedId = null, onClose = () => {} }) => {
  const [emailTemplate, setEmailTemplate] = useState({
    name: '',
    subject: '',
    content: '',
    send_to: '',
    type: ACTION_TYPE.MAIL, // default value for select
  });

  const getEmailTemplate = async (id) => {
    const res = await emailTemplateService.emailTemplateGet({ id });

    setEmailTemplate((prev) => ({
      ...prev,
      ...res?.data[0],
      content: convertHtmlToRawContent(res?.data[0]?.content), // Convert HTML content to raw content
    }));

    return res?.data[0];
  };

  // email template list API call section
  const {
    data: emailTemplateData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['email-template-single', selectedId],
    queryFn: () => getEmailTemplate(selectedId),
    enabled: selectedId ? true : false,
  });

  // Handle input change for all fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailTemplate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle editor content change
  const handleEditorChange = (contentState) => {
    setEmailTemplate((prev) => ({
      ...prev,
      content: contentState,
    }));
  };

  const queryClient = useQueryClient();

  // Email template mutation function
  const emailTemplateAdd = async (data) => {
    const payload = {
      ...data,
      content: draftToHtml(data.content), // Convert content to HTML
      id: selectedId ? selectedId : undefined,
    };

    await emailTemplateService.emailTemplateAdd(payload); // Assuming this is the service function
  };

  const { mutate: handleMutate } = useMutation({
    mutationFn: emailTemplateAdd,
    onSuccess: () => {
      queryClient.invalidateQueries(['email-template-list']);
      setEmailTemplate({
        name: '',
        subject: '',
        content: '',
        send_to: '',
        type: ACTION_TYPE.MAIL,
      });
      onClose();
    },
    onError: () => {
      // alert('Failed to add email template. Please try again.');
    },
  });

  const handleSubmit = () => {
    if (emailTemplate.name.trim()) {
      handleMutate(emailTemplate); // Trigger mutation
    }
  };

  return (
    <>
      <div className='modal-end-container'>
        {/* head section */}
        <div className='modal_header'>
          <div className='emt-title'>Create Email Template</div>
        </div>
        {/* head section */}

        {/* content section */}
        <div className='emt-content'>
          {/* Template Type */}
          <div className='emt-field'>
            <label htmlFor='templateType' className='mb-1 fz-13px-rem '>
              Select type
            </label>

            <ReactSelect
              id='type'
              className='trigger-content-selection'
              styles={CommonDropDownStyle}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              options={mailTemplateOptions}
              value={
                mailTemplateOptions.find((option) => option?.value === emailTemplate.type) || null
              }
              onChange={(selectedOption) =>
                handleChange({ target: { name: 'type', value: selectedOption?.value } })
              }
              placeholder='Select type'
            />
          </div>

          <div className='emt-field'>
            <label htmlFor='name' className='mb-1 fz-13px-rem '>
              Name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={emailTemplate.name}
              onChange={handleChange}
              className='input-box'
            />
          </div>
          {/* subject */}
          <div className='emt-field'>
            <label htmlFor='subject' className='mb-1 fz-13px-rem '>
              Template subject
            </label>
            <input
              type='text'
              id='subject'
              name='subject'
              value={emailTemplate.subject}
              onChange={handleChange}
              className='input-box'
            />
          </div>
          {/* subject */}

          {/* editor */}
          <div className='emt-field'>
            <label htmlFor='content' className='mb-1 fz-13px-rem '>
              Template content
            </label>

            {(!selectedId || emailTemplate.id) && (
              <Editor
                defaultContentState={emailTemplate?.content}
                toolbar={EDITOR_TOOLBAR_OPTIONS}
                stripPastedStyles={true}
                onChange={handleEditorChange}
                wrapperClassName='mail-template-wrapper-style'
                editorClassName='milestone-editor-style'
                toolbarClassName='editor-toolbar'
                placeholder='Type here'
              />
            )}
          </div>

          {/* sent to */}
          <div className='emt-field'>
            <label htmlFor='send_to' className='mb-1 fz-13px-rem '>
              Send to
            </label>
            <input
              type='text'
              id='send_to'
              name='send_to'
              value={emailTemplate.send_to}
              onChange={handleChange}
              className='input-box'
              placeholder='hidesk@gmail.com, {contactemail}, '
            />
          </div>
          <div className='emt-placeholder-info'>
            {`{contactemail}, {reportingusermail}, {ownermail}`}
          </div>
        </div>
        {/* end content section */}

        {/* footer section */}

        <div className='modal_footer'>
          {/* <button type='button' onClick={() => onClose()} className='cancel-btn'>
            CANCEL
          </button> */}
          <Button.Container
            className={'outline-cancel-button'}
            type='button'
            handleOk={() => {
              onClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>
          {/* <button type='button' onClick={() => handleSubmit()} className='done-btn'>
            SAVE
          </button> */}

          <Button.Container
            type='submit'
            className='blue-btn'
            isDisabled={isLoading}
            handleOk={handleSubmit}
          >
            <Tick />
            <Button.Title title='Save' />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
      {/*end footer section */}
    </>
  );
};

export default EmailTemplateForm;
