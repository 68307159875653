/* eslint-disable no-undef */

export const Images = {
  workflow: '/icons/workflow.svg',
  bell: '/icons/bell.svg',
  ai: '/ai.svg',
  reminder: '/icons/alarm-clock.svg',
  ai_chat: '/ai_chat.svg',
  ai_mic: './mic.svg',
  CompanyLogo: '/HideskLogo.png',
  Logo: '/logo.svg',
  Dashboard1: '/icons/Dashboard1.svg',
  Dashboard: '/icons/dashboard.png',
  ArrowDown: '/icons/downarrow.png',
  Add: '/icons/add.png',
  Spinner: '/spinner.svg',
  NoImagePreview: '/icons/no-image.png',
  ImagePreview: '/icons/image-preview.png',
  DeleteBin: '/icons/Vector (2).png',
  Project: '/icons/project.svg',
  Report: '/icons/report.svg',
  File: '/icons/file.svg',
  PlayDown: '/icons/Vector (1).svg',
  SerialNo: '/icons/serialnum.svg',
  Pinned: '/icons/pinned.svg',
  Time_history: '/icons/timehistory.svg',
  Pin: '/icons/pin.svg',
  tick: '/icons/tick.svg',
  clock: '/icons/clock.svg',
  subtask: '/icons/subtask.svg',
  chatGreen: '/chatGreen.svg',
  verify: '/verify.svg',
  dropdownIcon: '/icons/dropdown_icon.svg',
};

export const SideBar = {
  Dashboard: '/icons/Myteam.svg',
  Task: '/icons/Task.svg',
  Team: '/icons/team.svg',
  Chat: '/icons/chat.svg',
};

export const TaskListIcon = {
  Message: '/icons/Group 134692.svg',
  Repeat: '/icons/Group 134865.png',
  Date: '/icons/Group 133770.svg',
  Eye: '/icons/Group 134864.svg',
  BookMark: '/icons/Vector (2).svg',
  File: '/icons/Group 135800.svg',
};

export const SortIcons = {
  'Recently Updated': '/icons/Group 134865.svg',
  'Task Date': '/icons/Group 133770.svg',
  'Recently Viewed': '/icons/Group 134864.svg',
  Priority: '/icons/Vector (2).svg',
  Overdue: '/icons/overdue tasks.svg',
};
export const MyTeamIcon = {
  Slash: '/icons/Lineicon.png',
  EmployeeId: '/icons/employeeId.png',
  ReportTo: '/icons/reportedTo.png',
  Address: '/icons/addressIcon.png',
};
export const TaskIcon = {
  Dot: '/icons/Dot.png',
};
export const TemplateIcon = {
  Template: '/icons/Vector.svg',
  TaskType: '/icons/Group 135237.png',
  Verification: '/icons/Group 135235.png',
};

export const DashboardIcon = {
  Chat: '/icons/Group 135739.svg',
  Group: '/icons/Group 135740.svg',
};
export const DashboardDummyIcon = {
  Vishnu: '/icons/DummyImage/vishnu.png',
  John: '/icons/DummyImage/john.png',
  Wizad: '/icons/DummyImage/wizad.png',
  Ann: '/icons/DummyImage/ann.png',
  George: '/icons/DummyImage/george.png',
  Update: '/icons/DummyImage/update.svg',
};

export const FileSystemImage = {
  EmptyDocument: '/icons/nofile.png',
  EmptyFolder: '/icons/noFolder.png',
  FileUpload: '/icons/upload.png',
  FileUploadedPreview: '/icons/uploaded-file-img.png',
  ArrowUp: '/icons/arrow-up.png',
  ArrowDown: '/icons/arrow-down.png',
  Complete: '/icons/green-tick.svg',
  GroupIcon: '/icons/GropUser.svg',
};
export const OKRImage = {
  EmptyOkr: '/icons/OkrEmpty.svg',
};
