/* eslint-disable no-unused-vars */
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { Calendar, Clock3, File, Flag, Hourglass, Minus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useUser } from '../../Hook/useUser';
import ErrorBlock from '../../Template/SubComponents/ErrorBlock';
import SubtaskListWithAdd from '../../Template/SubComponents/SubtaskListWithAdd';
import { GlobalContext, subtaskState } from '../../Utils';
import { Label, Priority } from '../../Utils/SvgIcons';
import { SelectableContextMenu, ToggleButton } from '../../_components';
import IconLabelSet from '../../_components/IconLabelSet';
import { Popover } from '../../_components/Popover';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import UserDropDown from '../../_components/UserDropDown';
import {
  CommonDropDownStyle,
  LIST_PERMISSION_VALUE,
  MENU,
  ROLE,
  USER_LIST_ACTIONS,
} from '../../_constants';
import { POSITION } from '../../_constants/contextMenu.constants';
import { VALIDATION_VALUES } from '../../_constants/template.constants';
import { clientService, userService } from '../../_services';
import { labelService } from '../../_services/label.service';
import { customSelectBoxStyles, labelSelectValues, taskAssignUserSelect } from '../taskConstants';
import {
  getExpectedTime,
  getPriorityByContextAPI,
  getStartDateMilliSecond,
  removeTagsAndGetContent,
  validateHours,
} from '../taskValidation';
import {
  CreateCustomLabelAdd,
  CustomTimeInput,
  CustomVariableInput,
  getFormattedLabel,
} from './CustomInputs';
import { EditableDescription } from './EditableDescription';
import EditableField from './EditableField';

export const TaskAddFormSection = ({
  templateData,
  taskData,
  setTaskData,
  labelList = [],
  userList = [],
  verifyUserList = [],
  validationData,
  setNeedVerifyUser = () => {},
  needVerifyUser = false,
  isSubmitted = false,
  // setEditTaskIndex = () => {},
  isMenuLabelOn = false,
  isMenuSubTaskOn = false,
  onAssigneeChange = () => {},
  tableRef,
  labelRefetch = () => {},
  clientList = [],
  isProjectExistClient = false,
  hasExist = () => {},
}) => {
  const { getMenuLabelName, roleBasedPermission } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const user = useUser();
  const validation_fields = templateData?.validation_fields;
  const priorityLabel = getPriorityByContextAPI(); // to fetch the priority  label from context api
  // const [editorState, setEditorState] = useState(EditorState.createEmpty()); // to manage state of the editor
  const [priorityContextMenuVisible, setPriorityContextMenuVisible] = useState(false);
  const [startTimeAddVisible, setStartTimeAddVisible] = useState(false);
  const [endTimeAddVisible, setEndTimeAddVisible] = useState(false);
  // const [statusContextMenuVisible, setStatusContextMenuVisible] = useState(false);
  const [error, setError] = useState(false);
  const [contextDateItem, setContextDateItem] = useState(false); //state for add date visibility
  const [contextTimeItem, setContextTimeItem] = useState(false); //state for add time visibility

  // const tableRef = useRef(null);
  const expectedTime = getExpectedTime(
    parseInt(taskData?.expected_hours),
    parseInt(taskData?.expected_minutes),
  );

  // subtask handler for add or update
  const handleSubTaskAddOrUpdate = (value, index = null, status, startDate, endDate) => {
    // Check if startDate is a Date object; if not, convert it
    const startDateObj = startDate instanceof Date ? startDate : new Date(parseInt(startDate));

    // Check if endDate is a Date object; if not, convert it
    const endDateObj = endDate instanceof Date ? endDate : new Date(parseInt(endDate));
    // update

    if (!isNaN(index) && index !== null && index <= taskData.subtasks.length - 1) {
      setTaskData((prev) => ({
        ...prev,
        subtasks: (prev.subtasks || []).map((itm, idx) => {
          if (idx === index) {
            return {
              ...itm,
              content: value,
              start_date_milli: moment(startDateObj).valueOf(),
              startDate: startDateObj,
              end_date_milli: moment(endDateObj).valueOf(),
            };
          }
          return itm;
        }),
      }));
      return;
    }
    const initSubtask = { ...subtaskState };
    initSubtask.content = value;
    initSubtask.startDate = startDateObj;
    initSubtask.start_date_milli = moment(startDateObj).valueOf();
    initSubtask.end_date_milli = moment(endDateObj).valueOf();
    // TODO: Add user_id and task_id(if exist)
    setTaskData((prev) => ({
      ...prev,
      subtasks: [...(prev.subtasks || []), initSubtask],
    }));
  };

  // remove subtask data
  const handleSubtaskDelete = (index) => {
    setTaskData((prev) => ({
      ...prev,
      subtasks: prev.subtasks.filter((item, idx) => idx !== index),
    }));
  };

  // to maintain state of selected priority
  let selectedPriority;
  if (taskData?.priority) {
    selectedPriority = priorityLabel.find((item) => {
      if (item.value == taskData?.priority) {
        return item.label;
      }
    });
  }

  // handler for task
  const handleChange = (e) => {
    let { name, value } = e.target;

    setError(false);
    if (name == 'task_start_date' || name == 'task_end_date') {
      value = getStartDateMilliSecond(value);
    } else {
      if (name == 'expected_hours') {
        // to validate the hours according to the start - end dates
        const isValidHour = validateHours(
          taskData?.task_start_date,
          taskData?.task_end_date,
          value,
        );
        if (isValidHour) {
          let time = parseInt(value);
          value = time;
        } else {
          setError(true);
        }
      } else if (name == 'expected_minutes') {
        let time = parseInt(value);
        value = time;
      }
    }

    setTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // handler for date-picker to select the start and end date for task
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    handleChange({ target: { name: 'task_start_date', value: startDate } });
    handleChange({ target: { name: 'task_end_date', value: endDate } });
  };
  const currentDate = moment().valueOf();
  const StartDate = moment(taskData?.task_start_date).format('MMM DD');
  const EndDate = moment(taskData?.task_end_date).format('MMM DD');
  const handlePeriodChange = (event) => {
    const newPeriod = event.target.value;

    setTaskData((prev) => ({
      ...prev,
      start_time: {
        ...taskData?.start_time,
        period: newPeriod,
      },
    }));
  };

  // to handle the start time if time available
  const handleStartTimeChange = (event) => {
    const { name, value } = event.target;

    setTaskData((prev) => ({
      ...prev,
      start_time: {
        ...taskData?.start_time,
        [name]: value,
      },
    }));
  };

  // to handle the end time if time available
  const handleEndTimeChange = (event) => {
    const { name, value } = event.target;
    setTaskData((prev) => ({
      ...prev,
      end_time: {
        ...taskData?.end_time,
        [name]: value,
      },
    }));
  };

  // Handle correction on blur (e.g., inputting `13:00` converts to `12:59`)
  const handleBlur = () => {
    let hr = taskData?.start_time?.hours;
    let min = taskData?.start_time?.minutes;

    if (taskData?.start_time?.hours > 12) {
      hr = 12;
    } else if (taskData?.start_time?.hours < 1) {
      hr = 1;
    }
    if (taskData?.start_time?.minutes > 59) {
      min = 59;
    } else if (taskData?.start_time?.minutes < 0) {
      min = 0;
    }

    setTaskData((prev) => ({
      ...prev,
      start_time: {
        ...taskData?.start_time,
        hours: hr,
        minutes: min,
      },
    }));
  };

  return (
    <section>
      <div
        className='task-container'
        // style={{ width: taskData?.relatedTasks.length > 0 ? '35vw' : '33vw' }}
      >
        <div
          className={`form-group  py-1 `}
          hidden={validation_fields?.name == VALIDATION_VALUES.HIDE}
        >
          <div className='mt-10px '>
            <CustomVariableInput
              name='name'
              value={taskData.name}
              placeholder='Enter Task Name'
              onChange={(event) => handleChange(event)}
              className={`taskname-input  w-100  border-none form-control
                ${validationData?.includes('name') ? ' has-error' : ''}`}
            />
          </div>
          <ErrorBlock hasError={validationData?.includes('name')} />
        </div>
        <div className={`form-group  `} hidden={validation_fields?.note == VALIDATION_VALUES.HIDE}>
          <div>
            <EditableDescription
              key={2}
              isEditable={true}
              isUpdate
              handleChange={(val) => handleChange(val)}
              data={{ note: taskData?.note }}
            >
              <div
                className={`task-description-dummy mb-3 ${
                  validationData?.includes('note') ? ' has-error' : ''
                }`}
              >
                <div className='d-flex align-items-center ptr ms-1 '>
                  {!removeTagsAndGetContent(draftToHtml(taskData?.note)) ? (
                    <IconLabelSet
                      textClassName='mb-1'
                      label={'Add Description'}
                      iconClassName='d-flex justify-content-center align-items-center'
                      icon={<File width={14} height={16} />}
                    />
                  ) : (
                    parse(draftToHtml(taskData?.note))
                  )}
                </div>{' '}
              </div>
            </EditableDescription>
          </div>
          <ErrorBlock hasError={validationData?.includes('note')} />
        </div>
        <div className='d-flex gap-2 ms-1 position-relative task-time-container'>
          <div
            className={`d-flex gap-1 ${
              validation_fields?.task_start_date == VALIDATION_VALUES.HIDE &&
              validation_fields?.task_end_date == VALIDATION_VALUES.HIDE &&
              'd-none'
            }`}
            hidden={
              validation_fields?.task_start_date == VALIDATION_VALUES.HIDE &&
              validation_fields?.task_end_date == VALIDATION_VALUES.HIDE
            }
          >
            <div className='position-relative'>
              <div
                className={`task-icon-bg cursor-pointer  px-2 ${
                  validationData?.includes('task_start_date') ||
                  validationData?.includes('task_end_date') ||
                  moment(taskData?.task_end_date)
                    .startOf('day')
                    .isBefore(moment().startOf('day')) ||
                  moment(taskData?.task_start_date).startOf('day').isBefore(moment().startOf('day'))
                    ? ' has-error'
                    : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setContextDateItem(true);
                }}
              >
                <IconLabelSet
                  className='d-flex'
                  label={`${StartDate} - ${EndDate}`}
                  gap={8}
                  icon={<Calendar size={13} />}
                />
              </div>
              {contextDateItem && (
                <Popover.PopoverItem
                  onClose={() => {
                    setContextDateItem(false);
                  }}
                  positionLeft={() => '0px'}
                  positionTop={() => '0px'}
                  scrollRef={tableRef}
                  isVisible={contextDateItem}
                  zIndex={100}
                >
                  <div
                    className={`inline-context-container custom-field position-left  p-2 pb-0 calender-pop-up`}
                  >
                    <span className='fz-14px-rem inline-task-sub-head '>Timeline</span>
                    <div className='date-range-container mt-2'>
                      <DateRange
                        className='date-range-picker'
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        minDate={new Date(currentDate)}
                        ranges={[
                          {
                            startDate: taskData?.task_start_date
                              ? new Date(taskData.task_start_date)
                              : new Date(),
                            endDate: taskData?.task_end_date
                              ? new Date(taskData.task_end_date)
                              : new Date(),
                            key: 'selection',
                          },
                        ]}
                        ariaLabels={{
                          dateInput: {
                            startDate: 'Start date input',
                            endDate: 'End date input',
                          },
                        }}
                      />
                    </div>
                  </div>
                </Popover.PopoverItem>
              )}
            </div>
          </div>
          <div
            className={`d-flex  ${
              validation_fields?.expected_hours == VALIDATION_VALUES.HIDE && 'd-none'
            }`}
          >
            <div className=''>
              <div
                className={`task-icon-bg cursor-pointer  px-2 ${
                  validationData?.includes('expected_hours') ? ' has-error' : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setContextTimeItem(true);
                }}
              >
                <IconLabelSet
                  label={expectedTime ? expectedTime : 'Expected Time'}
                  gap={8}
                  icon={<Hourglass width={11} height={15} />}
                  className='d-flex'
                />
              </div>
              {contextTimeItem && (
                <SelectableContextMenu
                  isContextVisible={true}
                  onRequestClose={() => setContextTimeItem(false)}
                  className='time-modal-context-container inline-time mt-2 p-1'
                >
                  <CustomTimeInput
                    key={taskData?.expected_hours}
                    handleChange={(e) => handleChange(e)}
                    value={{
                      expected_hours: taskData?.expected_hours,
                      expected_minutes: taskData?.expected_minutes,
                    }}
                    focusField
                  />
                </SelectableContextMenu>
              )}
            </div>
          </div>{' '}
          <div className='ellipsis_icon' hidden={!templateData?.has_time}>
            <div
              className='task-icon-bg align-items-center px-2'
              onClick={() => {
                setStartTimeAddVisible(true);
              }}
            >
              <IconLabelSet
                label={
                  taskData?.start_time?.hours +
                  ':' +
                  (taskData?.start_time?.minutes < 10
                    ? '0' + taskData?.start_time?.minutes
                    : taskData?.start_time?.minutes) +
                  ' ' +
                  taskData?.start_time?.period
                }
                // label={taskData?.start_time ? convertTo12Hour(taskData?.start_time) : 'Time'}
                icon={<Clock3 size={16} />}
              />
            </div>
            {startTimeAddVisible && (
              <SelectableContextMenu
                isContextVisible={true}
                onRequestClose={() => setStartTimeAddVisible(false)}
                className='time-modal-context-container start-time-popup  menu_popup inline-priority-list '
              >
                <div className='inline-task-sub-head mb-1 mx-2'>Start Time</div>
                <div className='d-flex px-2 align-items-center'>
                  <input
                    type='number'
                    name='hours'
                    value={taskData?.start_time?.hours}
                    onChange={handleStartTimeChange}
                    min='1'
                    max='12'
                    className='input-box'
                    onBlur={handleBlur}
                  />
                  <span className='mx-1'>:</span>
                  <input
                    type='number'
                    name='minutes'
                    value={taskData?.start_time?.minutes}
                    onChange={handleStartTimeChange}
                    min='0'
                    max='59'
                    className='input-box'
                    onBlur={handleBlur}
                  />
                  <select
                    value={taskData?.start_time?.period}
                    onChange={(value) => {
                      handleStartTimeChange(value);
                    }}
                    className='input-box ms-1'
                  >
                    <option value='AM'>AM</option>
                    <option value='PM'>PM</option>
                  </select>
                </div>
              </SelectableContextMenu>
            )}
          </div>
          <div className='ellipsis_icon' hidden={!templateData?.has_time}>
            <div
              className='task-icon-bg align-items-center px-2'
              onClick={() => {
                setEndTimeAddVisible(true);
              }}
            >
              <IconLabelSet
                label={
                  taskData?.end_time?.hours +
                  ':' +
                  (taskData?.end_time?.minutes < 10
                    ? '0' + taskData?.end_time?.minutes
                    : taskData?.end_time?.minutes) +
                  ' ' +
                  taskData?.end_time?.period
                }
                icon={<Clock3 size={16} />}
              />
            </div>
            {endTimeAddVisible && (
              <SelectableContextMenu
                isContextVisible={true}
                onRequestClose={() => setEndTimeAddVisible(false)}
                className='time-modal-context-container start-time-popup  menu_popup inline-priority-list '
              >
                <div className='inline-task-sub-head mb-1 mx-2'>End Time</div>
                <div className='d-flex px-2 align-items-center'>
                  <input
                    type='number'
                    name='hours'
                    value={taskData?.end_time?.hours}
                    onChange={handleEndTimeChange}
                    min='1'
                    max='12'
                    className='input-box'
                    onBlur={handleBlur}
                  />
                  <span className='mx-1'>:</span>
                  <input
                    type='number'
                    name='minutes'
                    value={taskData?.end_time?.minutes}
                    onChange={handleEndTimeChange}
                    min='0'
                    max='59'
                    className='input-box'
                    onBlur={handleBlur}
                  />
                  <select
                    value={taskData?.end_time?.period}
                    onChange={(value) => {
                      handleEndTimeChange(value);
                    }}
                    className='input-box ms-1'
                  >
                    <option value='AM'>AM</option>
                    <option value='PM'>PM</option>
                  </select>
                </div>
              </SelectableContextMenu>
            )}
          </div>
        </div>
        <div
          className='d-flex mt-2'
          hidden={validation_fields?.assignee_id == VALIDATION_VALUES.HIDE}
        >
          <div>
            <div className={`form-group px-1 py-1 `}>
              <label className='label-name mb-1'>Assign User</label>
              <span className='field-required'> *</span>
              <SearchableQuerySelect
                queryKey='user-list'
                queryFn={userService.getRoleBasedUserList}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
                  select: taskAssignUserSelect,
                  ...(taskData?.project_id ? { project_id: taskData?.project_id } : {}),
                  ...(taskData?.template_id ? { template_id: taskData?.template_id } : {}),
                })}
                querySelect={(val) =>
                  val?.data?.rows?.map((item) => {
                    return {
                      ...item,
                      value: item.id,
                      label: item.first_name + ' ' + item.last_name,
                      id: item.id,
                    };
                  })
                }
                initialValue={userList}
                className={` select-box  ${
                  validationData?.includes('assignee_id') ? ' has-error' : ''
                }`}
                isCustomFunction
                styles={CommonDropDownStyle}
                placeholder='Select User'
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option.id}
                value={userList.find((item) => item.id == taskData?.assignee_id)}
                onChange={(selectedOptions) => {
                  onAssigneeChange(selectedOptions);
                  // handleChange({
                  //   target: { value: parseInt(selectedOptions?.id), name: 'assignee_id' },
                  // });
                }}
                formatOptionLabel={(data, metaData) => (
                  <UserDropDown data={data} metaData={metaData} />
                )}
                menuPortalTarget={document.getElementById('MODAL')}
              />
              <ErrorBlock hasError={validationData?.includes('assignee_id')} />
            </div>
          </div>
        </div>
        <div className='d-flex pt-1'>
          <div className='d-flex'>
            <div className='col-md-12'>
              <div className='form-group px-1 py-1 d-flex gap-2'>
                {/* <div className='ellipsis_icon' hidden={!templateData?.has_time}>
                  <div className='task-icon-bg'>
                    <IconLabelSet label={'Time'} icon={<Clock3 size={16} />} />
                  </div>
                </div> */}
                <div className='ellipsis_icon' hidden={!isMenuLabelOn}>
                  <EditableField
                    onSave={(val) => {
                      handleChange({
                        target: { value: val?.label, name: 'label' },
                      });
                    }}
                    key={1}
                    type='select'
                    queryFn={labelService.labelList}
                    functionParams={{
                      select: labelSelectValues,
                    }}
                    placeholder='Select labels'
                    list={labelList}
                    isEditable={validation_fields?.label !== VALIDATION_VALUES.HIDE}
                    data={labelList.filter((item) => taskData?.label.includes(item.value))}
                    name='label'
                    isMulti
                    querySelect={(val) => {
                      return val?.data?.rows?.map((item) => {
                        return {
                          ...item,
                          value: item.id,
                          label: item.name,
                          id: item.id,
                        };
                      });
                    }}
                    formattedOptionLabel={(data) => {
                      return getFormattedLabel({
                        value: data?.id,
                        color: data?.label_color,
                        label: data?.name,
                      });
                    }}
                  >
                    <div
                      role='button'
                      className={`task-icon-bg align-items-center  px-2  ${
                        validationData?.includes('label') ? ' has-error' : ''
                      }`}
                    >
                      <IconLabelSet
                        label={
                          taskData?.label?.length > 0
                            ? taskData?.label?.length + ' Label'
                            : 'Labels'
                        }
                        icon={<Label size={13} />}
                      />
                    </div>
                  </EditableField>
                </div>
                {user?.role == ROLE.ADMIN && isMenuLabelOn && (
                  <CreateCustomLabelAdd
                    tableRef={tableRef}
                    onClose={() => labelRefetch()}
                    afterLabelAdd={(data) => {
                      handleChange({
                        target: { value: [...taskData.label, data?.id], name: 'label' },
                      });
                    }}
                  />
                )}
                <div
                  className='ellipsis_icon'
                  hidden={validation_fields?.priority == VALIDATION_VALUES.HIDE}
                >
                  <div
                    className={`task-icon-bg align-items-center  px-2 ${
                      validationData?.includes('priority') ? ' has-error' : ''
                    }`}
                    onClick={() => {
                      setPriorityContextMenuVisible(true);
                    }}
                  >
                    <IconLabelSet
                      label={taskData?.priority ? selectedPriority?.label : 'Priority'}
                      icon={<Flag size={13} />}
                    />
                  </div>
                  {priorityContextMenuVisible && (
                    <SelectableContextMenu
                      isContextVisible={true}
                      onRequestClose={() => setPriorityContextMenuVisible(false)}
                      className='time-modal-context-container  menu_popup inline-priority-list'
                    >
                      {priorityLabel.map((item, index) => (
                        <div
                          key={index}
                          className='sort-item'
                          style={{
                            height: 28,
                            gap: 7,
                            background: taskData?.priority == item.id ? '#e2fbf0' : '',
                          }}
                          onClick={() => {
                            setPriorityContextMenuVisible(false);
                            handleChange({
                              target: {
                                name: 'priority',
                                value: item?.value,
                              },
                            });
                          }}
                        >
                          <Priority height={13} width={8} color='#2A2E34' />
                          {item?.label}
                        </div>
                      ))}
                      <span
                        className='clear-button-list'
                        style={{ color: '#87909E' }}
                        onClick={() => {
                          setPriorityContextMenuVisible(false);
                          setTaskData((prev) => ({
                            ...prev,
                            priority: 0,
                          }));
                        }}
                      >
                        {' '}
                        <Minus size={17} />
                        Clear
                      </span>
                    </SelectableContextMenu>
                  )}
                  <ErrorBlock hasError={validationData?.includes('priority')} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* client selection section  */}
        {hasExist(MENU?.contact?.default_name) && (
          <div
            className={`d-flex  ${
              (validation_fields?.client_id == VALIDATION_VALUES.HIDE ||
                (roleBasedPermission?.ACTION_CLIENT_LIST === LIST_PERMISSION_VALUE.NO_ACCESS &&
                  validation_fields?.client_id != VALIDATION_VALUES.REQUIRED)) &&
              'd-none'
            }`}
            // hidden={validation_fields?.client_id == VALIDATION_VALUES.HIDE}
          >
            <div>
              <div className={`form-group px-1 py-1 `}>
                <label className='label-name mb-1'>{LABEL_OF_CLIENT}</label>
                {validation_fields?.client_id == VALIDATION_VALUES.REQUIRED && (
                  <span className='field-required'> *</span>
                )}
                <SearchableQuerySelect
                  queryKey='client-list-task'
                  queryFn={clientService.clientList}
                  getFunctionParams={(searchKey) => ({
                    searchKey,
                    select: ['id', 'name'],
                  })}
                  initialValue={clientList}
                  className={` client-select-box ${
                    validationData?.includes('client_id') ? ' client-error' : ''
                  }`}
                  isCustomFunction
                  styles={{
                    ...CommonDropDownStyle,
                    container: (style) => ({
                      ...style,
                      textTransform: 'capitalize',
                      fontSize: 13,
                    }),
                  }}
                  placeholder={`Select ${LABEL_OF_CLIENT}`}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option.id}
                  value={taskData?.client_id}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: { value: selectedOption, name: 'client_id' },
                    });
                  }}
                  menuPortalTarget={document.getElementById('MODAL')}
                  isClearable={validation_fields?.client_id == VALIDATION_VALUES.NOT_REQUIRED}
                  isDisabled={isProjectExistClient}
                />
                <ErrorBlock hasError={validationData?.includes('client_id')} />
              </div>
            </div>
          </div>
        )}
        {/* end of client selection section  */}
        <div
          className='col mb-3 mt-1'
          hidden={
            validation_fields?.verifying_users == VALIDATION_VALUES.HIDE ||
            ((templateData?.verifying_users?.length ?? VALIDATION_VALUES.HIDE) ==
              VALIDATION_VALUES.HIDE &&
              templateData?.is_verify_user_changeable == VALIDATION_VALUES.HIDE)
          }
        >
          <div className='form-group px-2 py-1'>
            <label className='label-name toggle-content d-flex align-items-center gap-2'>
              Verify users
              <span
                style={{ color: 'red' }}
                hidden={templateData?.is_verify_user_changeable == VALIDATION_VALUES.HIDE}
              >
                <ToggleButton
                  key={1}
                  id={1}
                  isChecked={needVerifyUser ? 1 : 0}
                  handleToggle={() => setNeedVerifyUser(!needVerifyUser)}
                  size={28}
                  disabled={templateData?.is_verify_user_changeable == VALIDATION_VALUES.HIDE}
                />
              </span>
            </label>
            <div
              hidden={templateData?.is_verify_user_changeable == VALIDATION_VALUES.REQUIRED}
              className='mt-2'
            >
              <ul>
                {templateData?.verify_users_details?.map((item) => (
                  <li key={item?.id}>
                    <label className='label-name toggle-content d-flex align-items-center gap-2'>
                      {item?.first_name + ' ' + item?.last_name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            {needVerifyUser && (
              <SearchableQuerySelect
                queryKey='user-list-verify'
                queryFn={userService.getRoleBasedUserList}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  select: taskAssignUserSelect,
                  action: USER_LIST_ACTIONS.ACTION_TASK_VERIFY_USER_LIST,
                })}
                isCustomFunction
                initialValue={verifyUserList}
                className={`mt-2 col-md-6 w-80 select-box ${
                  isSubmitted && needVerifyUser && taskData?.verifying_users?.length === 0
                    ? ' has-error'
                    : ''
                }`}
                placeholder='Select '
                getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                getOptionValue={(option) => option.id}
                value={taskData?.verifying_users}
                isMulti
                styles={{
                  ...customSelectBoxStyles,
                  control: (style) => ({
                    ...style,
                    minHeight: 30,
                    zIndex: 99,
                  }),
                }}
                onChange={(selectedOptions) => {
                  handleChange({ target: { value: selectedOptions, name: 'verifying_users' } });
                }}
                menuPlacement='top'
              />
            )}
            <ErrorBlock
              hasError={
                (isSubmitted && needVerifyUser && taskData?.verifying_users?.length === 0) ||
                validationData?.includes('verifying_users')
              }
            />
          </div>
        </div>
        <section hidden={!isMenuSubTaskOn}>
          <div className='px-2 mt-2'>
            <hr className='m-0 my-1' />
          </div>
          <div className='px-2 '>
            <SubtaskListWithAdd
              key={1}
              list={taskData?.subtasks}
              onAddOrUpdate={handleSubTaskAddOrUpdate}
              handleChange={handleChange}
              onDelete={handleSubtaskDelete}
              position={POSITION.RIGHT}
              textMinWidth={'13vw'}
            />
          </div>
        </section>
      </div>
    </section>
  );
};
