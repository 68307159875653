/* eslint-disable no-unused-vars */
import { ArrowLeft, Clock } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconLabelSet from '../../_components/IconLabelSet';
import { RT } from '../../_constants';
import { authService } from '../../_services';
import useTimer from '../../Hook/useTimer';
import { GlobalContext } from '../../Utils';

const EmailVerification = ({ setValue, email, setEmail }) => {
  let navigate = useNavigate();
  const [startTimer, timer] = useTimer(30);
  const { makeAlert } = useContext(GlobalContext);
  const otpRef = useRef([]);
  const [error, setError] = useState();
  const [otp, setOtp] = useState({
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const handleKeyDown = (e, index) => {
    const { value } = e.target;
    if (otpRef.current[index].value != '' && e.keyCode !== 8 && !isNaN(e.target.value)) {
      otpRef.current[index + 1]?.focus();
    }
    if (value == '' && e.keyCode === 8 && otpRef.current[index - 1]) {
      otpRef.current[index - 1]?.focus();
    }
  };
  const handleKeyUp = (e, index) => {
    const { value } = e.target;
    if (value == '' && e.keyCode === 8 && otpRef.current[index - 1]) {
      otpRef.current[index - 1]?.focus();
    }
  };
  const handlePaste = (e) => {
    let value = e.clipboardData.getData('Text');
    let newObj = otp;
    if (value.length <= 6) {
      for (let item in value) {
        if (!isNaN(value[item])) {
          otpRef.current[item].value = value[item];
          newObj[item] = value[item];
        }
      }
    }
  };
  const handleData = (e, index) => {
    const { value } = e.target;
    if (isNaN(value)) {
      otpRef.current[index].value = '';
      return;
    }
    let newObj = { ...otp };
    newObj[index] = value;
    setOtp(newObj);
    if (index !== 5) {
      if (value !== '') {
        otpRef.current[index + 1]?.focus();
      }
    }
  };

  //function use to verify the OTP
  const verifyOtp = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!Object.values(otp).every(Boolean)) {
      return;
    }
    const payload = {
      otp: Object.values(otp).join(''),
      email_id: email.email,
    };
    setTimeout(() => {
      setError('');
    }, 3000);
    try {
      const response = await authService.verifyOtp(payload);
      if (response.error_code == 0) {
        setValue(2);
      }
    } catch (error) {
      if (error.message) {
        setError(error.message);
        makeAlert(error.message);
      }
    } finally {
      setSubmitted(false);
    }
  };

  //function use to msk the email
  function maskEmail(email) {
    const [username, domain] = email.split('@');
    const maskedUsername =
      username.length <= 3 ? username : username.slice(0, 3) + '*'.repeat(username.length - 3);
    return `${maskedUsername}@${domain}`;
  }

  //function use to resent the OTP
  const reSentOtpMail = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (email?.email.toString().trim() == '') {
      return;
    }

    setTimeout(() => {
      setError('');
    }, 3000);
    try {
      const response = await authService.sentOtpMail(email);
    } catch (error) {
      if (error.message) {
        setError(error.message);
        makeAlert(error.message);
      }
    } finally {
      setTimeout(() => {
        setSubmitted(false);
      }, 3000);
    }
  };
  useEffect(() => {
    startTimer();
  }, []);

  return (
    <div>
      <div className='login_header_contain'>
        {' '}
        <div className='login_company_icon_contain'>
          <img src='/logo.svg' />
        </div>
      </div>
      <div className='login_body_container'>
        <div className='row d-flex' style={{ gap: '100px' }}>
          <div className='col-md-5'>
            <div className='mt-5'>
              <div>
                <div className='login-welcome-txt'>Verify email address</div>
                <div className='forgot_second_txt mt-1'>
                  {`    Enter the OTP we send to the email ${maskEmail(email.email)}`}
                </div>
              </div>

              <form name='form' onSubmit={verifyOtp}>
                <div className='card_Container mt-3'>
                  <div className={'form-group'}>
                    <label className='mt-2 login_label mb-1' htmlFor='username'>
                      Enter OTP
                    </label>
                    <div className='d-flex mt-2' style={{ gap: 22 }}>
                      {[0, 1, 2, 3, 4, 5].map((item, index) => (
                        <React.Fragment key={item}>
                          <input
                            type='text'
                            ref={(el) => (otpRef.current[index] = el)}
                            inputMode='numeric'
                            className={
                              'form-control verification_inputbox ' +
                              (submitted && !otp[index] ? ' has-error' : '')
                            }
                            pattern='\d{1}'
                            maxLength={'1'}
                            min={'0'}
                            onPaste={(e) => handlePaste(e)}
                            onChange={(e) => handleData(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onKeyUp={(e) => handleKeyUp(e, index)}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <div className='form-group   mt-3'>
                    <button className='btn login_Button width-100' disabled={submitted}>
                      {' '}
                      Verify
                    </button>
                  </div>
                  <div
                    className='d-flex align-items-center justify-content-end  mt-2'
                    style={{ width: 320 }}
                  >
                    {' '}
                    {timer !== 0 ? (
                      <div className='d-flex align-items-center gap-1 ' >
                        <Clock size={13}  />
                        <div>
                          {('0' + Math.floor((timer / 60000) % 60)).slice(-2)}:
                          {('0' + Math.floor((timer / 1000) % 60)).slice(-2)}.
                        </div>
                      </div>
                    ) : (
                      !submitted && (
                        <div
                          className='forgot-txt mt-1 cursor-pointer'
                          onClick={(e) => {
                            startTimer();
                            // Clear the OTP state
                            setOtp({
                              0: '',
                              1: '',
                              2: '',
                              3: '',
                              4: '',
                              5: '',
                            });

                            // Clear the input values
                            otpRef.current.forEach((input) => {
                              if (input) input.value = '';
                            });

                            reSentOtpMail(e);
                          }}
                        >
                          Resend OTP
                        </div>
                      )
                    )}
                  </div>
                  <div className='mt-4'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <IconLabelSet
                        fontSize={13}
                        className='cursor-pointer'
                        fontWeight={500}
                        color={'#1E3DB9'}
                        icon={<ArrowLeft size={14} />}
                        label={'Return to login'}
                        onClick={() => navigate(`/${RT.LOGIN}`)}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-center error-txt'>{error}</div>{' '}
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='login_img_container'>
              <img src='/icons/forgotpasswordverification.png' style={{ height: '450px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
