/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { X } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { Button } from '../_components';
import EmailInput from '../_components/EmailInput';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CommonDropDownStyle } from '../_constants';
import { roleService, userService } from '../_services';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { GlobalContext } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { MY_TEAM_REQUIRED_FIELD_MSG } from './MyTeamConstant';

export const InviteEmployeeForm = ({ handleClose = () => {}, afterInvite = () => {} }) => {
  const [inviteData, setInviteData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const { makeAlert } = useContext(GlobalContext);

  const handleInviteUser = async () => {
    await userService.inviteUser(inviteData);
    // return response;
  };

  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleInviteUser,
    mutationKey: ['invite-mail'],
    onSuccess: (data) => {
      handleClose();
      afterInvite();
    },
    onError: (err) => {
      setIsLoading(false);
      setSubmitted(false);
      if (err?.data?.length) {
        const emailIds = err.data.map((data) => data.email_id).join(', ');
        const pluralSuffix = err.data.length > 1 ? 's' : '';
        setEmailError(`Email ID${pluralSuffix} ${emailIds} already exist${pluralSuffix}`);
        makeAlert(`Email ID${pluralSuffix} ${emailIds} already exist${pluralSuffix}`);
      }
      // makeAlert(err?.message);
    },
  });
  // if (isError) {
  //   makeAlert('errpr');
  // }

  // useEffect(() => {
  //   if (error?.data?.length) {
  //     const emailIds = error.data.map((data) => data.email_id).join(', ');
  //     const pluralSuffix = error.data.length > 1 ? 's' : '';
  //     makeAlert(`Email ID${pluralSuffix} ${emailIds} already exist${pluralSuffix}`);
  //   }
  // }, [error]);

  // role list
  const { data: roleData, isLoading: roleLoading } = useQuery({
    queryKey: ['roleList-invites'],
    queryFn: () => roleService.roleList(),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'role') {
      return setInviteData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === 'email_ids') {
      if (emailError) {
        setEmailError('');
      }
      return setInviteData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    setIsLoading(true);
    if (!inviteData?.role || inviteData?.email_ids?.length <= 0 || !inviteData?.email_ids) {
      setIsLoading(false);
      if (inviteData?.email_ids?.length <= 0 || !inviteData?.email_ids) {
        setEmailError(MY_TEAM_REQUIRED_FIELD_MSG.INVALID_EMAIL);
      }
      return null;
    }
    handleMutate();
  };
  return (
    <div className='d-flex flex-column' style={{ height: '100vh' }}>
      {/* <form onSubmit={handleSubmit}> */}
      <div className=' modal_header'>
        <div className='invite-head fz-16px'>Employee invite</div>
        <div
          className='pointer'
          onClick={() => {
            handleClose();
          }}
        >
          <X size={16} color='#87909E' />
        </div>
      </div>
      <div className='invite-form-body'>
        {/* email section  */}
        <div>
          <div className='form-input-label'>
            Email
            <span className='field-required'> *</span>
            <EmailInput
              isEmailValidate={true}
              onChangeData={(mail) =>
                handleChange({
                  target: {
                    name: 'email_ids',
                    value: mail,
                  },
                })
              }
              error={emailError}
              setError={setEmailError}
            />
          </div>
        </div>
        {/* Role section  */}
        <div>
          <div className='form-input-label'>
            Role
            <span className='field-required'> *</span>
          </div>
          {/* <ReactSelect
            name='role'
            styles={DropDownStyle}
            queryKey='search-role'
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            getOptionLabel={(option) => {
              return option?.name;
            }}
            getOptionValue={(option) => {
              return option?.id;
            }}
            filterOption={null}
            options={roleData?.data?.rows}
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'role',
                  value: selectedOption?.default_name,
                },
              });
            }}
            isLoading={roleLoading}
            value={
              roleData?.data?.rows?.find((option) => option?.default_name === inviteData?.role) ||
              null
            }
            // value={roleData?.data?.find((option) => option?.number === inviteData?.role) || null} // Set the value based on project.company_id
          /> */}

          <SearchableQuerySelect
            queryKey={'role-list-invite'}
            queryFn={roleService.roleList}
            getFunctionParams={(searchKey) => ({
              searchKey,
              select: ['id', 'name'],
            })}
            isCustomFunction
            initialValue={roleData?.data?.rows}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option.id}
            value={
              roleData?.data?.rows?.find((option) => option?.default_name === inviteData?.role) ||
              null
            }
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'role',
                  value: selectedOption?.default_name,
                },
              });
            }}
            styles={CommonDropDownStyle}
            placeholder='Choose role'
            className={`select-box ${submitted && !inviteData?.role && 'has-error'} `}
          />
          <ErrorBlock
            errorMsg={'Role selection required'}
            hasError={submitted && !inviteData?.role}
          />
          {/* End of role selection section  */}
        </div>
      </div>
      <div className='modal_footer '>
        <Button.Container
          className={'cancel-btn'}
          type='button'
          handleOk={() => {
            handleClose();
          }}
        >
          <Cancel />
          <Button.Title title={'Cancel'} />
        </Button.Container>

        <Button.Container
          type='submit'
          className='blue-btn'
          isDisabled={isLoading}
          handleOk={handleSubmit}
        >
          <Tick />
          <Button.Title title='Save' />
          <Button.Loading isLoading={isLoading} />
        </Button.Container>
      </div>
      {/* </form> */}
    </div>
  );
};
