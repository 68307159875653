/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { GlobalContext, taskFilter } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { Button } from '../_components';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { CommonDropDownStyle, MENU } from '../_constants';
import { clientService, documentTypeService, folderService } from '../_services';

 // file type filter list
 const type_option = [
    { name: 'Digital File', id: 1 },
    { name: 'Physical File', id: 2 },
  ];

  
  // expired on filter list
  const expiry_option = [
    { name: '1 month', id: 1 },
    { name: '3 month', id: 2 },
    { name: '6 month', id: 3 },
    { name: '1 year', id: 4 },
  ]; 
export const FileListFilter = ({
  handleClose = () => {},
  setSearchParams,
  searchParams,
  fileListFromClient = false
}) => {
  const [filter, setFilter] = useState({});


  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };


  const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
     const params = new URLSearchParams(window.location.search);
     const newFilterData = {};
 
     params.forEach((value, key) => {
       if (key === 'folder' || key === 'document_type' || key === 'client') {
         newFilterData[key] = value;
       }
       if (key === 'expiry') {
         newFilterData[key] = expiry_option.find((item) => parseInt(item.id) === parseInt(value));
       }
       if (key === 'type') {
         newFilterData[key] = type_option.find((item) => parseInt(item.id) === parseInt(value));
       }
     });
 
     setFilter(newFilterData);
   }, []);
 
console.log('ooooooooo',filter);




//   useEffect(() => {
//     const fetchTemplatesByIds = async (ids) => {
//       const promises = ids.map((id) =>
//         templateService
//           .getSingleTemplate({ id: id, select: ['id', 'name'] })
//           .then((res) => res.data),
//       );
//       return Promise.all(promises);
//     };

//     const fetchProjectsByIds = async (ids) => {
//       const promises = ids.map((id) =>
//         projectService.projectGet({ id: id, select: ['id', 'name'] }).then((res) => res.data),
//       );
//       return Promise.all(promises);
//     };

//     const fetchClientsByIds = async (ids) => {
//       const promises = ids.map((id) =>
//         clientService.clientGet({ id: id, select: ['id', 'name'] }).then((res) => res.data?.[0]),
//       );
//       return Promise.all(promises);
//     };

//     const params = new URLSearchParams(window.location.search);
//     const newFilterData = structuredClone(taskFilter);

//     const templateIds = [];
//     const projectIds = [];
//     const clientIds = [];

//     params.forEach((value, key) => {
//       if (
//         key === 'priority_id' ||
//         key === 'label_id' ||
//         key === 'template_id' ||
//         key === 'project_id' ||
//         key === 'client_id'
//       ) {
//         if (!newFilterData[key]) newFilterData[key] = [];
//         newFilterData[key].push(isNaN(value) ? value : parseInt(value));
//         if (key === 'template_id') {
//           templateIds.push(parseInt(value));
//         }
//         if (key === 'project_id') {
//           projectIds.push(parseInt(value));
//         }
//         if (key === 'client_id') {
//           clientIds.push(parseInt(value));
//         }
//       } else if (key === 'task_start_date' || key === 'task_end_date') {
//         const date = moment(parseInt(value));
//         newFilterData[key] = date.format('YYYY-MM-DD');
//       } else if (key === 'task_status') {
//         newFilterData[key] = parseInt(value);
//       } else if (key !== 'status') {
//         newFilterData[key] = value;
//       }
//     });

//     const updateFilterDataWithTemplatesAndProjects = async () => {
//       if (templateIds.length > 0) {
//         const templates = await fetchTemplatesByIds(templateIds);
//         newFilterData.template_id = templates.map((template) => ({
//           id: template[0].id,
//           name: template[0].name,
//         }));
//       }

//       if (projectIds.length > 0) {
//         const projects = await fetchProjectsByIds(projectIds);
//         newFilterData.project_id = projects.map((project) => ({
//           id: project.id,
//           name: project.name,
//         }));
//       }

//       if (clientIds.length > 0) {
//         const clients = await fetchClientsByIds(clientIds);
//         newFilterData.client_id = clients.map((client) => ({
//           id: client.id,
//           name: client.name,
//         }));
//       }

//       setFilterData(newFilterData);
//     };

//     updateFilterDataWithTemplatesAndProjects();
//   }, []);

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     if (name === 'template_id' || name === 'project_id' || name === 'client_id') {
//       setFilterData((prev) => ({
//         ...prev,
//         [name]: value.map((item) => item),
//       }));
//       return;
//     }

//     setFilterData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };
  // handle filter change
  const handleFilterChange = (selectedOption, filter) => {
    setFilter((prev) => ({
      ...prev,
      [filter]: selectedOption,
    }));
    // searchParams.delete(filter);

    // if (selectedOption) {
    //   searchParams.append(filter, selectedOption?.id);
    //   searchParams.set('pageNo', 1);
    //   setQuery({
    //     ...query,
    //     pageVo: { ...query.pageVo, pageNo: 1 },
    //   });
    // }
    // setSearchParams(searchParams);
  };

//     if (name === 'priority_id' || name === 'label_id') {
//       const exists = filterData[name]?.includes(value);
//       const updatedArray = exists
//         ? filterData[name]?.filter((item) => item !== value)
//         : [...filterData[name], value];

//       setFilterData((prev) => ({
//         ...prev,
//         [name]: updatedArray,
//       }));
//     }
//   };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Get the current URL parameters
    const params = new URLSearchParams(window.location.search);

    // Remove the unwanted parameters
    params.delete('pageNo');
    params.delete('type');
    params.delete('expiry');
    params.delete('document_type');
    params.delete('folder');
    params.delete('client');

    let document_type = filter?.document_type?.id ?? null;
    let folder = filter?.folder?.id?? null;
    let client = filter?.client?.id?? null;
    let type = filter?.type?.id?? null;
    let expiry = filter?.expiry?.id?? null;
    
    // Convert task_start_date and task_end_date to milliseconds if they exist
    const updatedFilterData = {
      ...filter,
      folder: folder,
      document_type: document_type,
      client: client,
      type:type,
      expiry:expiry
    };

    

    // Add the filter data to the URL parameters
    Object.keys(updatedFilterData).forEach((key) => {
      if (updatedFilterData[key]) {
        params.set(key, updatedFilterData[key]);
      }
    });
    params.set('pageNo', 1);

    // Update the URL
    setSearchParams(params, { replace: true });
    setIsLoading(false);
    handleClose(event);
  };
// const handleSubmit = () => {}
  const handleClear = (event) => {
    // const params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();

    // for (const [key, value] of params) {
    //   // Retain the parameters that should not be removed
    //   if (
    //     key === 'status' ||
    //     key === 'user_id' ||
    //     key === 'type' ||
    //     key === 'milestone_id' ||
    //     key === 'sort' ||
    //     key === 'list_type' ||
    //     key === 'search' ||
    //     key === 'currentView'
    //   ) {
    //     newParams.append(key, value);
    //   }
    // }

    // Update the URL with the new parameters
    setSearchParams(newParams);
    // Clear the filter data
    setFilter(taskFilter);
    handleClose(event);
  };

    const fetchFolder = async () => {
      const data = await folderService.folderGet({ id: filter?.folder, select: ['id', 'name'] });
  
      setFilter((prev) => ({
        ...prev,
        folder: data?.data?.[0],
      }));
  
      return data;
    };
  
    const fetchClient = async () => {
      const data = await clientService.clientGet({ id: filter?.client, select: ['id', 'name'] });
      setFilter((prev) => ({
        ...prev,
        client: data?.data?.[0],
      }));
  
      return data;
    };
  
    const fetchDocumentType = async () => {
        console.log('ooooooooooooooo1');
        
      const data = await documentTypeService.documentTypeGet({
        id: filter?.document_type,
        select: ['id', 'name'],
      });
      setFilter((prev) => ({
        ...prev,
        document_type: data?.data?.[0],
      }));
  
      return data;
    };

    // document type list
    const { data: documentTypes } = useQuery({
        queryKey: ['document-type-list'],
        queryFn: () =>
          documentTypeService.documentTypeList({
            searchKey: '',
            select: ['id', 'name'],
          }),
        select: (data) => data.data.rows,
      });
    
      // Client list
      const { data: clientData } = useQuery({
        queryKey: ['clientList'],
        queryFn: () =>
          clientService.clientList({ searchKey: '', select: ['id', 'name'], forFile: true }),
        select: (data) => {
          return data?.data?.rows;
        },
        enabled: !fileListFromClient,
      });
    
      const { data: folderData } = useQuery({
        queryKey: ['file-folder-list'],
        // eslint-disable-next-line no-undef
        queryFn: () => folderService.folderList(query),
        select: (data) => {
          return data?.data?.rows;
        },
      });
    
      // fetch file details
      const { data: folder } = useQuery({
        queryKey: ['folder-data', filter?.folder],
        queryFn: () => fetchFolder(),
        enabled:
        typeof filter?.folder !== 'object' &&
        !!filter?.folder &&
          !folderData?.some((item) => parseInt(item.id) === parseInt(filter.folder.id)), // Only run the query if the id is set
      });
    
      // fetch client details
      const { data: client } = useQuery({
        queryKey: ['client-data', filter?.client],
        queryFn: () => fetchClient(),
        enabled: typeof filter?.client !== 'object' &&
          !!filter?.client &&
          !clientData?.some((item) => parseInt(item.id) === parseInt(filter.client.id)), // Only run the query if the id is set
      });
    
      // fetch document type details
      const { data: docType } = useQuery({
        queryKey: ['doc-type-data', filter?.document_type],
        queryFn: () => fetchDocumentType(),
        enabled:
        typeof filter?.document_type !== 'object' &&
        !!filter?.document_type &&
        !documentTypes?.some((item) => parseInt(item.id) === parseInt(filter.document_type.id)),     });
    
  return (
    <div className={`menu_popup filter-container `} style={{ padding: 20, right: 0 }}>
      <form onSubmit={handleSubmit}>
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Filter</h6>
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer' }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
        <div className='filter-body'>
            
                <div className='filter-item-name'>FOLDER</div>
                <SearchableQuerySelect
                 queryFn={folderService.folderList}
                getFunctionParams={(searchKey) => ({
                    searchKey,
                     select: ['id', 'name'],
                      })}
                initialValue={folderData}
                placeholder='Choose Folder'
                isCustomFunction
                  styles={CommonDropDownStyle}
                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                  getOptionLabel={(option) => option?.name}
                //   isMulti
                isClearable
                  getOptionValue={(option) => parseInt(option?.id)}
                  value={filter?.folder ?? null}
                  onChange={(selectedOptions) => {
                    handleFilterChange(selectedOptions, 'folder');
                  }}
                //   isLoading={isTemplateLoading}
                />
              
            

           
              <div className='row'>
                <div className=' filter-item-name text-uppercase'>DOCUMENT TYPE</div>
                <SearchableQuerySelect
                                queryKey='doctype-list'
                   queryFn={documentTypeService.documentTypeList}
                   getFunctionParams={(searchKey) => ({
                                    searchKey,
                                    select: ['id', 'name'],
                                  })}
                                  initialValue={documentTypes}
isCustomFunction
isClearable
                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                  placeholder={`Choose Document type`}
                  styles={CommonDropDownStyle}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  value={filter?.document_type ?? null}
                  onChange={(selectedOptions) => {
                    handleFilterChange(selectedOptions, 'document_type');
                  }}
                //   isLoading={isProjectLoading}
                />
              </div>
            

          { hasExist(MENU.contact.default_name) && !fileListFromClient&& (
            <div className='row'>
              <div className='filter-item-name text-uppercase'>{LABEL_OF_CLIENT}</div>
              <SearchableQuerySelect
                queryKey='client-list-task'
                queryFn={clientService.clientList}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  select: ['id', 'name'],
                })}
                initialValue={clientData}
                className={` client-select-box `}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                isCustomFunction
                isClearable
                styles={CommonDropDownStyle}
                // isLoading={isClientLoading}
                placeholder={`Choose ${LABEL_OF_CLIENT}`}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option.id}
                value={filter?.client ?? null}
                onChange={(selectedOptions) => {
                    handleFilterChange(selectedOptions, 'client');
                  }}
              />
            </div>
          )}


            <div className='row'>
              <div className='filter-item-name text-uppercase'>FOLDER TYPE</div>
              <ReactSelect
                  options={type_option}
                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                  placeholder={`Choose Folder type`}
                  styles={CommonDropDownStyle}
                  isClearable
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => parseInt(option?.id)}
                //   onInputChange={(inputString) => setProjectSearch(inputString)}
                //   inputValue={projectSearch}
                  value={filter?.type ?? null}
                  onChange={(selectedOptions) => {
                    handleFilterChange(selectedOptions, 'type');
                  }}
                //   isLoading={isProjectLoading}
                />
                  
            </div>
          

          {/* archive task list filter  */}
          {/* {currentView === TASK_TABS.TASKS && ( */}
          
            <div className='row'>
              <span className='filter-item-name'>EXPIRY DATE</span>
              <ReactSelect
                      isClearable
                      styles={CommonDropDownStyle}
                      options={expiry_option}
                      placeholder={`Choose Expiry on`}
                      menuPlacement='top'
                      value={filter?.expiry ?? null}
                      onChange={(selectedOptions) => {
                        handleFilterChange(selectedOptions, 'expiry');
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}

                    />
            
             
            </div>
          
           
       
        </div>

        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container
              className={'outline-cancel-button'}
              type='button'
              handleOk={handleClose}
            ><Cancel/>
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' className='blue-btn' isDisabled={false}>
              <Tick />
              <Button.Title title={'Apply'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
