/* eslint-disable no-unused-vars */
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { Home, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Modal, TableRow } from '../_components';
import DatePicker from '../_components/DatePicker';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { SearchBar } from '../_components/Search';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import ToolTip from '../_components/Tooltip/ToolTip';
import { CommonDropDownStyle, LIST_PERMISSION_VALUE, MENU, RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { clientService } from '../_services';
import { timeSheetService } from '../_services/timesheet.service';
import useObserver from '../Hook/useObserver';
import { FileSystemImage, getSettingsByName, GlobalContext, MyTeamIcon } from '../Utils';
import { LoadingIcon, ProjectIcon } from '../Utils/SvgIcons';
import './timesheet.css';
import { TimeSheetAdd } from './TimeSheetAdd';
import { TimeSheetGenerate } from './TimeSheetGenerate';
import { TimeSheetListBody } from './TimeSheetListBody';
import { TimeSheetSingle } from './TimeSheetSingle';

export const TimeSheetList = () => {
  const HEADS = [
    '#',
    'Time Sheet',
    'From Date',
    'To Date',
    'Client',
    'Total Hours',
    'Billing Hours',
    '',
    '',
  ];
  // hooks Section
  const nav = useNavigate();
  const { getMenuLabelName, roleBasedPermission, globalSettings } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const menuSettings = getSettingsByName(globalSettings, 'menu-settings');
  const hasExist = (default_name) => {
    return menuSettings?.labels?.some((label) => label.default_name === default_name);
  };

  const [timesheetData, setTimeSheetData] = useState([]);
  const [timeSheetRows, setTimeSheetRows] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // reference section
  const tableContainerRef = useRef(null);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showSingleViewModal, setShowSingleViewModal] = useState(false);
  const [showDatePick, setShowDatePick] = useState(false);
  const [timeSingleData, setTimeSingleData] = useState({});
  const [filterData, setFilterData] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };

  //filters
  const searchKey = getParam('search') ? getParam('search') : '';
  const clientId = getParam('clientId') ? getParam('clientId') : '';
  const fromDate = getParam('fromDate') ? getParam('fromDate') : '';
  const toDate = getParam('toDate') ? getParam('toDate') : '';
  const debouncedSearch = useDebounce(searchKey, 500);
  const debouncedClientId = useDebounce(clientId, 500);
  const debouncedFromDate = useDebounce(fromDate, 500);
  const debouncedToDate = useDebounce(toDate, 500);

  // query key in which filtered list rendering
  const queryKey = [
    'task',
    query?.pageVo?.pageNo,
    debouncedSearch,
    debouncedClientId,
    debouncedFromDate,
    debouncedToDate,
  ];
  const {
    data: timeSheetList,
    isLoading: timeSheetListLoading,
    refetch: timeSheetRefetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      timeSheetService.getTimeSheetList({
        searchKey: searchKey,
        clientId: clientId,
        fromDate: fromDate,
        toDate: toDate,
        pageVo: {
          pageNo: pageParam,
          noOfItems: 10,
        },
      }),
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });

  const flattedUpdateData = React.useMemo(
    () => timeSheetList?.pages?.flatMap((page) => page.data.rows),
    [timeSheetList],
  );
  const allUpdateList = flattedUpdateData ? [...flattedUpdateData] : [];
  // const timeSheetList = async (pageNo) => {
  //   try {
  //     if (pageNo) {
  //       query.pageVo.pageNo = pageNo;
  //     }
  //     setQuery(query);
  //     setIsLoading(true);

  //     const response = await timeSheetService.getTimeSheetList(query);

  //     if (response.data) {
  //       setTimeSheetData(response.data.rows);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmitFilter = async (filterData) => {
    setIsLoading(true);
    try {
      const response = await timeSheetService.getTimeSheetEntries(filterData);
      response.data.userIds = filterData.userIds;
      if (response) {
        setTimeSheetRows(response.data);
        setShowAddModal(false);
        setShowGenerateModal(true);
        setFilterData(filterData);
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateSheet = async (timesheetData) => {
    setIsLoading(true);
    try {
      const response = await timeSheetService.generateTimeSheet(timesheetData);

      if (response.data) {
        setShowGenerateModal(false);
        timeSheetRefetch(1);
      }
    } catch (err) {
      console.log(err, 'this is generate err');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeClient = (selectedOption) => {
    searchParams.delete('clientId');
    if (selectedOption?.id) {
      searchParams.set('clientId', selectedOption?.id);
    }
    setQuery((prev) => ({
      ...prev,
      clientId: selectedOption?.id,
      client_name: selectedOption?.name,
    }));
    setSearchParams(searchParams, { replace: true });
  };

  // handle change of date
  const handleDateChange = (dates) => {
    const startDate = moment(dates.startDate).startOf('day');
    const endDate = moment(dates.endDate).endOf('day');
    if (startDate) {
      searchParams.delete('fromDate');
      searchParams.set('fromDate', moment(dates.startDate).valueOf());
    }
    if (endDate) {
      searchParams.delete('toDateDate');
      searchParams.set('toDate', moment(dates.endDate).valueOf());
    }
    setSearchParams(searchParams, { replace: true });
  };

  const handleClickRow = (data) => {
    setShowSingleViewModal(true);
    setTimeSingleData(data);
  };

  const handleDelete = async (id) => {
    try {
      const response = await timeSheetService.deleteTimeSheet({ id: id });
    } catch (err) {
      console.log(err);
    } finally {
      timeSheetRefetch();
      console.log('finally');
    }
  };

  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  // NO ACCESS MANAGE
  if (roleBasedPermission?.ACTION_TIME_SHEET_LIST === LIST_PERMISSION_VALUE.NO_ACCESS) {
    return <NoPermission />;
  }

  return (
    <div className=' fade-entry '>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Time Sheet'}
            fontWeight={500}
            color={`#2A2E34`}
            icon={<ProjectIcon height={12} width={12} color='#2A2E34' />}
            onClick={() => nav(`/${RT.TIMESHEET}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div style={{ minHeight: '80vh' }}>
        <ErrorComponent error={error?.message} />
        <div>
          <div className='project-table-container pt-0'>
            <div className='timesheet-hedsection'>
              <div className='d-flex justify-content-start gap-3 mt-2 flex-wrap align-items-center'>
                <SearchBar
                  handleSearch={(event) => {
                    if (event.target.value) {
                      searchParams.set('search', event.target.value);
                      setSearchParams(searchParams);
                    } else {
                      searchParams.delete('search');
                      setSearchParams(searchParams);
                    }
                  }}
                  searchKey={searchParams.get('search') ?? ''}
                />

                {/* <div className=''> */}
                <SearchableInfiniteQuerySelect
                  name='client_id'
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  queryFn={clientService.clientList}
                  queryKey={['client-list-task', query?.client_id]}
                  enableCondition={true}
                  isCustomFunction
                  getFunctionParams={(searchKey) => {
                    return {
                      searchKey: searchKey,
                      select: ['id', 'name'],
                    };
                  }}
                  onChange={(val) => handleChangeClient(val)}
                  placeholder={`Select Client`}
                  styles={CommonDropDownStyle}
                  value={query?.clientId ? { name: query?.client_name, id: query?.clientId } : null}
                  menuPortalTarget={document.getElementById('MODAL')}
                  isClearable
                />
                {/* </div> */}
                <div className=''>
                  <DatePicker
                    inputContainerClass={'date-picker'}
                    isDatePrefixNeed={false}
                    onDateChange={handleDateChange}
                    isEditable
                    initialStartDate={parseInt(fromDate)}
                    initialEndDate={parseInt(toDate)}
                  />
                </div>
              </div>
              {roleBasedPermission?.ACTION_TIME_SHEET_ADD && (
                <div className=''>
                  <ToolTip tooltipText={`Add Time Sheet`} isModern>
                    <div
                      className='menu-item-outline'
                      // className='add_item_button'
                      onClick={() => {
                        setShowAddModal(true);
                      }}
                    >
                      <Plus size={16} color='#87909E' />
                    </div>
                  </ToolTip>
                </div>
              )}
            </div>
            <section className='mt-2'>
              <div className='time-sheet-table-container no-padding' ref={tableContainerRef}>
                <Table className='table tbl mb-0 task-table'>
                  <Thead>
                    <Tr className='table-head'>
                      {HEADS.map((heading, index) => {
                        return (
                          <Th
                            key={index}
                            scope='col'
                            // style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                          >
                            {heading}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody style={{ verticalAlign: 'middle' }}>
                    {timeSheetListLoading && (
                      <TableRow noOfCols={8}>
                        <LoadingIcon size={60} />
                      </TableRow>
                    )}
                    {/* {!timeSheetListLoading && allUpdateList.length == 0 && (
                      <TableRow noOfCols={8}>No data found</TableRow>
                    )} */}
                    {allUpdateList.length > 0 && !timeSheetListLoading && (
                      <TimeSheetListBody
                        data={allUpdateList}
                        handleClick={handleClickRow}
                        handleDelete={handleDelete}
                      />
                    )}
                    <Tr>
                      <Td colSpan='8' style={{ height: '0px', border: 'none', padding: 0 }}>
                        <div ref={observerRef} className='d-flex justify-content-center'>
                          {hasNextPage && isFetchingNextPage && (
                            <div
                              className='cursor-pointer'
                              onClick={() => hasNextPage && fetchNextPage()}
                              style={{ height: '90px' }}
                            >
                              <LoadingIcon size={60} />
                            </div>
                          )}
                        </div>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
                <div
                  className={
                    allUpdateList?.length == 0 && !timeSheetListLoading
                      ? 'timesheet-nodata project-table-container'
                      : 'd-none'
                  }
                >
                  <img src={FileSystemImage.EmptyDocument} className='timesheet-nodataiconbg' />
                  <div className='timesheet-nodatatxt'>No timesheet found</div>
                  <div className='timesheet-nodatasubtext'>Your timesheet is listed here</div>
                  <div className='timesheetgeneratebtn mt-2' onClick={() => setShowAddModal(true)}>
                    <Plus size={16} color='#87909E' /> Generate
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {showAddModal && (
        <Modal.Container>
          <Modal.View
            className={'widget-form-container'}
            containerClass={'d-flex justify-content-end modal_right_side'}
          >
            <TimeSheetAdd
              handleClose={() => setShowAddModal(false)}
              handleSubmit={handleSubmitFilter}
              isLoading={isLoading}
            />
          </Modal.View>
        </Modal.Container>
      )}
      {showGenerateModal && (
        <Modal.Container>
          <Modal.View className={'team-list timesheet-modal_view'}>
            <TimeSheetGenerate
              payload={timeSheetRows}
              handleSuccess={handleGenerateSheet}
              handleClose={() => setShowGenerateModal(false)}
            />
          </Modal.View>
        </Modal.Container>
      )}
      {showSingleViewModal && (
        <Modal.Container>
          <Modal.View className={'team-list timesheet-modal_view'}>
            <TimeSheetSingle
              payload={timeSingleData}
              handleSuccess={handleGenerateSheet}
              handleClose={() => {
                setShowSingleViewModal(false), setTimeSingleData({});
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};
