/* eslint-disable no-unused-vars */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ChevronDown,
  CircleChevronDown,
  GalleryHorizontalEnd,
  InfinityIcon,
  PanelLeftClose,
  PanelLeftOpen,
  Settings,
} from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { favTypes } from '../Favorites/favCostants';
import { useUser } from '../Hook/useUser';
import { useWhoAmI } from '../Hook/useWhoAmI';
import { CustomSelectModal } from '../Task/TaskComponents/CustomInputs';
import { getSettingsByName, getTemplateMenu, GlobalContext, Images, ReturnIcon } from '../Utils';
import {
  FileIconSidebar,
  GroupUser,
  HiDeskIcon,
  LoadingIcon,
  PinIconSideBar,
  ProgressIcon,
  ReportsIcon,
  TemplateIcon,
  TimeHistoryIcon,
  WorkActivityIcon,
} from '../Utils/SvgIcons';
import { Popover } from '../_components/Popover';
import UserDropDown from '../_components/UserDropDown';
import { apiConstants, LIST_PERMISSION_VALUE, MENU, RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { organizationService } from '../_services/organization.service';
import './sidebar.css';

export function SideBarMenu({ isOpen, toggleSidebar }) {
  const { globalSettings, isSettingLoading, roleBasedPermission, getMenuLabelName } =
    useContext(GlobalContext);

  const { data: newUser } = useWhoAmI();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCheckedPin, setIsCheckedPin] = useState(false);

  // reports dropdown
  const [isChecked, setIsChecked] = useState(false);
  const handleMouseEnter = () => {
    setIsChecked(true);
  };
  const handleMouseLeave = () => {
    setIsChecked(false);
  };

  //date's
  const todayStart = moment().startOf('day').valueOf();
  const todayEnd = moment().endOf('day').valueOf();
  const todayCurrent = moment().valueOf();
  const monthStartDate = moment().startOf('month').valueOf();

  // fetching company logo
  const GeneralSettings = getSettingsByName(globalSettings, 'general-settings');
  const companyLogo =
    GeneralSettings?.labels?.find((settings) => settings?.label === 'Logo').value || '';
  let companyIcon = apiConstants.imgUrlS3 + companyLogo;

  const [organizationSearch, setOrganizationSearch] = useState(''); // state for  organization list organizationSearch
  const debounceOrganization = useDebounce(organizationSearch, 500);

  // checking if menu exist
  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');
  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels?.some((label) => label?.default_name === default_name)
    );
  };
  const pinnedCheckRef = useRef(null); // reference for pin drop-down
  const todoCheckboxRef = useRef(null); // reference for todo drop-down
  const handleNavLinkClick = () => {
    if (todoCheckboxRef?.current) {
      todoCheckboxRef.current.checked = false;
    }
    // scrollbar close on responsive
    isOpen && toggleSidebar();
  };

  const team = getLabelProperty('team-responsibility', 'in_progress_id');
  const self = getLabelProperty('my-responsibility', 'in_progress_id');
  const user = useUser()?.id;
  // Function to get the name of a label based on its default_name
  function getLabelName(defaultName) {
    const label =
      menuSettings && menuSettings?.labels?.find((label) => label.default_name === defaultName);

    return label ? label?.name : null;
  }

  //extra menu's
  const extraMenus = menuSettings?.labels?.filter((label) => label?.type === 'template') || [];

  //contact and project
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);
  const LABEL_OF_TIMESHEET = getMenuLabelName(MENU.timesheet.default_name);

  // get notification count from react query cache   queryKey: ['notificationCount'],
  const queryClient = useQueryClient();
  // Get notification count from React Query cache
  const notificationCount = queryClient.getQueryData(['notificationCount']);
  const chatCount = notificationCount?.data?.chatNotificationCount;

  // organization list
  const { data: orgData } = useQuery({
    queryKey: ['COMPANY-SWITCH-LIST', debounceOrganization],
    queryFn: () =>
      organizationService.organizationList({
        searchKey: organizationSearch,
        select: ['id', 'name', 'logo'],
      }),
    enabled: user ? true : false,
  });

  //-- handleCompanyChange
  const handleCompanyChange = async (selectedOption) => {
    if (selectedOption.id === user?.company_id) {
      return;
    }

    const userDetails = await organizationService.makeDefaultOrganization({
      company_id: selectedOption?.id,
      company_name: selectedOption?.name,
    });

    // setIsOpen(false);
    // 

    if (userDetails?.data?.is_profile_completed) {
      navigate('/');
    } else {
      navigate(`/${RT.INVITE_ACCEPT}?view=signUp`);
    }
    window.location.reload();
  };

  const orgList = orgData?.data?.rows
    ? orgData.data.rows.map((org) => ({ image_url: org.logo, name: org?.name, id: org?.id }))
    : [];

  // favorites item map
  const favorites =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'favorites');
  const handleMouseEnterPin = () => {
    setIsCheckedPin(true);
  };
  const handleMouseLeavePin = () => {
    setIsCheckedPin(false);
  };
  const favoriteItem = (data, index) => {
    const name =
      data.type == favTypes.TASK
        ? data['Task.name']
        : data.type == favTypes.PROJECT
        ? data['Project.name']
        : 'Other';

    const link =
      data.type == favTypes.TASK
        ? `${RT.TASK}/${data?.task_id}?tab=work-history`
        : data.type == favTypes.PROJECT
        ? `${RT.PROJECT}/${data?.project_id}/${RT.PROJECT_TASK_LIST}?status=${
            data.navigation_id ?? 'in-progress'
          }&list_type=${TaskListTypeConst.Project}`
        : 'Other';

    return (
      <li>
        <NavLink
          style={index === 0 ? { marginTop: 2 } : {}}
          className={({ isActive }) => {
            return isActive ? 'active_tab' : 'non_active';
          }}
          to={link}
        >
          {/* <img className='tab-img' src={Images.Pin} /> */}
          <div className='tab-img space'></div>
          <span className='text'>{name}</span>
        </NavLink>
      </li>
    );
  };

  // return report visibility
  const reportVisible =
    !roleBasedPermission?.ACTION_TIME_LOG &&
    roleBasedPermission?.ACTION_TIME_SHEET_LIST === LIST_PERMISSION_VALUE.NO_ACCESS &&
    !roleBasedPermission?.ACTION_TEAM_REPORT &&
    !roleBasedPermission?.ACTION_GRAPHICAL_REPORT &&
    !roleBasedPermission?.ACTION_TIME_HISTORY;

  return (
    <>
      <div className='hamburger' onClick={toggleSidebar}>
        <img src='/icons/hamburger.svg' style={{ filter: 'var(--dark-mode)' }} />
      </div>
      <div
        className={`sidebar side_bar_contain ${isCollapsed ? 'active' : ''} ${
          isOpen ? 'show' : 'hide'
        }`}
      >
        <div className='menu-header'>
          <div className='company_icon_contain pointer' onClick={() => navigate('/')}>
            {!isSettingLoading ? (
              isCollapsed ? (
                <HiDeskIcon />
              ) : (
                <img src={companyLogo ? companyIcon : Images.CompanyLogo} />
              )
            ) : (
              <LoadingIcon size={40} />
            )}
          </div>
          <div className='header-menu-icon' onClick={() => setIsOpenDropDown(true)}>
            <CircleChevronDown size={25} color='var(--second-text-color)' strokeWidth={1} />

            {isOpenDropDown && (
              <Popover.PopoverItem
                positionLeft={(width) => -width}
                onClose={() => setIsOpenDropDown(false)}
              >
                <div>
                  <CustomSelectModal
                    listData={orgList}
                    menuCssPosition={'static'}
                    setSearch={(value) => setOrganizationSearch(value)}
                    selectedValueVisibility={false} // show the selected value
                    search={organizationSearch}
                    handleChange={(value) => {
                      setOrganizationSearch('');
                      handleCompanyChange(value);
                    }}
                    left={16}
                    formatOptionLabel={(data, metaData) => (
                      <UserDropDown
                        size={19}
                        gap={14}
                        data={data}
                        metaData={metaData}
                        showInitials={true}
                        getNameByKey={() => data?.name}
                      />
                    )}
                  />
                </div>
              </Popover.PopoverItem>
            )}
          </div>
        </div>
        <div>
          <div className='menu'>
            <section className='menu-btn' onClick={() => setIsCollapsed(!isCollapsed)}>
              {isCollapsed ? (
                <section className='d-flex'>
                  <PanelLeftOpen size={15} color='#767676' />
                  <section className='button_side_bar_section'>Expand</section>
                </section>
              ) : (
                <section className='d-flex'>
                  <PanelLeftClose size={15} color='#767676' />
                  <section className='button_side_bar_section'>Collapse</section>
                </section>
              )}
            </section>
            <ul className='menu-list menu-list-primary'>
              {/* dashboard link start */}
              <li>
                {hasExist(MENU.dashboard.default_name) && (
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab ' : 'non-active';
                    }}
                    to='/'
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Dashboard'} />
                    <span className='text'> Dashboard</span>
                  </NavLink>
                )}
              </li>
              {/* dashboard link ends */}
              {/* my task */}
              <li>
                {hasExist(MENU?.myResponsibility?.default_name) && (
                  <NavLink
                    className={() => {
                      const params = new URLSearchParams(location?.search);
                      return params.get('list_type') === 'my-task' ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.TASK_LIST}?status=${self}&list_type=my-task`}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'My-Task'} />
                    <span className='text'>
                      {getLabelName(MENU?.myResponsibility?.default_name)}
                    </span>
                  </NavLink>
                )}
              </li>
              {/* my task end */}
              {/* team task */}
              <li>
                {hasExist(MENU.teamResponsibility.default_name) && (
                  <NavLink
                    className={() => {
                      const params = new URLSearchParams(location.search);
                      return params.get('list_type') === 'team-task' ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.TASK_LIST}?status=${team}&list_type=team-task`}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Task'} />
                    <span className='text'>
                      {getLabelName(MENU.teamResponsibility.default_name)}
                    </span>
                  </NavLink>
                )}
              </li>
              {/* team task end */}
              {/* extra template menu's */}
              {extraMenus && extraMenus.length ? ( // if only one extra menu
                <ExtraMenuItems data={extraMenus} handleNavLinkClick={handleNavLinkClick} />
              ) : null}
              {/* extra template menu's end */}
              {/* my team */}
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? 'active_tab' : 'non-active';
                  }}
                  to={`${RT.MY_TEAM_LIST}`}
                  onClick={handleNavLinkClick}
                >
                  <ReturnIcon name={'Team'} />
                  <span className='text'>My Team</span>
                </NavLink>
              </li>
              {/*end my team */}
              {/* OKR */}
              {hasExist(MENU.okr.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.OKR_LIST}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'OKR'} />
                    <span className='text'>OKR</span>
                  </NavLink>
                </li>
              )}
              {/*end OKR*/}
              {/* Projects */}
              {hasExist(MENU.project.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.PROJECT_LIST}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Project'} />
                    <span className='text'>{LABEL_OF_PROJECT}</span>
                  </NavLink>
                </li>
              )}
              {/*end Projects*/}
              {/* Contact */}
              {hasExist(MENU.contact.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.CLIENT}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={LABEL_OF_CLIENT} />
                    <span className='text'>{LABEL_OF_CLIENT}</span>
                  </NavLink>
                </li>
              )}
              {/*end Contact*/}
              {/* Chats */}
              {hasExist(MENU.chat.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive
                        ? 'active_tab position-relative'
                        : 'non-active position-relative';
                    }}
                    to={`${RT.CHAT}?tab=all`}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Chat'} />
                    <span className='text'>Chat</span>
                    {parseInt(chatCount) > 0 && <div className='chat-count'>{chatCount}</div>}
                  </NavLink>
                </li>
              )}
              {/*end Chats*/}
              {/* Calendar */}
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? 'active_tab' : 'non-active';
                  }}
                  to={`${RT.CALENDAR}?user_id=${user}`}
                  onClick={handleNavLinkClick}
                >
                  <ReturnIcon name={'Calendar'} />
                  <span className='text'>Calendar</span>
                </NavLink>
              </li>
              {/* Calendar end */}
              {/* Filesystem */}
              {hasExist(MENU.files.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.FILESYSTEM}/${RT.FILELIST}`}
                  >
                    <FileIconSidebar />
                    <span className='text'>Files</span>
                  </NavLink>
                </li>
              )}
              {/*end files*/}
              {/* template */}
              {/* {hasExist(MENU.template.default_name) && ( */}
              {(roleBasedPermission?.ACTION_TEMPLATE_ADD || roleBasedPermission?.ACTION_TEMPLATE_EDIT|| roleBasedPermission?.ACTION_TEMPLATE_DELETE) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.TEMPLATE_LIST}
                    onClick={handleNavLinkClick}
                  >
                    <TemplateIcon />
                    <span className='text'>Template</span>
                  </NavLink>
                </li>
              )}
              {/* )} */}
              {/* template end */}

              {/* workflow  */}

              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? 'active_tab' : 'non-active';
                  }}
                  to={RT.WORK_FLOW_LIST}
                  onClick={handleNavLinkClick}
                >
                  <InfinityIcon />
                  <span className='text'>Automation</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? 'active_tab' : 'non-active';
                  }}
                  to={RT.WORK_PROCESS_LIST}
                  onClick={handleNavLinkClick}
                >
                  <InfinityIcon />
                  <span className='text'>Process</span>
                </NavLink>
              </li> */}

              {/* workflow  */}

              {/* report */}
              {hasExist(MENU.reports.default_name) && (
                <li>
                  <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <input
                      checked={isChecked}
                      readOnly
                      type='checkbox'
                      id='todo'
                      name='todo'
                      value='todo'
                      ref={todoCheckboxRef}
                    />
                    {!reportVisible && (
                      <label htmlFor='todo' style={{ width: '100%' }}>
                        <ReportsIcon />
                        <span className='text'>{RT.REPORT}</span>
                        <ChevronDown className='arrow' size={14} />
                      </label>
                    )}
                    <div id='hiddenDiv' className='sub-menu-container'>
                      <div className='hidden_contain overflow-hidden'>
                        {roleBasedPermission?.ACTION_TIME_SHEET_LIST !==
                          LIST_PERMISSION_VALUE.NO_ACCESS && (
                          <li>
                            <NavLink
                              className={({ isActive }) => {
                                return isActive ? 'active_tab' : 'non-active';
                              }}
                              to={`${RT.TIMESHEET}`}
                              onClick={handleNavLinkClick}
                            >
                              <ReturnIcon name={'time-sheet'} />
                              <span className='text'>Time Sheet</span>
                            </NavLink>
                          </li>
                        )}
                        {roleBasedPermission?.ACTION_TIME_LOG && (
                          <li>
                            <NavLink
                              className={({ isActive }) => {
                                return isActive ? 'active_tab' : 'non_active';
                              }}
                              to={`${RT.WORK_ACTIVITY}?user_id=${user}&fromDate=${todayStart}&toDate=${todayEnd}`}
                            >
                              <WorkActivityIcon />
                              <span className='text'>Time Log</span>
                            </NavLink>
                          </li>
                        )}
                        {roleBasedPermission?.ACTION_TIME_HISTORY && (
                          <li>
                            <NavLink
                              className={({ isActive }) => {
                                return isActive ? 'active_tab' : 'non_active';
                              }}
                              to={`${RT.TIME_HISTORY}`}
                            >
                              <TimeHistoryIcon />
                              <span className='text'>Time History</span>
                            </NavLink>
                          </li>
                        )}
                        {roleBasedPermission?.ACTION_TEAM_REPORT && (
                          <li>
                            <NavLink
                              className={({ isActive }) => {
                                return isActive ? 'active_tab' : 'non_active';
                              }}
                              to={`${RT.TEAM_REPORT}?fromDate=${todayStart}&toDate=${todayEnd}`}
                            >
                              <GroupUser height={17} width={19} isSidebar={true} />
                              <span className='text'>Team Report</span>
                            </NavLink>
                          </li>
                        )}
                        {roleBasedPermission?.ACTION_GRAPHICAL_REPORT && (
                          <li>
                            <NavLink
                              className={({ isActive }) => {
                                return isActive ? 'active_tab' : 'non_active';
                              }}
                              to={`${RT.GRAPH_REPORT}/${RT.BAR_CHART}?user_id=${user}&fromDate=${monthStartDate}&toDate=${todayCurrent}`}
                            >
                              <ProgressIcon width={20} height={20} isSidebar={true} />
                              <span className='text'>Graphical Report</span>
                            </NavLink>
                          </li>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {/*end report */}
            </ul>
            <ul className='menu-list menu-list-secondary'>
              {/* favorites */}
              {favorites?.labels && favorites?.labels?.length ? (
                <>
                  <hr className='m-0 my-1' style={{ color: 'var(--modal-bg)' }} />
                  <li>
                    <div
                    // onMouseEnter={handleMouseEnterPin}
                    // onMouseLeave={handleMouseLeavePin}
                    >
                      <input
                        checked={isCheckedPin}
                        type='checkbox'
                        id='pinned'
                        name='pinned'
                        value='pinned'
                        ref={pinnedCheckRef}
                      />
                      <label htmlFor='pinned' style={{ width: '100%' }}>
                        <PinIconSideBar />
                        <span className='text'>{RT.PINNED}</span>
                        {/* <ChevronDown className='arrow' size={14} /> */}
                      </label>
                      <div
                        // id='pinnedDiv'
                        className='sub-menu-container pinned-menu-list'
                      >
                        <div className='hidden_contain'>
                          {favorites?.labels?.map((label, index) => (
                            <>{favoriteItem(label, index)}</>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>
        <section className='menu-end-container'>
          <div className='menu'>
            <ul className='menu-list menu-list-secondary'>
              {(roleBasedPermission?.ACTION_ORG_MANAGE ||
                roleBasedPermission?.ACTION_USER_MANAGE) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non_active';
                    }}
                    to={`${RT.SETTINGS}/${RT.GENERAL}`}
                  >
                    <Settings size={20} />
                    <span className='text'>Settings</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

// extra menu item map
const ExtraMenuItems = ({ data = [], handleNavLinkClick = () => {} }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const ref = useRef();
  const handleMouseEnter = () => {
    setMouseOver(true);
  };
  const handleMouseLeave = () => {
    setMouseOver(false);
  };
  if (data?.length == 1) {
    return (
      <li>
        <NavLink
          className={({ isActive }) => {
            return isActive ? 'active_tab' : 'non-active';
          }}
          to={`${RT.TEMPLATE}/${data?.[0]?.id}?status=${
            data?.[0]?.navigationId ?? 'in-progress'
          }&list_type=${TaskListTypeConst.Template}`}
          onClick={handleNavLinkClick}
        >
          {data?.[0]?.icon ? getTemplateMenu(data?.[0]?.icon) : <ReturnIcon name={'Template'} />}
          <span className='text'>{data?.[0]?.name}</span>
        </NavLink>
      </li>
    );
  }
  return (
    <li>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <input
          checked={mouseOver}
          type='checkbox'
          id='pinned'
          name='pinned'
          value='pinned'
          ref={ref}
        />
        <label htmlFor='pinned' style={{ width: '100%' }}>
          <GalleryHorizontalEnd size={18} />
          <span className='text'>{RT.QUICK_SET}</span>
          <ChevronDown className='arrow' size={14} />
        </label>
        <div id='pinnedDiv' className='sub-menu-container'>
          <div className='hidden_contain overflow-hidden'>
            {data?.map((item, index) => {
              return (
                <li key={index}>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.TEMPLATE}/${item?.id}?status=${
                      item?.navigationId ?? 'in-progress'
                    }&list_type=${TaskListTypeConst.Template}`}
                    onClick={handleNavLinkClick}
                  >
                    {item?.icon ? getTemplateMenu(item?.icon) : <ReturnIcon name={'Template'} />}
                    <span className='text'>{item?.name}</span>
                  </NavLink>
                </li>
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
};

// responsibility progress
export function getLabelProperty(defaultName, property) {
  const { globalSettings } = useContext(GlobalContext);
  // checking if menu exist
  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');
  if (!menuSettings?.labels) return null;
  const item = menuSettings?.labels.find((label) => label?.default_name === defaultName);
  return item ? item[property] || null : null;
}
