import { useQuery, useQueryClient } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SHARED_WITH_ALL_OPTION } from '../File/FileSystemConstant';
import { convertHtmlToRawContent, fileState, getFileType, sanitizeFileName } from '../Utils';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { alertConstants } from '../_constants';
import { FILE_TYPE } from '../_constants/filesystem.constant';
import { fileService, miscService } from '../_services';
import { DigitalFileAdd } from './DigitalFileAdd';
import { FileAddTabs } from './FileAddTabs';

export const FileAdd = ({ handleClose = () => {}, id = null, selectedTab, setSelectedTab }) => {
  const folderId = useParams().folderId;
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState(
    folderId ? { ...fileState, folder_id: parseInt(folderId) } : fileState,
  );
  const [advanceOptionVisible, setAdvanceOptionVisible] = useState(false);
  const [fileUploadVisible, setFileUploadVisible] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const queryClient = useQueryClient();
  const [error, setError] = useState({ message: '' });
  // eslint-disable-next-line no-unused-vars
  const [query] = useState({
    select: [
      'id',
      'name',
      'shared_with_team',
      'shared_with_user',
      'location',
      'files_data',
      'issued_date',
      'expiry_date',
      'file_type',
      'folder_id',
    ],
  });

  const fetchFile = async (query) => {
    const payload = { ...query, id };
    const file = await fileService.fileGet(payload);

    if (file?.data?.length) {
      const result = file?.data[0];

      let issued = moment(parseInt(result?.issued_date)).format('YYYY-MM-DD');
      let expiry = result?.expiry_date
        ? moment(parseInt(result?.expiry_date)).format('YYYY-MM-DD')
        : null;

      let sharedTeam =
        result.shared_with_team?.length > 0 && result.shared_with_team[0] !== 0
          ? result.teams
          : result.shared_with_team?.length > 0
          ? [SHARED_WITH_ALL_OPTION.TEAM]
          : [];
      let sharedUser =
        result.shared_with_user?.length > 0 && result.shared_with_user[0] !== 0
          ? result.members
          : result.shared_with_user?.length > 0
          ? [SHARED_WITH_ALL_OPTION.USER]
          : [];

      let sharedProject =
        result.shared_with_project?.length > 0 && result.shared_with_project[0] !== 0
          ? result.projects
          : result.shared_with_project?.length > 0
          ? [SHARED_WITH_ALL_OPTION.PROJECT]
          : [];
      let description = convertHtmlToRawContent(result?.description);

      setSelectedFiles(result?.files_data ?? []);
      const owner = result?.owner;
      const documentType = result?.document_type;
      const folder = result?.folder ? result?.folder : null;
      const client = result?.client_id ? result?.client : null;

      setFileData({
        ...result,
        description: description,
        issued_date: issued,
        expiry_date: expiry,
        shared_with_team: sharedTeam,
        shared_with_user: sharedUser,
        shared_with_project: sharedProject,
        owner_id: owner,
        document_type_id: documentType,
        folder_id: folder,
        client_id: client,
      });
    }

    return file;
  };

  // fetch file details if edit
  useQuery({
    queryKey: ['file-data', id],
    queryFn: () => fetchFile(query),
    enabled: Boolean(id), // Only run the query if the id is set
    onError: (error) => {
      setError({
        message: error.message || alertConstants.SERVER_ERROR,
      });
    },
  });

  // get the name of file after removing extension
  const getExtractedFileName = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) return fileName; // No dot found, return the original name
    return fileName.substring(0, lastDotIndex);
  };
  // on submitting form , save file

  const handleFileUpload = async (data) => {
    if (!data.length) {
      return [];
    }

    // Only upload new files that haven't been uploaded yet
    // eslint-disable-next-line no-prototype-builtins
    const newData = data.filter((field) => !field.hasOwnProperty('url') && !field.isSuccess);
    // eslint-disable-next-line no-prototype-builtins
    const oldData = data.filter((field) => field.hasOwnProperty('url') || field.isSuccess);

    // Only keep name, extension, url, and size from oldData
    const cleanedOldData = oldData.map(({ name, size, url, extension }) => ({
      name,
      size,
      url,
      extension,
    }));

    let uploadedData = [...cleanedOldData];

    for (let fileObj of newData) {
      try {
        let signedData = await miscService.createSignedUploadUrl({
          type: fileObj.fileType, //--video,image,audio
          ext: fileObj.extension, //--jpg or mp4
          name: fileObj.sanitizeName,
        });

        // Update isUploading to true before starting the upload
        setSelectedFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.sanitizeName === fileObj.sanitizeName ? { ...file, isUploading: true } : file,
          ),
        );

        if (signedData?.data?.signedUrl) {
          let signedUrl = signedData.data.signedUrl;
          let fileName = signedData.data.filename;

          await fetch(signedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: fileObj.file,
          }).then((response) => response.text());

          // Update isSuccess to true after upload
          setSelectedFiles((prevFiles) =>
            prevFiles.map((file) =>
              file.sanitizeName === fileObj.sanitizeName
                ? { ...file, isSuccess: true, isUploading: false, isFailed: false }
                : file,
            ),
          );

          const fileDetails = {
            name: fileObj.name,
            size: fileObj.size,
            url: fileName,
            extension: fileObj.extension,
          };

          uploadedData.push(fileDetails);
        }
      } catch (error) {
        // Update isFailed to true if upload failed
        setSelectedFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.sanitizeName === fileObj.sanitizeName
              ? { ...file, isFailed: true, isUploading: false }
              : file,
          ),
        );
      }
    }
    return uploadedData;
  };

  const validateFileData = (file) => {
    if (
      file?.name.toString().trim() === '' ||
      !file?.issued_date ||
      (file?.expiry_date && file?.expiry_date < file?.issued_date) ||
      !file?.owner_id ||
      !file?.document_type_id ||
      (selectedTab === FILE_TYPE.PHYSICAL && !file?.location)
    ) {
      return null;
    } else {
      return true;
    }
  };

  const handleDocumentTypeSelect = (value) => {
    setFileData((prev) => ({
      ...prev,
      document_type_id: value,
    }));
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (name == 'note') {
      setFileData((prev) => ({
        ...prev,
        description: value,
      }));
      return;
    }
    if (type == 'shared_with') {
      const lastItem = value.length > 0 ? value[value.length - 1] : [];
      const isSelectedAll = lastItem && lastItem.id === 0;

      let filteredValue;
      if (isSelectedAll) {
        // If 'All' is selected last, show only 'All'
        filteredValue = [lastItem];
      } else {
        // Filter out 'All' if other items are selected
        filteredValue = value.filter((item) => item.id !== 0);
      }

      setFileData((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));
      return;
    }

    setFileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);

    let uploadedResult;

    try {
      uploadedResult = await handleFileUpload(selectedFiles);
      if (!uploadedResult) {
        throw new Error('File upload failed');
      }

      if (selectedFiles.length == 0 && selectedTab === FILE_TYPE.DIGITAL) {
        setIsLoading(false);
        return null;
      }
    } catch (error) {
      setIsLoading(false);
      setSubmitted(false);
      return;
    }

    const expiry = fileData?.expiry_date
      ? moment(fileData.expiry_date).endOf('day').valueOf()
      : null;
    const issued = new Date(fileData?.issued_date).getTime();
    const shared_with_user = fileData.shared_with_user?.map((user) => parseInt(user.id));
    const shared_with_team = fileData.shared_with_team?.map((team) => parseInt(team.id));
    const shared_with_project = fileData.shared_with_project?.map((project) =>
      parseInt(project.id),
    );

    const owner = fileData?.owner_id?.id;
    const client = fileData?.client_id?.id ?? null;

    const folder = folderId ? folderId : fileData?.folder_id ? fileData?.folder_id?.id : null;

    const documentType = fileData?.document_type_id?.id ?? null;
    let totalFileSize = uploadedResult?.reduce((accumulator, currentObject) => {
      return accumulator + currentObject?.size;
    }, 0);
    let desc = '';
    if (typeof fileData?.description === 'object') {
      desc = draftToHtml(fileData?.description);
    }

    let tempPayload = {
      ...fileData,
      description: desc,
      issued_date: issued,
      expiry_date: expiry,
      folder_id: folder,
      shared_with_team: shared_with_team,
      shared_with_user: shared_with_user,
      shared_with_project: shared_with_project,
      owner_id: owner,
      document_type_id: documentType,
      file_type: selectedTab,
      client_id: client,
    };

    if (selectedTab === FILE_TYPE.DIGITAL) {
      for (let item of uploadedResult) {
        let extractedName;
        if (id) {
          extractedName = fileData?.name;
        } else {
          extractedName =
            fileData?.name.toString().trim() === ''
              ? getExtractedFileName(item.name)
              : fileData?.name;
        }

        const payload = {
          ...tempPayload,
          name: extractedName,
          size: item.size,
          files_data: [item],
        };
        onSubmit(payload);
      }
    }

    if (selectedTab === FILE_TYPE.PHYSICAL) {
      const payload = { ...tempPayload, size: totalFileSize, files_data: uploadedResult };
      onSubmit(payload);
    }
  };

  const onSubmit = async (payload) => {
    const isValid = validateFileData(payload);
    if (!isValid) {
      setIsLoading(false);
      return null;
    }

    try {
      const res = await fileService.fileAdd(payload);
      queryClient.invalidateQueries(['file-list']);

      if (res.error_code === 0) {
        setSubmitted(false);
        handleClose();
        // makeAlert('Success');
      }
    } catch (error) {
      console.log('Error saving file:', error);
      // You can decide whether to continue saving the rest of the files or break the loop
    } finally {
      setIsLoading(false);
      // setSubmitted(false);
    }
  };

  // file upload section ,
  const onFileSelect = (files) => {
    let filesArray = [];

    for (let file of files) {
      filesArray.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file,
        isUploading: false,
        isFailed: false,
        isSuccess: false,
      });
    }
    selectedFiles?.length > 0
      ? setSelectedFiles([...selectedFiles, ...filesArray])
      : setSelectedFiles([...filesArray]);
  };
  // remove selected file
  const fileRemove = (index) => {
    let tempArray = [...selectedFiles];
    tempArray.splice(index, 1);
    setSelectedFiles(tempArray);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='modal_header'>
        <span className='menu-item-head'>{id ? 'Edit' : 'Add'} File</span>
      </div>
      <div className='modal_view_content' style={{ width: 700 }}>
        <ErrorComponent error={error?.message} />

        <div style={{ padding: '0 10px' }}>
          {!id && (
            <div className='filter-body mt-2'>
              <FileAddTabs
                selectedTab={selectedTab}
                setSelectedTab={(value) => {
                  if (value !== selectedTab) {
                    setFileData(
                      folderId ? { ...fileState, folder_id: parseInt(folderId) } : fileState,
                    );
                    setSelectedFiles([]);
                    setFileUploadVisible(false);
                    setAdvanceOptionVisible(false);
                    setSubmitted(false);
                  }
                  setSelectedTab(value);
                }}
              />
            </div>
          )}
        </div>
        {/* {selectedTab === FILE_TYPE.DIGITAL && ( */}
        <DigitalFileAdd
          handleDocumentTypeSelect={handleDocumentTypeSelect}
          handleChange={handleChange}
          onFileSelect={onFileSelect}
          selectedFiles={selectedFiles}
          fileRemove={fileRemove}
          fileData={fileData}
          setFileData={setFileData}
          submitted={submitted}
          fileType={selectedTab}
          fileId={id}
          advanceOptionVisible={advanceOptionVisible}
          setAdvanceOptionVisible={setAdvanceOptionVisible}
          fileUploadVisible={fileUploadVisible}
          setFileUploadVisible={setFileUploadVisible}
          // folderId={folderId}
        />
        {/* )} */}
        {/* {selectedTab === FILE_TYPE.PHYSICAL && <PhysicalFileAdd />} */}
      </div>
      <div className='modal_footer'>
        <div className='d-flex justify-content-end gap-2'>
          <Button.Container
            className={'outline-cancel-button'}
            type='button'
            handleOk={handleClose}
          >
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container
            handleOk={(e) => handleSubmit(e)}
            type='submit'
            className='blue-btn'
            isDisabled={isLoading}
          >
            <Button.Title title={'Apply'} />
            <Button.Loading />
          </Button.Container>
        </div>
      </div>
    </form>
  );
};
