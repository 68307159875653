//create search component

import { Search } from 'lucide-react';
import React, { useState } from 'react';
export const SearchBar = ({ handleSearch, searchKey }) => {
  const [hasValue, setHasValue] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setHasValue(value !== ''); // Add `has-value` if input is not empty
    handleSearch(e); // Pass the event to the parent handler
  };

  return (
    <div className={`search_contain ${hasValue ? 'has-value' : ''}`}>
      <input
        type='text'
        className='bodr_none'
        onChange={handleInputChange}
        value={searchKey}
        placeholder='Search'
      />
      <Search size={18} color='#87909E' />
    </div>
  );
};
