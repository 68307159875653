/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../_components';
import { settingsService } from '../../_services';
import { GlobalContext } from '../../Utils';
import { Cancel, Tick, WhatsAppIcon } from '../../Utils/SvgIcons';
import ConfigApiForm from './ConfigApiForm';
import { defaultWhatsAppState } from './configitems';

export default function WhatsAppConfigSettings() {
  const { makeAlert, globalSettingsRefetch, globalSettings } = useContext(GlobalContext);

  const configSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'config-settings');
  const isMutating = useIsMutating();

  const [hasChanged, setHasChanged] = useState(false);
  // config state - whatsapp
  const [whatsAppConfig, setWhatsAppConfig] = useState(
    configSettings?.labels?.whatsapp || defaultWhatsAppState,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get('sub');

  // to send the request
  const handleSettingUpdate = async (data) => {
    let newQuery = {};
    newQuery.labels = { ...configSettings?.labels, whatsapp: data };
    newQuery.name = 'config-settings';
    await settingsService.settingsAdd(newQuery);
    makeAlert('Success');
    setHasChanged(false);

    globalSettingsRefetch();
    return newQuery;
  };

  // --react query for mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: handleSettingUpdate,
  });

  // change tab handler
  const changeTab = (name) => {
    searchParams.set('sub', name);
    setSearchParams(searchParams, { replace: true });
  };

  // on input change handler
  const onChange = (e) => {
    const { name, value } = e.target;
    setWhatsAppConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // handle for add header -- add additional header properties
  const handleAddHeader = () => {
    const headerCopy = whatsAppConfig.headers;
    headerCopy.push({ key: '', value: '' });
    setWhatsAppConfig((prev) => ({
      ...prev,
      headers: headerCopy,
    }));
  };
  // delete the existing header if no need by index
  const handleDeleteHeader = (index) => {
    const headerCopy = whatsAppConfig.headers;
    headerCopy.splice(index, 1);
    setWhatsAppConfig((prev) => ({
      ...prev,
      headers: headerCopy,
    }));
  };
  // On change handler for header values - key and value
  const handleHeaderChange = (index, key, value) => {
    setWhatsAppConfig((prev) => {
      const updatedHeaders = prev.headers.map((header, i) => {
        if (i === index) {
          return { ...header, [key]: value }; // Update the specific header object
        }
        return header; // Return the unchanged header
      });
      return {
        ...prev,
        headers: updatedHeaders,
      };
    });
  };
  const handleDelete = () => {
    setWhatsAppConfig(defaultWhatsAppState);
    handleSettingUpdate(defaultWhatsAppState);
  };
  return (
    <div className='config_layout'>
      <div className='config_heading_container'>
        <section className='icon_container'>
          <WhatsAppIcon />
        </section>
        <span className='general_head'>WhatsApp</span>
      </div>
      <ConfigApiForm
        config={whatsAppConfig}
        handleAddHeader={handleAddHeader}
        handleDeleteHeader={handleDeleteHeader}
        handleHeaderChange={handleHeaderChange}
        onChange={onChange}
        tabName={tabName}
        changeTab={changeTab}
      />
      <section className='mt-2 d-flex justify-content-end gap-1'>
        <Button.Container
          handleOk={() => {
            handleDelete();
          }}
          className={'cancel-btn'}
        >
          <Cancel />
          <Button.Title title={'Delete'} />
        </Button.Container>
        <Button.Container isDisabled={hasChanged} handleOk={() => handleMutate(whatsAppConfig)}>
          <Tick />
          <Button.Title title={'Update'} />
          <Button.Loading isLoading={isMutating} />
        </Button.Container>
      </section>
    </div>
  );
}
