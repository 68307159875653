/* eslint-disable no-unused-vars */
import { CableCar, EarthLock, GitFork, Plus } from 'lucide-react';
import React from 'react';
import { Handle } from 'reactflow';
import { Modal } from '../_components';
import { NodeAddComponent } from './NodeAddComponent';

export function ConditionNode(props) {
  const { data, ...rest } = props;

  const [addModal, setAddModal] = React.useState(false);

  const addIconNeed = data?.addIconNeed;

  let nodeData = data?.operation_details;

  const getConditionIcon = (id) => {
    if (id === 'project_id') {
      return (
        <EarthLock
          style={{ background: 'black', padding: '1px 3px 1px 3px', borderRadius: 4 }}
          size={16}
          color='white'
        />
      );
    }
  };

  console.log('CONSITIONS-->', nodeData);

  const conditionView = (
    <>
      {nodeData &&
        nodeData?.map((item, index) => {
          const isLastElement = index === nodeData.length - 1;
          return (
            <div key={index} style={{ padding: '4px 0px 0px 0px' }}>
              <div>
                {item?.condition?.label} <span> is </span>
                {item?.condition_value.label}
              </div>

              {!isLastElement && (
                <>
                  <div
                    style={{
                      height: 0.4,
                      background: '#d2d2d2',
                      marginTop: 10,
                      marginBottom: 2,
                    }}
                  ></div>

                  <div style={{ fontSize: 13, fontWeight: '600' }}>And</div>
                </>
              )}
            </div>
          );
        })}
    </>
  );

  return (
    <>
      <div className='nd-container'>
        <div className='nd-header'>
          <CableCar className='nd-header-icon' size={14} />
          <span className='nd-header-text'>Condition</span>
        </div>

        <div>
          <div className='nd-content'>
            <GitFork className='nd-content-icon' size={16} />
            <span className='nd-content-text'>
              Check <strong>IF</strong>
            </span>
          </div>
          <div className='nd-divider'></div>
          <div className='nd-description'>
            {conditionView}
            {/* <span className='nd-description-bold'>{nodeData?.condition?.label}</span> is{' '}
            <span className='nd-description-bold'>{nodeData?.condition_value?.label}</span> */}
          </div>
        </div>

        <Handle type='target' position='top' id='condition-input' className='nd-handle' />
        <Handle type='source' position='bottom' id='condition-output' className='nd-handle' />

        {addIconNeed && (
          <div onClick={() => setAddModal(true)} className='nd-add-icon'>
            <Plus size={13} style={{ color: 'white' }} />
          </div>
        )}
      </div>

      {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <NodeAddComponent
              rest={rest}
              data={data}
              addModal={addModal}
              setAddModal={setAddModal}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
