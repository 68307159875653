import { Check, Plus, Trash } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { apiConstants } from '../../_constants';
import useOutsideClickHandler from '../../Hook/useOutsideClickHandler';
import { fullNameUser } from '../../Utils';
import { reactSelectUtils } from '../ReactSelectCustomizations/reactSelectUtils';
import SearchableQuerySelect from '../SearchableQuerySelect';
import ToolTip from '../Tooltip/ToolTip';
import UserDropDown from '../UserDropDown';
import CircularAvatar from './CircularAvatar';

/***
 * Extent the stacked avatar to an addable one.
 */
const AddableUserDropDown = ({
  onAdd,
  size,
  options = [],
  selectedList,
  getValueByKey,
  selectProps,
  dropdownSize,
  addableLimit,
  renderCustomOptionLabel,
  getNameByKey,
  showInitials,
  openState,
  rootContainerRef,
  isMulti = true,
  tooltipPortal,
  // zIndex = 1,
  // setShrinkSize,
}) => {
  const isChangeable = addableLimit === 1;

  const [isOpen, setIsOpen] = openState;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [startAdd, setStartAdd] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isLimitReached = addableLimit
    ? addableLimit <= selectedList.length + selectedUsers.length
    : false;

  const { containerRef } = useOutsideClickHandler({
    onClose: () => {
      if (selectedUsers.length <= 0) {
        containerRef.current.classList.add('block-closed');
        containerRef.current.classList.remove('block-opened');
        // toggleAvatars(size);
      }
    },
    dependencies: [selectedUsers],
  });

  const handleBeforeUnload = (e) => {
    if (selectedUsers.length > 0) {
      const message = 'You have unsaved changes. Are you sure you want to leave?';
      e.preventDefault();
      e.returnValue = message; // For most browsers
      return message; // For some older browsers
    }
  };

  useEffect(() => {
    const element = document.querySelector('.select-addable-value-container');
    if (element) {
      // element.scrollLeft = element.scrollWidth;
      window.requestAnimationFrame(() => {
        element.scrollTo({
          left: element.scrollWidth,
          behavior: 'smooth',
        });
      });
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selectedUsers.length]);

  useEffect(() => {
    (isOpen || selectedUsers.length <= 0) && toggleAvatars(!isOpen ? size : null);
  }, [isOpen, selectedUsers.length > 0]);

  const filterItem = (item) => {
    if (getValueByKey) {
      return selectedList.length > 0
        ? selectedList.every((itm) => getValueByKey(itm) != getValueByKey(item))
        : true;
    }

    return selectedList.some((itm) => itm != item);
  };

  const validList = options.filter(filterItem);

  if (isLimitReached && !isOpen) {
    return null;
  }

  const selectSize = dropdownSize && isOpen ? dropdownSize : size;
  /***
   * Will submit the data and reset all the start to default values
   */
  const handleSubmit = (event) => {
    if (event.target.classList.contains('block-closed') && isOpen) {
      setIsOpen(false);
      setStartAdd(false);
      onAdd(selectedUsers);
      setSelectedUsers([]);
      // Adjusted time-frame for animating the pop in
      setTimeout(() => {
        document
          .querySelectorAll('.single-avatar-container')
          .forEach((item) => item.classList.add('scale-up'));
      }, 0);
    }
  };
  /***
   * Alter the class name to make the dropdown close
   */
  const handleDropdownClose = (event) => {
    event.stopPropagation();

    if (selectedUsers.length > 0) {
      containerRef.current.classList.add('block-closed');
      containerRef.current.classList.remove('block-opened');
      // toggleAvatars(size);
    }
  };

  // const expandAvatars = () => {
  //   const stackedRootElement = rootContainerRef.current;
  //   const avatars = stackedRootElement.querySelectorAll('.smak-single-avatar-preview');
  //   avatars.forEach((el, index) => {
  //     if (index !== 0) {
  //       el.style.translate = '0px';
  //       el.style.transition = 'translate 200ms ease-in';
  //     }
  //   });
  //   const addableSelectRoot = stackedRootElement.querySelector('.smak-avatar-react-select');
  //   addableSelectRoot.style.minWidth = '0px';
  //   // addableSelectRoot.style.transition = 'translate 200ms ease-in';
  //   addableSelectRoot.style.translate = `0px`;
  // };

  const toggleAvatars = (customSize = null) => {
    const stackedRootElement = rootContainerRef.current;
    const parentElement = stackedRootElement?.parentNode;

    if (stackedRootElement && parentElement) {
      const parentWidth = parentElement.clientWidth;
      const addableSelectRoot = stackedRootElement.querySelector('.smak-avatar-react-select');
      // const addableRootWidth = addableSelectRoot.clientWidth;

      // if (addableRootWidth <= 300) {
      const avatars = stackedRootElement.querySelectorAll('.smak-single-avatar-preview');
      const availableSpaceForAvatars = customSize
        ? parentWidth - customSize - 10
        : Math.max(parentWidth / 4, parentWidth - 300);
      const availableSpaceForSingleAvatar = availableSpaceForAvatars / avatars.length;

      // const availableSize = parentWidth - size;
      // addableSelectRoot.style.width = `${availableSize}px`;
      // if (avatars.length * size + 150 > parentWidth) {
      avatars.forEach((el, index) => {
        if (index !== 0) {
          el.style.transition = 'translate 200ms ease-in';
          el.style.translate = `-${
            index * (size - Math.min(availableSpaceForSingleAvatar, size))
          }px`;
        }
      });

      addableSelectRoot.style.translate = `-${
        avatars.length * (size - Math.min(availableSpaceForSingleAvatar, size))
      }px`;
      addableSelectRoot.style.transitionProperty = 'translate,width,min-width';
      addableSelectRoot.style.transitionDuration = '200ms,250ms,250ms';
      addableSelectRoot.style.minWidth = `${
        customSize ? customSize : parentWidth - availableSpaceForAvatars
      }px`;

      // }
      // }
    }
  };

  const handleDropdownOpen = () => {
    // toggleAvatars();
    if (!isOpen) {
      setIsOpen(true);
      containerRef.current.classList.remove('block-closed');
      containerRef.current.classList.add('block-opened');
    }
  };

  const handleChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
    if (isChangeable) {
      containerRef.current.classList.add('block-closed');
      containerRef.current.classList.remove('block-opened');
    }
  };

  return (
    <div
      className={`circular-block smak-avatar-react-select position-relative`}
      ref={containerRef}
      onTransitionEnd={handleSubmit}
      style={{
        width: selectSize,
        height: selectSize,
        borderRadius: selectSize / 2,
        marginLeft: -selectSize / 10,
        // zIndex: zIndex,
        // top: dropdownSize && isOpen ? -((dropdownSize - size) / 2) : 'auto',
      }}
      onClick={handleDropdownOpen}
    >
      {!isOpen && <Plus size={selectSize / 2} color='rgba(135, 144, 158, 1)' />}
      {/* Absolute dropdown  */}
      <div
        className='w-100 h-100 d-flex fz-13px'
        style={{ position: 'absolute', display: isOpen ? 'block' : 'none' }}
      >
        {isOpen && (
          <>
            <div className='h-100   right-shadow' style={{ width: 'calc(100% - 50px)' }}>
              <SearchableQuerySelect
                className='w-100 z-10 h-100'
                initialValue={isLimitReached ? [] : validList}
                isClearable={false}
                onBlur={() => setIsMenuOpen(false)}
                onChange={(selectedUsers) => {
                  handleChange(selectedUsers);
                }}
                onFocus={() => setIsMenuOpen(true)}
                value={selectedUsers}
                isMulti={isMulti}
                menuIsOpen={isMenuOpen}
                getFilteredSearchValues={filterItem}
                components={{ MultiValueRemove: RemoveComponent }}
                getOptionLabel={(user) => user.first_name}
                isOptionDisabled={() => isLimitReached}
                noOptionsMessage={() =>
                  isLimitReached ? `Max limit of ${addableLimit} is reached` : undefined
                }
                formatOptionLabel={(data, metaData) => (
                  <InputSelect
                    getNameByKey={getNameByKey}
                    size={selectSize - 10}
                    setIsMenuOpen={setIsMenuOpen}
                    key={data.id}
                    data={data}
                    isChangeable={isChangeable}
                    metaData={metaData}
                    startAdd={startAdd}
                    renderCustomOptionLabel={renderCustomOptionLabel}
                    showInitials={showInitials}
                    scrollRef={selectProps?.scrollRef ?? null}
                    tooltipPortal={tooltipPortal}
                  />
                )}
                getOptionValue={(user) => user.id}
                classNames={reactSelectUtils.getAddableAvatarClassNames()}
                styles={reactSelectUtils.getAddableAvatarStyles(selectedUsers)}
                {...selectProps}
              />
            </div>

            {!isChangeable && (
              <div
                style={{ height: 'fit-content', padding: '4px 14px' }}
                aria-disabled={selectedUsers.length <= 0}
                className={`m-auto check-container ${
                  selectedUsers?.length > 0 ? 'check-container-button-effect' : ''
                }`}
                onClick={handleDropdownClose}
              >
                <Check
                  className='check-lucid'
                  size={22}
                  strokeWidth={`${selectedUsers?.length > 0 ? 3 : 2}`}
                  color={`${selectedUsers?.length > 0 ? 'green' : '#cfcfcf'}`}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const InputSelect = ({
  data,
  metaData,
  size = 40,
  startAdd,
  onAnimationEnd,
  renderCustomOptionLabel,
  getNameByKey,
  showInitials,
  scrollRef = null,
  tooltipPortal
  // showInitials,
}) => {
  const customOption = renderCustomOptionLabel(data, metaData);

  if (customOption) {
    return customOption;
  }

  // if (data.id <= 0 && metaData.context === 'value') {
  //   return <SelectComponents.AllUsersFormatLabel />;
  // }

  if (metaData.context === 'menu') {
    return (
      <UserDropDown
        showInitials={showInitials}
        data={data}
        metaData={metaData}
        getNameByKey={getNameByKey}
      />
    );
  }
  const imageUrl = data.compressed_image_url
    ? apiConstants.imgUrlS3 + data.compressed_image_url
    : data.image_url
    ? apiConstants.imgUrlS3 + data.image_url
    : null;

  const name = getNameByKey ? getNameByKey(data) : fullNameUser(data.first_name, data.last_name);
  return (
    <div
      className={`selected-avatar-container ${startAdd ? 'squeeze-and-slide' : ''}`}
      onAnimationEnd={onAnimationEnd}
    >
      <ToolTip popOverPortal={tooltipPortal} isModern tooltipText={data.first_name} popOver scrollRef={scrollRef}>
        <CircularAvatar
          size={size}
          isCentered
          isImgStacked
          source={imageUrl}
          name={showInitials ? name : null}
          isMale={parseInt(data.gender) === 1}
        />
      </ToolTip>
    </div>
  );
};

const RemoveComponent = (props) => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        props.innerProps.onClick();
      }}
      className='avatar-remove-input'
    >
      <Trash size={12} />
    </div>
  );
};

const ExceedAvatarWithName = ({
  exceedCount,
  exceededMembers = [],
  size,
  renderExceedList,
  activeIds,
  setActiveIds,
  isClickable,
  hideTooltip,
  scrollRef = null,
  getNameByKey,
  nonExceededUserList = [],
  searchParams,
}) => {
  const [isUserSelected, setIsUserSelected] = useState(false);

  const userParams = searchParams?.getAll('user_id');
  useEffect(() => {
    if (userParams?.length === 0) {
      setIsUserSelected(false);
    }
  }, [userParams]);

  const isExceedSelected =
    isClickable && exceededMembers.some((item) => activeIds.includes(item.id));

  // const isTop = POSITION.TOP === tooltipPosition;
  const [isActive, setIsActive] = useState(false);

  const { containerRef } = useOutsideClickHandler({
    onClose: () => {
      setIsActive(false);
    },
  });

  // For removing or adding userId to the selected list.
  function toggleSelect(user) {
    const isSelected = activeIds.includes(user.id);
    if (!isSelected) {
      setActiveIds((prev) => [...prev, user.id]);
      return;
    }
    setActiveIds((prev) => prev.filter((item) => item != user.id));
  }

  return (
    <div className='position-relative' ref={containerRef}>
      <ToolTip
        hideTooltip={hideTooltip || Boolean(renderExceedList)}
        scrollRef={scrollRef}
        popOver
        tooltipText={exceededMembers.map((member) => {
          const name = getNameByKey(member);
          return (
            <div key={member.id} className='member-name' style={{ overflow: 'hidden' }}>
              {name}
            </div>
          );
        })}
        isModern
      >
        <div
          onClick={() => isClickable && setIsActive((prev) => !prev)}
          className={`member-avatar ${isExceedSelected || isUserSelected ? 'avatar-active' : ''}`}
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
            marginLeft: -size / 10,
            background: '#f1f2f4',
            color: 'black',
            fontSize: 10,
            display: 'grid',
            placeItems: 'center',
          }}
        >
          +{exceedCount}
        </div>

        {/* <div
        className={`member-name-container l-n20px ${
          isTop ? 'avatar-tooltip-top' : 'avatar-tooltip-c-bottom'
        }`}
        style={renderExceedList ? { gridTemplateColumns: '0fr', padding: 0 } : {}}
      > */}
      </ToolTip>
      {/* </div> */}
      {renderExceedList &&
        isActive &&
        renderExceedList({
          exceedCount,
          exceededMembers,
          selectedIds: activeIds,
          updateSelection: setActiveIds,
          nonExceededUserList: nonExceededUserList,
          setIsUserSelected: setIsUserSelected,
          toggleSelect,
        })}
    </div>
  );
};

const SingleAvatarWithName = ({
  user,
  name,
  index,
  source = null,
  popOverPortal = false,
  isMale,
  size,
  showInitials,
  isClickable,
  onAvatarClick,
  popOver,
  onAvatarDeselect,
  activeIds,
  setActiveIds,
  scrollRefForTooltip,
  onAvatarRemove,
  isClearable,
  hideTooltip,
}) => {
  const finalUrl = source ? apiConstants.imgUrlS3 + source : null;
  const isNeutral = user.gender === 3;
  const isActive = activeIds.includes(user.id);

  // const isTop = tooltipPosition === POSITION.TOP;

  const handleAvatarClick = (user) => {
    if (!isActive) {
      setActiveIds((prev) => [...prev, user.id]);
      onAvatarClick(user, [...activeIds, user.id]);
      return;
    }

    setActiveIds((prev) => prev.filter((item) => item != user.id));
    onAvatarDeselect(
      user,
      activeIds.filter((item) => item != user.id),
    );
  };

  return (
    <div
      className={`stacked-relative  smak-single-avatar-preview single-avatar-container ${
        isClearable() ? 'clearable-avatar' : ''
      }`}
      onClick={() => {
        isClickable && handleAvatarClick(user);
      }}
    >
      <ToolTip
        tooltipText={name}
        isModern
        popOverPortal={popOverPortal}
        popOver={popOver}
        scrollRef={scrollRefForTooltip}
        hideTooltip={hideTooltip}
      >
        {isClearable && (
          <div
            role='button'
            onClick={() => onAvatarRemove()}
            className='avatar-delete justify-content-center align-items-center'
          >
            <Trash size={15} color='#FFF' />
          </div>
        )}
        <CircularAvatar
          className={`member-avatar ${isActive ? `avatar-active z-${index + 1}` : ''}`}
          isImgStacked
          size={size}
          source={finalUrl}
          isMale={isMale}
          isNeutral={isNeutral}
          name={showInitials ? name : null}
        />
      </ToolTip>
    </div>
  );
};

export const StackedComponents = {
  AddableUserDropDown,
  ExceedAvatarWithName,
  SingleAvatarWithName,
};
