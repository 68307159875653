import { keepPreviousData, useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { FileIcon } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactSelect from 'react-select';
import { EditableDescription } from '../Task/TaskComponents';
import { CustomVariableInput } from '../Task/TaskComponents/CustomInputs';
import { removeTagsAndGetContent } from '../Task/taskValidation';
import { TEAM_LIST_BASED_ON_USER } from '../Team/team.constants';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  convertHtmlToRawContent,
  GlobalContext,
  OkrChildState,
  percentageOfManualOkr,
  userInitialLetter,
} from '../Utils';
import { Cancel, ChildTreeDownIcon, Tick } from '../Utils/SvgIcons';
import { Button } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import UserDropDown from '../_components/UserDropDown';
import {
  alertConstants,
  COMMON_USER_SELECT,
  CommonDropDownStyle,
  typeConstants,
  USER_LIST_ACTIONS,
} from '../_constants';
import { okrService, userService } from '../_services';
import { teamService } from '../_services/team.service';
import {
  childTypeOptions,
  childTypeOptionsForTeam,
  manualProgressType,
  objectiveType,
  okrFormAction,
  okrValidationConstant,
  progressType,
  timeIntervalOptions,
} from './okrConstants';
import { okrChildSchema } from './okrValidation';

export default function OkrChildAdd({
  parentData = {},
  id,
  isChildObjective,
  handleClose = () => {},
}) {
  const { makeAlert } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({ message: '' });
  const [okrData, setOkrData] = useState(
    isChildObjective
      ? {
          ...OkrChildState,
          objective_type:
            parseInt(parentData?.objective_type) === parseInt(objectiveType.company)
              ? objectiveType.team
              : objectiveType.personal,
        }
      : { ...OkrChildState },
  );
  const [query, setQuery] = useState({
    select: [
      'id',
      'assignee_id',
      'name',
      'note',
      'interval',
      'objective_type',
      'task_start_date',
      'task_end_date',
      'visibility_type',
      'visibility_team',
      'okr_progress_type',
      'manual_progress_type',
      'progress_measure_start',
      'progress_measure_end',
      'okr_progress',
      'status',
      'type',
      'children',
      'manual_current_progress',
    ],
  });
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });

  const measurementOptions = [
    { label: 'Manually', value: 1 },
    { label: 'Based on child status', value: 2 },
  ];

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getOKR(query);
    }
  }, [id]);

  // Fetch user list based on objective type
  const { data: userData, isLoading: assigneeListLoading } = useQuery({
    queryKey: [
      'okr-assignee-list',
      okrData?.objective_type === objectiveType.team && okrData?.visibility_team.length !== 0
        ? okrData?.visibility_team
        : null,
    ],
    queryFn: () =>
      userService.getRoleBasedUserList({
        searchKey: '',
        action: USER_LIST_ACTIONS.ACTION_OKR_ALLOCATION,
        select: COMMON_USER_SELECT,
        ...(parentData?.visibility_team?.length > 0 && {
          team_Ids: parentData?.visibility_team,
          // action: actionConstants.OKRADD,
        }),
        ...(okrData?.objective_type === objectiveType.team &&
          okrData?.visibility_team.length !== 0 && {
            team_Ids: [okrData?.visibility_team[0]?.id],
            // action: actionConstants.OKRADD,
          }),
      }),
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  // team list
  const { data: teamData, isLoading: isTeamLoading } = useQuery({
    queryKey: ['okr-team-list'],
    queryFn: () =>
      teamService.teamList({
        select: ['id', 'name'],
        action: TEAM_LIST_BASED_ON_USER.OKR_ADD,
        ...(parentData?.visibility_team?.length > 0 && {
          id: parentData?.visibility_team,
        }),
      }),
    select: (data) => data.data.rows,
  });

  const getOKR = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    setQuery(queryNew);
    setIsLoading(true);
    try {
      const okr = await okrService.getOKR(queryNew);

      if (okr?.data) {
        setOkrData({
          ...okr?.data,
          note: convertHtmlToRawContent(okr?.data.note),
          assignee_id: okr?.data?.User,
          visibility_team: okr?.data?.teams ? okr?.data?.teams?.map((team) => team) : [],
          task_end_date: parseInt(okr?.data?.task_end_date),
          task_start_date: parseInt(okr?.data?.task_start_date),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (name == 'objective_type') {
      setOkrData({
        ...okrData,
        visibility_team: [],
        assignee_id: '',
        [name]: value,
      });
      return;
    }

    if (type === 'team_based') {
      setOkrData((prev) => ({
        ...prev,
        assignee_id: '',
        [name]: value,
      }));
      return;
    }

    if (name == 'interval' && value != 0) {
      if (value != 3) {
        setOkrData((prev) => ({
          ...prev,
          [name]: value,
          task_end_date: moment(okrData.task_start_date)
            .add(value * 3, 'months')
            .endOf('day')
            .valueOf(),
        }));
        return;
      }
      setOkrData((prev) => ({
        ...prev,
        [name]: value,
        task_end_date: moment(okrData.task_start_date).add(1, 'year').endOf('day').valueOf(),
      }));
      return;
    }
    if (name == 'interval' && value == 0 && !id) {
      setOkrData((prev) => ({
        ...prev,
        [name]: value,
        task_start_date: moment().valueOf(), // Current date in 'YYYY-MM-DD' format
        task_end_date: moment().endOf('day').valueOf(), // End of today in timestamp format
      }));
      return;
    }

    if (type === 'date') {
      if (name == 'task_start_date' && okrData.interval != 0) {
        if (okrData.interval != 3) {
          setOkrData((prev) => ({
            ...prev,
            [name]: moment(value).valueOf(),
            task_end_date: moment(value)
              .add(okrData.interval * 3, 'months')
              .endOf('day')
              .valueOf(),
          }));
          return;
        }
        setOkrData((prev) => ({
          ...prev,
          [name]: moment(value).valueOf(),
          task_end_date: moment(value).add(1, 'year').endOf('day').valueOf(),
        }));

        return;
      }
      setOkrData({
        ...okrData,
        [name]: moment(value).endOf('day').valueOf(), // Adds end of the day
      });
      return;
    }
    if (name == 'manual_progress_type') {
      if (parseInt(value) === manualProgressType.binary) {
        setOkrData({
          ...okrData,
          [name]: parseInt(value),
          progress_measure_start: 0,
          progress_measure_end: 100,
          manual_current_progress: 0,
        });
        return;
      }
      setOkrData({
        ...okrData,
        [name]: parseInt(value),
        progress_measure_start: 0,
        progress_measure_end: 0,
        manual_current_progress: 0,
      });
      return;
    }
    if (name == 'okr_progress_type') {
      setOkrData({
        ...okrData,
        [name]: value,
        manual_progress_type:
          parseInt(value) === progressType.manually ? manualProgressType.percentage : 0,
        progress_measure_start: 0,
        progress_measure_end: 0,
        okr_progress: 0,
        manual_current_progress: 0,
      });
      return;
    }
    setOkrData({
      ...okrData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);
    let currentProgress = okrData?.manual_current_progress;
    let finalizedProgress = okrData?.okr_progress;
    if (id) {
      if (parseInt(okrData?.okr_progress_type) === progressType.basedOnChild) {
        finalizedProgress = okrData?.okr_progress;
      } else if (parseInt(okrData?.okr_progress_type) === progressType.manually) {
        if (parseInt(okrData?.manual_progress_type) === manualProgressType.binary) {
          finalizedProgress = okrData?.manual_current_progress;
        } else {
          finalizedProgress = percentageOfManualOkr(
            okrData?.progress_measure_start,
            okrData?.progress_measure_end,
            currentProgress,
          );
        }
      }
    } else {
      if (parseInt(okrData?.okr_progress_type) === progressType.basedOnChild) {
        finalizedProgress = 100;
      } else if (parseInt(okrData?.okr_progress_type) === progressType.manually) {
        finalizedProgress = 0;
        currentProgress = okrData?.progress_measure_start;
      }
    }

    const payload = {
      ...okrData,
      note: draftToHtml(okrData.note),
      type: okrData?.type
        ? okrData?.type
        : isChildObjective
        ? typeConstants.OBJECTIVE
        : typeConstants.KEY_RESULT,
      okr_parent_id: parentData?.id,
      assignee_id: okrData?.assignee_id?.id,
      okr_progress: finalizedProgress,
      manual_current_progress: currentProgress,
      visibility_team: okrData?.visibility_team?.map((team) => team?.id),
      // visibility_team: okrData.visibility_team ? [parseInt(okrData.visibility_team?.id)] : [],
    };

    // console.log([payload);

    const validationResult = okrChildSchema.safeParse(payload);
    if (!validationResult.success) {
      makeAlert('Incorrect data');
      setIsLoading(false);
      return;
    }

    try {
      await okrService.OKRAdd(payload).then(() => {
        setSubmitted(false);
      });
      handleClose(okrFormAction.Save);
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else if (scrollHeight - scrollTop <= clientHeight + 5) {
      setScrollFlag({ isBottomReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  const typeOption =
    parseInt(parentData?.objective_type) === objectiveType.company
      ? childTypeOptions
      : parseInt(parentData?.objective_type) === objectiveType.team
      ? childTypeOptionsForTeam
      : [];

  return (
    <div>
      <div
        className='objective-add-head '
        style={{
          boxShadow: scrollFlag.isTopReached ? '' : '0 6px 5px -5px #091e4229',
        }}
      >
        <h6 className='okr-popup-head'>
          {id ? 'Edit' : 'Create'} {isChildObjective ? 'Child Objective' : 'Key Result'}
        </h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          {
            <div className='d-flex parent-info-head' style={{ margin: '10px 20px' }}>
              <div>
                <ChildTreeDownIcon />
              </div>
              <div>
                <div className='fz-10px-rem'>Parent Objective</div>
                <div className=' fz-14 d-flex' style={{ fontWeight: '500' }}>
                  <span>{parentData?.name}</span>
                </div>
              </div>
            </div>
          }
          <div className='okr-parent-separation ' style={{ margin: '20px 20px 0' }} /> {/* <div> */}
          <div className='okr-modal-content fz-13px-rem ' onScroll={handleScroll}>
            <div className='d-flex row' style={{ flexWrap: 'wrap' }}>
              {isChildObjective && (
                <>
                  <div className='col-md-6'>
                    <label className='form-input-label'>Type</label>

                    <ReactSelect
                      styles={CommonDropDownStyle}
                      components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                      placeholder='Select'
                      options={typeOption}
                      getOptionValue={(option) => {
                        return option?.value;
                      }}
                      isDisabled={
                        (id && okrData?.children) ||
                        parseInt(parentData?.objective_type) === objectiveType.team
                      }
                      getOptionLabel={(option) => {
                        return option?.label;
                      }}
                      formatOptionLabel={(option) => {
                        return (
                          <div className='d-flex'>
                            <div className='Letter_bg me-2'>{userInitialLetter(option?.label)}</div>
                            <span>{option?.label}</span>
                          </div>
                        );
                      }}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'objective_type',
                            value: selectedOption.value,
                          },
                        });
                      }}
                      value={typeOption?.find(
                        (option) => option.value == parseInt(okrData?.objective_type),
                      )}
                    />
                  </div>

                  {okrData?.objective_type == objectiveType.team && (
                    <div className='col-md-6 mb-2  pl-0'>
                      <label className='form-input-label'> Team</label>
                      <SearchableQuerySelect
                        queryKey={'okr-team-list'}
                        queryFn={teamService.teamList}
                        getFunctionParams={(searchKey) => ({
                          searchKey,
                          select: ['id', 'name'],
                          action: TEAM_LIST_BASED_ON_USER.OKR_ADD,
                        })}
                        isCustomFunction
                        isLoading={isTeamLoading}
                        initialValue={teamData}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option.id}
                        value={okrData?.visibility_team}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'visibility_team',
                              value: [selectedOption],
                              type: 'team_based',
                            },
                          });
                        }}
                        styles={CommonDropDownStyle}
                        components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                        placeholder='Select Team '
                        className={` select-box 
                          ${
                            submitted &&
                            okrData?.objective_type === objectiveType.team &&
                            okrData?.visibility_team?.length === 0 &&
                            'field-error'
                          }
                          }
                         `}
                      />
                      <ErrorBlock
                        errorMsg={'Team selection Required'}
                        hasError={
                          submitted &&
                          okrData?.objective_type === objectiveType.team &&
                          okrData?.visibility_team?.length === 0 && (
                            <div className='help-block'>Team is required</div>
                          )
                        }
                      />
                    </div>
                  )}
                </>
              )}
              <div className='col-md-6'>
                <label className='form-input-label'>Assignee</label>
                <SearchableQuerySelect
                  queryKey={'okr-assignee-list'}
                  queryFn={userService.getRoleBasedUserList}
                  getFunctionParams={(searchKey) => ({
                    searchKey,
                    action: USER_LIST_ACTIONS.ACTION_OKR_ALLOCATION,
                    select: COMMON_USER_SELECT,
                    ...(okrData?.objective_type === objectiveType.team &&
                      okrData?.visibility_team.length !== 0 && {
                        team_Ids: [okrData?.visibility_team?.[0].id],
                        // action: actionConstants.OKRADD,
                      }),
                  })}
                  isCustomFunction
                  isLoading={assigneeListLoading}
                  initialValue={userData}
                  formatOptionLabel={(data, metaData) => (
                    <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
                  )}
                  getOptionValue={(option) => option.id}
                  value={okrData?.assignee_id}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: {
                        name: 'assignee_id',
                        value: selectedOption,
                      },
                    });
                  }}
                  styles={CommonDropDownStyle}
                  components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                  //   isDisabled={templateSelectionDisabled}
                  placeholder='Select Assignee '
                  className={` select-box 
                ${submitted && !okrData?.assignee_id && 'field-error'}
               `}
                />
                <ErrorBlock
                  errorMsg={'Assignee selection Required'}
                  hasError={submitted && !okrData?.assignee_id}
                />
              </div>
            </div>

            <div className='mt-2'>
              <CustomVariableInput
                name='name'
                value={okrData?.name}
                placeholder={`Enter ${isChildObjective ? 'child objective' : 'key result'} name`}
                onChange={(event) => handleChange(event)}
                className={`okr-name-input w-100  border-none `}
              />
              <ErrorBlock
                errorMsg={'Objective name required'}
                hasError={submitted && okrData?.name?.trim() === ''}
              />
            </div>

            <div className={`form-group row col-md-12`}>
              {id && <label className='mb-1 fz-13px-rem  label_input'>Description</label>}

              <div>
                <EditableDescription
                  key={2}
                  isEditable={true}
                  isUpdate
                  handleChange={(val) => {
                    handleChange(val);
                  }}
                  data={{ note: okrData?.note }}
                >
                  <div
                    className={` ${id ? 'field-input-field' : 'task-description-dummy '}  mb-1`}
                    style={{
                      width: '50%',
                      height: 'fit-content',
                      lineBreak: 'anywhere',
                      maxHeight: 100,
                      overflow: 'auto',
                    }}
                  >
                    <div
                      className='d-flex align-items-center ptr '
                      style={{ padding: ` ${!id ? '6px 1px' : ' '} ` }}
                    >
                      {!removeTagsAndGetContent(draftToHtml(okrData?.note)) ? (
                        <IconLabelSet
                          textClassName=''
                          label={'Add Description'}
                          iconClassName='d-flex justify-content-center align-items-center'
                          icon={<FileIcon width={16} height={18} />}
                        />
                      ) : (
                        parse(draftToHtml(okrData?.note))
                      )}
                    </div>
                  </div>
                </EditableDescription>
              </div>
            </div>

            <div className='row my-2'>
              <div className='col-md-6'>
                <label className='form-input-label'>Time Interval</label>
                <ReactSelect
                  styles={CommonDropDownStyle}
                  components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                  placeholder='Select'
                  options={timeIntervalOptions}
                  getOptionValue={(option) => {
                    return option?.value;
                  }}
                  getOptionLabel={(option) => {
                    return option?.label;
                  }}
                  menuPlacement={
                    parseInt(okrData?.okr_progress_type) === progressType.basedOnChild
                      ? 'top'
                      : 'auto'
                  }
                  onChange={(selectedOption) => {
                    handleChange({
                      target: {
                        name: 'interval',
                        value: selectedOption.value,
                      },
                    });
                  }}
                  value={timeIntervalOptions?.find(
                    (option) => option.value === parseInt(okrData?.interval),
                  )}
                />
                {submitted && isNaN(okrData?.interval) && (
                  <div className='help-block'>Time Interval is required</div>
                )}
              </div>
            </div>

            {/* date section  */}

            <div className='row my-2'>
              <div className='col-md-6 d-flex gap-1'>
                <div className='col-md-6'>
                  <label className='form-input-label'>Start Date</label>
                  <input
                    type='date'
                    className={`input-box ${
                      submitted && !okrData?.task_start_date ? 'input-error' : ''
                    }`}
                    id='task_start_date'
                    name='task_start_date'
                    style={{ padding: '0 8px', height: 34 }}
                    onChange={handleChange}
                    value={moment(parseInt(okrData?.task_start_date)).format('YYYY-MM-DD')}
                  />
                  {submitted && !okrData?.task_start_date && (
                    <div className='help-block'>Start Date is required</div>
                  )}
                </div>
                <div className='col-md-6'>
                  <label className='form-input-label'>End Date</label>
                  <input
                    type='date'
                    disabled={okrData.interval != 0}
                    id='task_end_date'
                    name='task_end_date'
                    min={moment(parseInt(okrData?.task_start_date)).format('YYYY-MM-DD')}
                    style={{
                      padding: '0 8px',
                      height: 34,
                      backgroundColor: okrData.interval !== 0 ? 'var(--border-color)' : '',
                    }}
                    className={`input-box ${
                      (submitted && !okrData?.task_end_date) ||
                      (submitted &&
                        okrData?.task_end_date < okrData?.task_start_date &&
                        okrData?.task_end_date &&
                        okrData?.task_end_date)
                        ? 'input-error'
                        : ''
                    }`}
                    onChange={handleChange}
                    value={moment(parseInt(okrData?.task_end_date)).format('YYYY-MM-DD')}
                  />
                  <ErrorBlock
                    errorMsg={'End Date is required'}
                    hasError={submitted && !okrData?.task_end_date}
                  />
                  <ErrorBlock
                    errorMsg={okrValidationConstant.END_DATE_MUST_BE_GREATER_THAN_START_DATE}
                    hasError={
                      submitted &&
                      okrData?.task_end_date < okrData?.task_start_date &&
                      okrData?.task_end_date &&
                      okrData?.task_end_date
                    }
                  />
                </div>
              </div>
            </div>

            {/* progress section */}

            <div className='mb-3'>
              <div className='row mt-2'>
                {' '}
                <div className='col-md-6'>
                  <label className='form-input-label'>Measurement</label>
                  <ReactSelect
                    // menuPlacement={okrData?.manual_progress_type === 0 ? 'auto' : 'top'}
                    styles={CommonDropDownStyle}
                    components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                    placeholder='Select'
                    className=' w-100'
                    menuPlacement='top'
                    // menuPortalTarget={document.getElementById('MODAL')}
                    options={measurementOptions}
                    getOptionValue={(option) => {
                      return option?.value;
                    }}
                    getOptionLabel={(option) => {
                      return option?.label;
                    }}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: 'okr_progress_type',
                          value: selectedOption.value,
                        },
                      });
                    }}
                    value={measurementOptions?.find(
                      (option) => option.value === parseInt(okrData?.okr_progress_type),
                    )}
                  />
                  {submitted && isNaN(okrData?.okr_progress_type) && (
                    <div className='help-block'>Measurement is required</div>
                  )}
                </div>
              </div>

              {parseInt(okrData?.okr_progress_type) === progressType.manually && (
                <div className='row mt-3'>
                  <div className='row mb-1'>
                    <div className='col-md-12 d-flex gap-2'>
                      <div className='d-flex gap-1'>
                        <input
                          type='radio'
                          name='manual_progress_type'
                          checked={
                            parseInt(okrData?.manual_progress_type) ===
                            manualProgressType.percentage
                          }
                          onChange={() =>
                            handleChange({
                              target: {
                                name: 'manual_progress_type',
                                value: manualProgressType.percentage,
                              },
                            })
                          }
                          style={{ accentColor: 'black', cursor: 'pointer' }}
                        />
                        <span className='label-name'>Percentage</span>
                      </div>
                      <div className='d-flex gap-1'>
                        <input
                          type='radio'
                          name='manual_progress_type'
                          checked={
                            parseInt(okrData?.manual_progress_type) === manualProgressType.numeric
                          }
                          onChange={() =>
                            handleChange({
                              target: {
                                name: 'manual_progress_type',
                                value: manualProgressType.numeric,
                              },
                            })
                          }
                          style={{ accentColor: 'black', cursor: 'pointer' }}
                        />
                        <span className='label-name'>Numbers</span>
                      </div>
                      <div className='d-flex gap-1'>
                        <input
                          type='radio'
                          name='manual_progress_type'
                          checked={
                            parseInt(okrData?.manual_progress_type) === manualProgressType.binary
                          }
                          onChange={() =>
                            handleChange({
                              target: {
                                name: 'manual_progress_type',
                                value: manualProgressType.binary,
                              },
                            })
                          }
                          style={{ accentColor: 'black', cursor: 'pointer' }}
                        />
                        <span className='label-name'>Binary</span>
                      </div>
                    </div>
                  </div>
                  {parseInt(okrData?.manual_progress_type) !== manualProgressType.binary ? (
                    <div className='row mt-1'>
                      <div className={`${id ? 'col-md-4' : 'col-md-6'} `}>
                        <label className='label-name'>Start</label>
                        <input
                          type='number'
                          // className='field-input-field'
                          className={`input-box ${
                            submitted && okrData?.progress_measure_start < 0 ? 'input-error' : ''
                          }`}
                          style={{ height: 34 }}
                          id='progress_measure_start'
                          min={0}
                          // max={99}
                          name='progress_measure_start'
                          placeholder='Enter Start'
                          value={okrData?.progress_measure_start}
                          onChange={handleChange}
                        />
                        {submitted && okrData?.progress_measure_start < 0 && (
                          <div className='help-block'>Start is required</div>
                        )}
                      </div>

                      {id && (
                        <div className='col-md-4'>
                          <label className='label-name'>Current</label>
                          <input
                            type='number'
                            // max={100}
                            min={0}
                            placeholder='Enter Progress'
                            className={`input-box ${
                              submitted &&
                              id &&
                              parseInt(okrData?.okr_progress_type) === progressType.manually &&
                              (parseInt(okrData?.progress_measure_end) <
                                parseInt(okrData?.manual_current_progress) ||
                                parseInt(okrData?.progress_measure_start) >
                                  parseInt(okrData?.manual_current_progress))
                                ? 'input-error'
                                : ''
                            }`}
                            style={{ height: 34 }}
                            id='manual_current_progress'
                            name='manual_current_progress'
                            value={okrData?.manual_current_progress}
                            onChange={handleChange}
                          />
                          {submitted &&
                            id &&
                            parseInt(okrData?.okr_progress_type) === progressType.manually &&
                            (parseInt(okrData?.progress_measure_end) <
                              parseInt(okrData?.manual_current_progress) ||
                              parseInt(okrData?.progress_measure_start) >
                                parseInt(okrData?.manual_current_progress)) && (
                              <div className='help-block'>Invalid Progress</div>
                            )}
                        </div>
                      )}

                      <div className={`${id ? 'col-md-4' : 'col-md-6'} `}>
                        <label className='label-name'>Target</label>
                        <input
                          type='number'
                          // max={100}
                          min={1}
                          placeholder='Enter Target'
                          className={`input-box ${
                            (submitted && !okrData?.progress_measure_end) ||
                            (submitted &&
                              parseInt(okrData?.progress_measure_end) &&
                              parseInt(okrData?.progress_measure_start) &&
                              parseInt(okrData?.okr_progress_type) === progressType.manually &&
                              parseInt(okrData.progress_measure_end) <
                                parseInt(okrData.progress_measure_start))
                              ? 'input-error'
                              : ''
                          }`}
                          style={{ height: 34 }}
                          id='progress_measure_end'
                          name='progress_measure_end'
                          value={okrData?.progress_measure_end}
                          onChange={handleChange}
                        />
                        {submitted && !okrData?.progress_measure_end && (
                          <div className='help-block'>Target is required</div>
                        )}
                        <ErrorBlock
                          errorMsg={okrValidationConstant.PROGRESS_START_END_MISS_MATCH}
                          hasError={
                            submitted &&
                            parseInt(okrData?.progress_measure_end) &&
                            parseInt(okrData?.progress_measure_start) &&
                            parseInt(okrData?.okr_progress_type) === progressType.manually &&
                            parseInt(okrData.progress_measure_end) <
                              parseInt(okrData.progress_measure_start)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      {id && (
                        <div className='col-md-6 mt-2 '>
                          <div className='binaryCondainer'>
                            <div
                              className={`binary_key_bg ${
                                okrData?.manual_current_progress == 0 ? 'binary_key_bg_active' : ''
                              }`}
                              onClick={() => {
                                handleChange({
                                  target: { name: 'manual_current_progress', value: 0 },
                                });
                              }}
                            >
                              Incomplete
                            </div>
                            <div
                              className={`binary_key_bg ${
                                okrData?.manual_current_progress == 100
                                  ? 'binary_key_bg_active'
                                  : ''
                              }`}
                              onClick={() => {
                                handleChange({
                                  target: { name: 'manual_current_progress', value: 100 },
                                });
                              }}
                            >
                              Complete
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* end of progress section  */}
          </div>
          <div
            className='modal_footer'
            style={{
              boxShadow: scrollFlag.isBottomReached
                ? ''
                : '0 -6px 5px -5px var(--shadow, #091e4229)',
            }}
          >
            <Button.Container
              className={'cancel-btn'}
              type='button'
              handleOk={() => {
                handleClose(okrFormAction.Close);
              }}
            >
              <Cancel width={15} height={15} color='#696969' />
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container
              handleOk={(e) => handleSubmit(e)}
              type='submit'
              className='blue-btn'
              isDisabled={isLoading}
            >
              <Tick width={16} height={16} />
              <Button.Title title='Save' />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
}
