import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import React, { useContext } from 'react';
// import CircularAvatar from '../Myteam/CircularAvatar';
import imageCompression from 'browser-image-compression';
import { Columns3 } from 'lucide-react';
import moment from 'moment';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { MENU, STATUS_ICONS, apiConstants } from '../_constants';
import { CHAT_UPDATE_TYPE, MIME_TYPE_MAPPING } from '../_constants/chat.constants';
import {
  daysWithFirstLetter,
  monthlyRecurType,
  recurFrequency,
  recurFrequencyString,
  recurWeekNumberObj,
} from '../_constants/recurrence.constants';
import {
  MENU_ICONS,
  START_MODES,
  TEMPLATE_FIELD_VALIDATIONS,
  VALIDATION_VALUES,
} from '../_constants/template.constants';
import { GlobalContext } from './Contexts';
import { Images } from './Images';
import {
  CalendarIcon,
  ChatIconSideBar,
  ClientIcon,
  CompletedDot,
  DashBoardIcon,
  DefaultTaskStatusIcon,
  MyTaskIcon,
  MyTeamIconSvg,
  OkrIcon,
  ProjectIconSidebar,
  TeamTaskIcon,
  TemplateIcon,
} from './SvgIcons';

// --Project Name convert into project-name
export function formatString(status) {
  // Convert status to lowercase
  let lowercaseStatus = status?.toLowerCase();
  // Replace spaces with underscores
  let formattedStatus = lowercaseStatus?.replace(/\s+/g, '-');
  return formattedStatus;
}

export const isActive = (status) => {
  const params = new URLSearchParams(location.search);
  return params.get('status') === formatString(status);
};

// Convert bytes to kilobytes
export const getSize = (fileSizeInBytes) => {
  const fileSizeInKB = fileSizeInBytes / 1024;

  // Check if the size is less than 1 MB
  if (fileSizeInKB < 1024) {
    return fileSizeInKB.toFixed(2) + ' KB'; // Display as KB
  } else {
    const fileSizeInMB = fileSizeInKB / 1024;
    return fileSizeInMB.toFixed(2) + ' MB'; // Display as MB
  }
};

export const sanitizeFileName = (fileName) => {
  // Remove special characters, spaces, dots, %, and #
  const sanitizedFileName = fileName.replace(/[^\w.%#-\s]/g, '');
  // Remove all spaces
  const fileNameWithoutSpaces = sanitizedFileName.replace(/\s/g, '');
  // Remove file extension
  const fileNameWithoutExtension = fileNameWithoutSpaces.split('.').slice(0, -1).join('');
  return fileNameWithoutExtension;
};

export const getFileType = (extension) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv'];
  const audioExtensions = ['mp3', 'wav', 'aac', 'ogg', 'wma', 'flac'];
  const pdfExtensions = ['pdf'];

  const lowerCaseExtension = extension.toLowerCase();

  if (imageExtensions.includes(lowerCaseExtension)) {
    return 'image';
  } else if (videoExtensions.includes(lowerCaseExtension)) {
    return 'video';
  } else if (audioExtensions.includes(lowerCaseExtension)) {
    return 'audio';
  } else if (pdfExtensions.includes(lowerCaseExtension)) {
    return 'pdf';
  } else {
    return 'document';
  }
};

export const userInitialLetter = (name) => {
  let initials = '';
  if (name) {
    let fullName = name.toString().trim();
    if (fullName.indexOf(' ') !== -1) {
      let firstName = fullName.split(' ')[0];
      let lastName = fullName.split(' ')[1];
      initials = firstName.charAt(0) + '' + lastName.charAt(0);
    } else {
      initials = fullName.slice(0, 1);
    }
  }
  return initials.toUpperCase();
};

export const fullNameUser = (fName, sName) => {
  if (fName && sName) return fName + ' ' + sName;
  else if (fName) return fName;
  else return 'Unnamed User';
};

export function dayHourToFloat(daysParam, hoursParam) {
  let days = daysParam;
  let hours = hoursParam;

  if (!days) {
    days = 0;
  }
  if (!hours) {
    hours = 0;
  }
  return parseInt(days) + Math.round(hours) / 24;
}

export function floatToDayHour(floatValue) {
  if (!floatValue) {
    return { days: '', hours: '' };
  }
  const days = Math.floor(floatValue);
  const remainingHours = (floatValue - days) * 24;
  const hours = Math.round(remainingHours);
  return { days, hours };
}

// Function to convert hours and minutes to a single floating-point number
export function timeToFloat(hours, minutes) {
  if (!minutes) {
    minutes = 0;
  }
  if (!hours) {
    hours = 0;
  }
  return parseInt(hours) + parseInt(minutes) / 60;
}

// Function to convert a single floating-point number to hours and minutes
export function floatToTime(floatNumber) {
  if (isNaN(floatNumber)) {
    return { hours: '', minutes: '' };
  }

  const hours = Math.floor(floatNumber);
  const minutes = Math.round((floatNumber - hours) * 60);
  return { hours, minutes };
}

export function floatToStringTime(hour, minute) {
  // Build the time string using if statements
  if (hour && minute) {
    return `${hour} hrs ${minute} min`;
  } else if (hour) {
    return `${hour} hr`;
  } else if (minute) {
    return `${minute} min`;
  } else {
    return '0 hr';
  }
}

export const generateNextId = (arrayOfIds) => {
  if (!arrayOfIds || !Array.isArray(arrayOfIds) || arrayOfIds.length <= 0) {
    return 1;
  }
  const lastIndex = arrayOfIds.length - 1;
  const lastId = arrayOfIds[lastIndex];

  return parseInt(lastId) + 1;
};

export const generateStartModeOptions = (taskList, index = null) => {
  const taskLength = taskList.length;

  const defaultOptions = [
    {
      id: 1,
      label: 'Immediate',
      value: START_MODES.IMMEDIATE,
      info: 'Start without any constrain',
      startMode: START_MODES.IMMEDIATE,
    },
    {
      id: 2,
      label: 'Custom',
      value: START_MODES.CUSTOM_START_TIME,
      info: 'Start after the provided time',
      startMode: START_MODES.CUSTOM_START_TIME,
    },
  ];

  if (!taskLength) {
    return defaultOptions;
  }

  const lastIndex = index ?? taskLength;

  const otherTaskData = taskList.filter((_, idx) => idx !== lastIndex);

  const currentTask = taskList.find((_, idx) => idx === lastIndex);

  const otherOptions = otherTaskData.map((task, idx) => {
    const isCircular = findDeadlock(currentTask, task.tempId, taskList);

    return {
      id: 3 + idx,
      label: `After ${task.tempId}`,
      tempId: task.tempId,
      relatedTaskName: task?.name,
      value: `${START_MODES.PRECEDING_OTHER_TASK}${task.tempId}`,
      startMode: START_MODES.PRECEDING_OTHER_TASK,
      isCircular: isCircular,
    };
  });

  return [...defaultOptions, ...otherOptions];
};

// Used in generateStartModeOptions
function findDeadlock(currentTask, taskTempId, taskList) {
  const visited = new Set(); // Track visited tasks

  if (!currentTask) {
    return false;
  }

  const newTaskList = taskList.map((tsk) => {
    if (tsk.tempId === currentTask.tempId) {
      return { ...tsk, preceding_task_id: taskTempId };
    }
    return tsk;
  });

  let currentTaskId = currentTask.tempId;

  while (currentTaskId) {
    if (visited.has(currentTaskId)) {
      return true; // Deadlock found - cycle detected
    }
    visited.add(currentTaskId);

    // Find the task with the current task ID
    const currentTask = newTaskList.find((task) => task.tempId === currentTaskId);
    if (!currentTask) {
      return false; // Task not found - no dependency chain
    }

    // Move to the preceding task
    currentTaskId = currentTask.preceding_task_id;
  }

  return false; // No deadlock found
}

/***
 * Converts the provided hours, minutes and seconds into seconds
 * @param hours
 * @param minutes
 * @param seconds
 * @return Converted seconds
 */
export function timeToSeconds(hours, minutes, seconds) {
  return hours * 3600 + minutes * 60 + seconds;
}

/***
 * Convert the given seconds to {hours, minutes, seconds}
 * @param seconds - The seconds to convert to hours, minutes and seconds
 * @returns {object} in the format eg: {hours:8, minutes: 10, seconds:20}
 */
export function secondsToTime(totalSeconds) {
  let hours = Math.floor(totalSeconds / 3600);
  let remainingSeconds = totalSeconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  let seconds = remainingSeconds % 60;
  return { hours: hours, minutes: minutes, seconds: seconds };
}
export function secondsToTimeString(totalSeconds) {
  if (totalSeconds <= 0) {
    return 0;
  }
  let hours = Math.floor(totalSeconds / 3600);
  let remainingSeconds = totalSeconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  // let seconds = remainingSeconds % 60;
  // Convert hours and minutes to strings and pad with leading zeros if necessary
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Return the formatted time string
  return `${hoursString}:${minutesString}`;
}

export function timeStringToSeconds(timeString) {
  // Split the time string into hours, minutes, and optional seconds
  let timeComponents = timeString?.split(':');
  let hours = parseInt(timeComponents[0], 10);
  let minutes = parseInt(timeComponents[1], 10);

  // Check if there's a period (AM/PM) specified
  let periodIndex = timeComponents[1].indexOf(' ');
  let period = '';
  if (periodIndex !== -1) {
    period = timeComponents[1]
      .substring(periodIndex + 1)
      .trim()
      .toUpperCase();
    minutes = parseInt(timeComponents[1].substring(0, periodIndex), 10);
  }

  // Adjust hours for 12-hour format
  if (period === 'AM' && hours === 12) {
    hours = 0; // 12 AM is equivalent to 00:00
  } else if (period === 'PM' && hours !== 12) {
    hours += 12; // Add 12 hours for PM times (except for 12 PM)
  }

  // Convert to seconds
  let totalSeconds = hours * 3600 + minutes * 60;
  return totalSeconds;
}

/***
 * Function to find the circular dependency
 */
export function hasCircularDependencies(data) {
  const graph = {}; // Convert array of tasks to an adjacency list
  const taskListClone = [...data];
  taskListClone.forEach(({ tempId, preceding_task_id }) => {
    graph[tempId] = preceding_task_id;
  });

  const visited = new Set();

  function dfs(node, path) {
    visited.add(node);
    path.add(node);

    const precedingTaskId = graph[node];

    if (precedingTaskId !== undefined) {
      if (path.has(precedingTaskId)) {
        return true;
      }

      if (!visited.has(precedingTaskId) && dfs(precedingTaskId, path)) {
        return true;
      }
    }

    path.delete(node);
    return false;
  }

  for (const nodeId in graph) {
    if (!visited.has(nodeId)) {
      if (dfs(nodeId, new Set())) {
        return true;
      }
    }
  }

  return false;
}

export function hasCircularDependencyInCurrentTask(relatedTasks, currentTask) {
  // Create a map to store the relationship between tasks
  const taskMap = {};

  // Populate the task map
  relatedTasks.forEach((task) => {
    const taskId = task.tempId;
    const precedingTask = task.preceding_task_id;
    if (!taskMap[precedingTask]) {
      taskMap[precedingTask] = [];
    }
    taskMap[precedingTask].push(taskId);
  });

  // DFS function to check for circular dependency
  function dfs(task) {
    if (visited[task] === 1) {
      return true; // Found a back edge, indicating a cycle
    }
    if (visited[task] === 2) {
      return false; // Already visited this node
    }

    visited[task] = 1; // Mark as visited

    // Recursively visit dependent tasks
    if (taskMap[task]) {
      for (const nextTask of taskMap[task]) {
        if (dfs(nextTask)) {
          return true; // Cycle found
        }
      }
    }

    visited[task] = 2; // Mark as processed
    return false;
  }

  // Start DFS from the current task
  const visited = {};
  const currentTaskId = currentTask.tempId;
  return dfs(currentTaskId);
}

export const convertHtmlToRawContent = (html) => {
  if (!html) {
    return '';
  }
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return convertToRaw(contentState);
};

export const phoneNumberValidation = (phoneNumber) => {
  // Regular expression for phone number validation
  const phoneRegex = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?[\d-.\s]{7,10}$/;
  return phoneRegex.test(phoneNumber);
};

// Function to validate PIN number
export const validatePinNumber = (pinNumber) => {
  // Regular expression for PIN number validation (6 digits)
  const pinRegex = /^\d{6}$/;
  return pinRegex.test(pinNumber);
};

// return icon for side bar
export const ReturnIcon = ({ name = '' }) => {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  if (name === 'Dashboard') {
    return <DashBoardIcon />;
  } else if (name === 'Board') {
    return <Columns3 color='black' size={18} />;
  } else if (name === 'Dashboard1') {
    return <img className='tab-img' src={Images.Dashboard1} />;
  } else if (name === 'Task') {
    return <TeamTaskIcon />;
  } else if (name === 'Template') {
    return <TemplateIcon />;
  } else if (name === 'Team') {
    return <MyTeamIconSvg isSidebar={true} />;
  } else if (name === 'Todo') {
    return <img className='tab-img' src={Images.Dashboard} />;
  } else if (name === 'Query Demo') {
    return <img className='tab-img' src={Images.Dashboard} />;
  } else if (name === 'Report') {
    return <img className='tab-img' src={Images.Dashboard} />;
  } else if (name === 'settings') {
    return <img className='tab-img' src={Images.Dashboard} />;
  } else if (name === 'Chat') {
    return <ChatIconSideBar />;
  } else if (name === 'My-Task') {
    return <MyTaskIcon />;
  } else if (name === 'Calendar') {
    return <CalendarIcon />;
  } else if (name === 'Project') {
    return <ProjectIconSidebar />;
  } else if (name === 'OKR') {
    return <OkrIcon width={18} height={18} iconForSideBar={'#87909E'} />;
  } else if (name === LABEL_OF_CLIENT) {
    return <ClientIcon width={18} height={18} isSidebar={true} />;
  } else {
    return <img className='sidebar-img-icon' src={Images.Dashboard} />;
  }
};

// return user with image in file system details model

export const getTemplateMenu = (menu_name) => {
  const menu = MENU_ICONS.find((icon) => icon.name === menu_name);
  console.log({ getTemplateMenu: menu.path });

  return <img src={menu?.path} className='sidebar-img-icon' />;
};

const getUserForView = (userArray) => {
  if (!userArray) return [];
  return userArray.map((user) => {
    const name = fullNameUser(user?.first_name, user?.last_name);
    const isMale = user?.gender === 1;
    const isNeutral = user?.gender === 3;
    const url = user?.compressed_image_url ? user?.compressed_image_url : user?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    return { name, isMale, isNeutral, finalUrl };
  });
};

export const renderUserDetails = (userArray) => {
  return getUserForView(userArray).map((item) => (
    <div className='d-flex align-items-center' style={{ padding: '5px 0' }} key={item.name}>
      <CircularAvatar
        size={25}
        source={item?.finalUrl}
        className='fz-none zoom-in employee-img-with-low-border'
        isMale={item?.isMale}
        isNeutral={item?.isNeutral}
      />
      {item?.name}
    </div>
  ));
};

/***
 * For getting the specific settings by it's name
 * @param {object} settings - The array of settings
 * @param {string} labelName - THe label name.
 * @returns {object | null} Return the required setting as an object. If inputs are invalid or data is not found, it will return null
 */
export function getSettingsByName(settings, labelName) {
  if (!labelName || !settings || !Array.isArray(settings)) {
    return null;
  }
  return settings?.find((item) => item?.name === labelName) ?? null;
}

/***
 * For converting the priority label key:value to array of labels with values as the key
 * @param {Object} labels - The label list to be converted
 * @returns {Object[]} - An array of labelObj
 */
export function convertPriorityLabelToList(labels) {
  if (!labels) {
    return [];
  }
  return Object.entries(labels)
    .filter(([key, value]) => Boolean(value.text) && !isNaN(key))
    .map(([key, value]) => ({
      value: key,
      ...value,
    }));
}
// export const getFileType = (file) => {
//   const extension = file.split('.').pop().toLowerCase();
//   if (IMAGE_EXTENTIONS.includes(extension)) {
//     return 'img';
//   }
//   if (VEDIO_EXTENTIONS.includes(extension)) {
//     return 'video';
//   }
//   // Add more conditions if needed
//   return 'default';
// };

// RECURRENCE
export const getDateWithSuffix = (day) => {
  const suffix =
    day === 1 || day === 21 || day === 31
      ? 'st'
      : day === 2 || day === 22
      ? 'nd'
      : day === 3 || day === 23
      ? 'rd'
      : 'th';

  return `${day}${suffix}`;
};

export function checkWeekCountMatchesInterval(date, startDate, interval) {
  const dateClone = date.clone().startOf('d');
  const startDateClone = startDate.clone().startOf('d');
  // Find day in between start date and next sunday
  const daysUntilSunday = startDate.day() > 0 ? 7 - startDate.day() : 0;
  const nearestSundayOfStartDate = startDateClone.add(daysUntilSunday, 'days');
  // Find day difference from nearest sunday.
  const dayDifference = dateClone.clone().diff(nearestSundayOfStartDate, 'days');
  let weekCount = Math.ceil(dayDifference / 7);
  if (startDate.day() < date.day() || date.day() === 0) {
    weekCount += 1;
  }

  return weekCount % interval === 0;
}

export function checkDayMatchesInterval(date, today, interval) {
  const createdAndTodayDiff = date.clone().diff(today.clone(), 'days');
  return createdAndTodayDiff % interval === 0;
}

export function checkMonthMatchesInterval(date, today, interval) {
  let monthCount = date.clone().diff(today.clone().add(1, 'd').startOf('d'), 'month') + 1;

  return monthCount % interval === 0;
}

export function taskSortPayload(sort) {
  if (!sort) return [];

  const sortArray = [];
  if (sort.includes('Recently Viewed')) {
    sortArray.push(['last_active_time', 'DESC']);
  }
  if (sort.includes('Priority')) {
    sortArray.push(['priority', 'ASC']);
  }
  if (sort.includes('Recently Updated')) {
    sortArray.push(['updated_time', 'DESC']);
  }
  if (sort.includes('Task Date')) {
    sortArray.push(['task_start_date', 'DESC']);
  }
  if (sort.includes('Overdue')) {
    sortArray.push(['task_end_date', 'ASC']);
  }

  return sortArray;
}
// Start: For recurring

const generateWeekDayString = (weekDays, string) => {
  for (let index in weekDays) {
    const day = weekDays[index];
    const dayString = daysWithFirstLetter[day];

    string +=
      dayString.day +
      's' +
      (parseInt(index) === weekDays.length - 2 ? ' and ' : '') +
      (parseInt(index) < weekDays.length - 2 ? ', ' : '');
  }
  return string;
};
export const generateRecurrenceString = (recurData) => {
  const interval = recurData.interval;
  const frequency = recurData.frequency;

  let string = `Recur every ${interval > 1 ? interval : ''} ${recurFrequencyString[frequency]}${
    interval > 1 ? 's' : ''
  }`;
  if (frequency === recurFrequency.WEEK_RECURRENCE) {
    const weekDays = recurData.days_of_week;
    if (weekDays.length > 0) {
      string += ' (';
    }
    string = generateWeekDayString(weekDays, string);
    if (weekDays.length > 0) {
      string += ')';
    }
  }

  if (frequency === recurFrequency.MONTH_RECURRENCE) {
    const monthRecurType = recurData.monthly_recurrence_type;
    if (monthRecurType == monthlyRecurType.BY_DATE) {
      string += ' on the ' + getDateWithSuffix(recurData.days_of_month[0]) + ' day';
    }
    if (monthRecurType === monthlyRecurType.BY_WEEK) {
      const weekDays = recurData.recurrenceDaysOfWeek;
      string += ' on the ' + recurWeekNumberObj[recurData.week_number] + ' week of ';
      string = generateWeekDayString(weekDays, string);
    }
  }

  // string += ' from ' + moment(parseInt(recurData.start_date)).format('MMM DD, YYYY');

  if (recurData.end_date) {
    string += ' until ' + moment(recurData.end_date, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }

  return string;
};

// Recurring end

// Task validation
export function validateTaskWithTemplateTest(validationFieldObj, taskList = []) {
  if (!validationFieldObj) {
    return { isValid: true, errorMsg: null, requiredFields: null };
  }

  // Covert the validation fields to an array with key and values
  const validationArray = Object.entries(validationFieldObj);
  const requiredFields = [];

  for (const validationField of validationArray) {
    const key = validationField[0];
    const value = parseInt(validationField[1]);
    // if value is required, then we need to check the fields existence in the task list
    if (value === VALIDATION_VALUES.REQUIRED) {
      const isInvalid = taskList.some(
        (task) =>
          (!isNaN(task[key]) && !parseFloat(task[key])) ||
          !task[key] ||
          Array.isArray(task[key] && task[key].length <= 0),
      );
      // If fails
      if (isInvalid) {
        requiredFields.push(key);
      }
    }
  }

  if (requiredFields.length > 0) {
    return { isValid: false, requiredFields };
  }

  // If success
  return { isValid: true, errorMsg: null, requiredFields: null };
}

export function filterNullValues(obj) {
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && Boolean(obj[key])) {
      result[key] = obj[key];
    }
  }
  return result;
}

export const convertMillisecondsToDuration = (timeInMilliseconds) => {
  let totalSeconds = Math.floor(timeInMilliseconds / 1000);
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);

  let seconds = totalSeconds % 60;

  let result = [];

  if (hours > 1) {
    result.push(`${hours}`);
  }
  result.push(`${minutes > 0 ? minutes : 0}`);
  result.push(`0${seconds}`.slice(-2));

  return result.join(':');
};

export function convertBytes(bytes) {
  const bytesInKB = 1024; // 1 KB = 1024 bytes
  const bytesInMB = 1048576; // 1 MB = 1048576 bytes

  let value, unit;

  const kbValue = bytes / bytesInKB;
  if (kbValue < 1000) {
    value = Math.round(kbValue);
    unit = 'KB';
  } else {
    value = Math.round(bytes / bytesInMB);
    unit = 'MB';
  }

  return { value, unit };
}

export function ensureHttps(url) {
  // Ensure the URL starts with https:// or http://
  let formattedUrl = url;
  if (!/^https?:\/\//i.test(formattedUrl)) {
    formattedUrl = 'https://' + formattedUrl;
  }

  // Ensure the URL contains www. after the protocol
  try {
    const urlObject = new URL(formattedUrl);
    if (!urlObject.hostname.startsWith('www.')) {
      urlObject.hostname = 'www.' + urlObject.hostname;
    }

    return urlObject.toString();
  } catch (err) {
    console.log(err);
  }
}

export function extractUrls(text) {
  const urlPattern = /\bhttps?:\/\/[^\s/$.?#].[^\s]*\b/g;
  return text.match(urlPattern) || [];
}

export const formatMessageDate = (date) => {
  const messageDate = moment(date);

  if (messageDate.isSame(moment(), 'day')) {
    return 'Today';
  } else if (messageDate.isSame(moment().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  } else if (messageDate.year() !== moment().year()) {
    return messageDate.format('DD MMM YYYY');
  } else {
    return messageDate.format('DD MMM');
  }
};

export const isTemplateFieldDisabled = (type, fieldName) => {
  if (!fieldName || !TEMPLATE_FIELD_VALIDATIONS?.[fieldName]?.[type] || !type) {
    return false;
  }

  return TEMPLATE_FIELD_VALIDATIONS?.[fieldName]?.[type].isDisabled;
};

export const getChatUpdateRowData = (update) => {
  if (!update) {
    return { title: null };
  }
  const updateType = update.update_type;
  if (updateType === CHAT_UPDATE_TYPE.TASK) {
    return { title: update?.task?.name ?? update.task_name };
  }

  if (updateType === CHAT_UPDATE_TYPE.PERSONAL) {
    if (update?.receiver_details) {
      const { first_name, last_name, image_url, gender, compressed_image_url } =
        update.receiver_details;
      return {
        title: fullNameUser(first_name, last_name),
        image: compressed_image_url ? compressed_image_url : image_url,
        gender,
      };
    }
    return {
      title: fullNameUser(update.first_name, update.last_name),
      image: update.compressed_image_url ? update.compressed_image_url : update.image_url,
    };
  }

  if (updateType === CHAT_UPDATE_TYPE.GROUP) {
    if (update.group_id) {
      return { title: update?.group?.name ?? update.group_name, image: update.group.image ?? null };
    }
    if (update.team_id) {
      return { title: update?.team?.name ?? update.team_name };
    }

    if (update.project_id) {
      return { title: update?.project?.name ?? update.project_name };
    }
  }

  return { title: 'Unknown', image: null };
};
export const getChatUpdateInputId = (update) => {
  const updateType = update.update_type;
  if (updateType === CHAT_UPDATE_TYPE.TASK) {
    return { task_id: update.task_id };
  }

  if (updateType === CHAT_UPDATE_TYPE.PERSONAL) {
    if (update?.receiver_details) {
      const { id } = update.receiver_details;
      return { receiver_id: id, creator_id: update.creator_id };
    }

    return { receiver_id: update.receiver_id, creator_id: update.creator_id }; //add sender and receiver ids
  }

  if (updateType === CHAT_UPDATE_TYPE.GROUP) {
    if (update.group_id) {
      return { group_id: update.group_id };
    }
    if (update.team_id) {
      return { team_id: update.team_id };
    }
    if (update.project_id) {
      return { project_id: update.project_id };
    }
  }
};

// export function getTaskCurrentStatus(day,status) {
//   let result;

//   if(status == 'todo'){

//   }

// }

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getStatusIcon = (iconName) => {
  let path = STATUS_ICONS[iconName];
  if (!path) {
    path = <DefaultTaskStatusIcon />;
  }
  return path;
};

export const getCurrentStatusIcon = (menu_name) => {
  const menu = MENU_ICONS.find((icon) => icon.name === menu_name);
  if (!menu?.path) {
    return <DefaultTaskStatusIcon />;
  }
  return (
    <img
      src={menu?.path}
      height={menu?.height}
      width={menu?.width}
      className='sidebar-img-icon'
    />
  );
};

// function to display the status on Action column of task list
export const getTaskListActionData = (status, color) => {
  if (status === 'in-progress') {
    return (
      <div className='d-flex align-items-center gap-2 '>
        <CompletedDot color='#FA8847' />
        In-Progress
      </div>
    );
  } else if (status === 'done') {
    return (
      <div className='d-flex align-items-center' style={{ gap: 10 }}>
        <CompletedDot /> <span className='completed-text'>Completed</span>
      </div>
    );
  } else if (status === 'todo') {
    return (
      <div className='d-flex align-items-center' style={{ gap: 10 }}>
        <CompletedDot color={color} /> <span className='completed-text'>Todo</span>
      </div>
    );
  } else if (status === 'failed') {
    return (
      <div className='d-flex align-items-center' style={{ gap: 10 }}>
        <CompletedDot color={color} /> <span className='todo-text'>Failed</span>
      </div>
    );
  } else if (status === 'under_verification') {
    return (
      <div className='d-flex align-items-center' style={{ gap: 10 }}>
        {/* <CompletedDot color='#17C666' /> <span className='completed-text'>Under Verification</span> */}
        <CompletedDot color={color} /> <span className='completed-text'>Not verified</span>
      </div>
    );
  } else if (status === 'to_verify') {
    return (
      <div className='todo-action-button-outline'>
        <span className='todo-text'>Select</span>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export const updatedGetTaskListActionData = (status, color, name) => {
  return (
    <div
      className='d-flex align-items-center gap-2 action-button '
      style={{ whiteSpace: 'no-wrap' }}
    >
      {/* <span>
        <CompletedDot color={color} />
      </span> */}
      <span>{name}</span>
    </div>
  );
};

/***
 * For generating the times based on type for calendar events
 * @param {string} type - Whether it's reminder, todo or subtask
 * @param {number} date - The date to be processed
 * @returns {{startDate:string, endDate:string, endTime: string, startTime:string}}
 */
export const generateEventTimeFromType = (type, date) => {
  if (!date) {
    return { startDate: null, endDate: null, startTime: null, endTime: null };
  }

  if (!type) {
    return {
      startDate: moment(parseInt(date)).startOf('day').format('DD-MM-YYYY hh:mm:ss A'),
      endDate: moment(parseInt(date)).endOf('day').format('DD-MM-YYYY hh:mm:ss A'),
      startTime: moment.utc(parseInt(date)).startOf('days').format('HH:mm:ss'),
      endTime: moment.utc(parseInt(date)).endOf('day').format('HH:mm:ss'),
    };
  }

  if (type.reminder) {
    return {
      startDate: moment(parseInt(date)).format('DD-MM-YYYY hh:mm:ss A'),
      endDate: moment(parseInt(date)).add(10, 'minute').format('DD-MM-YYYY hh:mm:ss A'),
      startTime: moment.utc(parseInt(date)).format('HH:mm:ss'),
      endTime: moment.utc(parseInt(date)).add(10, 'minute').format('HH:mm:ss'),
    };
  }
  if (type.sub_task) {
    return {
      startDate: moment(parseInt(date)).startOf('day').format('DD-MM-YYYY hh:mm:ss A'),
      endDate: moment(parseInt(date)).endOf('day').format('DD-MM-YYYY hh:mm:ss A'),
      startTime: moment.utc(parseInt(date)).startOf('days').format('HH:mm:ss'),
      endTime: moment.utc(parseInt(date)).endOf('day').format('HH:mm:ss'),
    };
  }
  if (type.todo) {
    return {
      startDate: moment(parseInt(date)).startOf('day').format('DD-MM-YYYY hh:mm:ss A'),
      endDate: moment(parseInt(date)).endOf('day').format('DD-MM-YYYY hh:mm:ss A'),
      startTime: moment.utc(parseInt(date)).startOf('days').format('HH:mm:ss'),
      endTime: moment.utc(parseInt(date)).endOf('day').format('HH:mm:ss'),
    };
  }
  return {
    startDate: moment(parseInt(date)).startOf('day').format('DD-MM-YYYY hh:mm:ss A'),
    endDate: moment(parseInt(date)).endOf('day').format('DD-MM-YYYY hh:mm:ss A'),
    startTime: moment.utc(parseInt(date)).startOf('days').format('HH:mm:ss'),
    endTime: moment.utc(parseInt(date)).endOf('day').format('HH:mm:ss'),
  };
};

/***
 * For getting the emoji with count. Eliminate null or zero emojis
 * @param {Object} emojiWithCountObj - The emoji with count object
 */
export function getEmojiListFromObject(emojiWithCountObj) {
  if (!emojiWithCountObj) {
    return [];
  }

  const emojiList = Object.entries(emojiWithCountObj)
    .filter(([, value]) => value > 0)
    .map(([key, value]) => ({ emoji: key, count: value }));
  return emojiList;
}

export function convertHoursAndMinutes(decimalHours) {
  // Check for zero input
  if (decimalHours === 0) {
    return 0.0;
  }

  // Extract the integer part (hours)
  let totalHours = Math.floor(decimalHours);
  // Extract the fractional part and convert to minutes
  let minutes = Math.round((decimalHours - totalHours) * 100);

  // Convert minutes to hours and remaining minutes if minutes >= 60
  totalHours += Math.floor(minutes / 60);
  minutes = minutes % 60;

  // Convert back to decimal representation
  let normalizedDecimal = totalHours + minutes / 100;

  return normalizedDecimal.toFixed(2);
}

export function millisecondsToHoursMinutes(ms) {
  // Convert milliseconds to hours and minutes
  const totalMinutes = Math.floor(ms / 60000); // 60000 milliseconds in a minute
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
}

export function hoursToHoursMinutes(hoursDecimal) {
  // Extract hours
  const hoursInTime = Math.floor(hoursDecimal);

  // Calculate minutes
  const minutesInTime = Math.round((hoursDecimal - hoursInTime) * 60);

  return { hoursInTime, minutesInTime };
}

export function hoursToHoursMinutesIn100(hoursDecimal) {
  // Extract hours
  const hours = Math.floor(hoursDecimal);

  // Calculate minutes
  const minutes = Math.round((hoursDecimal - hours) * 100);

  return { hours, minutes };
}

export const focusInputByClassName = (className) => {
  const element = document.querySelector(`.${className}`);
  if (element) {
    element?.focus();
  }
};

const isUrl =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(?:com|org|net|edu|gov|mil|int|co|io|us|ca|uk|de|jp|au|fr|br|in|ru|za|mx|nl|se|no|ch|it|es|pl|be|at|dk|fi|gr|hu|cz|ro|sk|bg|ie|lt|lv|ee|si|hr|lu|mt|cy|is|li|md|ua|by|kz|vn|th|tw|hk|sg|my|ph|id|pk|bd|np|lk|kh|mm|la|bt|ae|sa|kw|qa|om|bh|ye|ma|dz|tn|ly|ng|ke|tz|ug|zw|zm|na|sz|sc|mu|mr|rw|sl|sn|ne|ci|gh|gw|lr|et|mw|sy|jo|ps|il|kw|qa|bh|sa|om|ae|co\.uk|ac\.uk|gov\.uk|edu\.uk|org\.uk|whatsapp\.com)(\/[^\s]*)?$/i;

export function isValidUrl(text) {
  // Remove any leading or trailing whitespace
  const trimmedText = text.trim();
  // Check if the text matches the URL pattern
  return isUrl.test(trimmedText);
}

export function formatMillisecondFeatured(ms, isSecondsNeed = false) {
  if (!ms || ms === 0) {
    return '--';
  }

  const duration = moment.duration(ms, 'milliseconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = '';

  if (hours > 0) {
    result += `${hours} Hr${hours > 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    result += `${minutes} Min${minutes > 1 ? 's' : ''} `;
  }
  if ((seconds > 0 || result === '') && isSecondsNeed) {
    result += `${seconds} Sec${seconds > 1 ? 's' : ''}`;
  }
  return result.trim();
}

export function getHourFormMilliSecond(ms, includeMinute = false) {
  if (!ms) {
    return 0;
  }

  const duration = moment.duration(ms, 'milliseconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  let result = 0;

  if (hours > 0) {
    result = hours;
  }

  if (includeMinute && hours >= 0) {
    // Convert minutes to fraction (out of 60) and add to hours
    const minuteFraction = minutes / 60;
    result = hours + parseFloat(minuteFraction.toFixed(2)); // Adds the fractional part
  }
  return result;
}

export function isEffortGreaterThanExpected(expected_hours, sum_of_efforts) {
  // if (expected_hours == null) {
  //   throw new Error('Invalid input value for expected_hours');
  // }

  // Handle the case where sum_of_efforts is null or zero
  if (sum_of_efforts == null || sum_of_efforts === 0 || expected_hours === 0) {
    return false;
  }

  const hoursToMilliseconds = expected_hours * 60 * 60 * 1000; // Convert hours to milliseconds

  return sum_of_efforts > hoursToMilliseconds;
}

// Function to calculate the percentage of progress between start and end
export function percentageOfManualOkr(start, end, progress) {
  const base = end - start;
  const value = progress - start;
  const result = (value / base) * 100;
  return result;
}

// Function to calculate the current progress based on a given percentage
export function currentProgressBasedOnStartEnd(start, end, percentage) {
  const base = end - start;
  const value = (percentage / 100) * base;
  const progress = start + value;
  return progress;
}
const urlPattern = /(?:(?:(?:ftp|http)[s]*:\/\/|www\.)[^.]+\.[^ \n]+)/gi;

const whatsappPattern = /^(https?:\/\/)?(www\.)?wa\.me\/\d+/i;

const customUrlPattern = /(https?:\/\/[^\s]+)/g;

export const isValidUrlFeatured = (str) => {
  return urlPattern.test(str) || whatsappPattern.test(str) || customUrlPattern.test(str);
};
/***
 * Convert base64 images to a file format
 * @param {string} base64String - THe base64string input
 * @returns {{file:File, fileType:number}} file
 */
export function base64ToFile(base64String) {
  const mimeMatch = base64String.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  const mime = mimeMatch ? mimeMatch[1] : undefined;

  const fileType = MIME_TYPE_MAPPING[mime] || 2;

  const extension = mime ? mime.split('/')[1] : 'txt';
  const filename = `file_${Date.now()}.${extension}`;

  const byteString = atob(base64String.split(',')[1]);
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new File([byteArray], filename, { type: mime });

  return { file, fileType };
}

export function jsonToUrlEncoded(obj) {
  const str = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
  return str;
}

export const getCurrentTimeInMilliseconds = (time = null) => {
  const selectedTime = time ? time : moment();
  return (selectedTime.hours() * 60 + selectedTime.minutes()) * 60 * 1000; // Convert to milliseconds from midnight
};

export const getHoursFromMs = (ms) => {
  const totalMinutes = ms / (60 * 1000);
  return Math.floor(totalMinutes / 60) % 12 || 12; // Adjust for 12-hour format
};

export const getMinutesFromMs = (ms) => {
  return Math.floor(ms / (60 * 1000)) % 60;
};

export const getPeriodFromMs = (ms) => {
  const totalMinutes = ms / (60 * 1000);
  return totalMinutes < 12 * 60 ? 'AM' : 'PM';
};

export const convertTimeIn12ToMillisecond = (hours, minutes, period) => {
  let hours24 = parseInt(hours);

  // Convert to 24-hour format if the period is "PM" and it's not 12 PM
  if (period === 'PM' && hours24 !== 12) {
    hours24 += 12;
  }

  // Handle 12 AM case (midnight)
  if (period === 'AM' && hours24 === 12) {
    hours24 = 0;
  }

  // Convert hours and minutes to milliseconds
  const millisecondsFromHours = hours24 * 60 * 60 * 1000; // 1 hour = 3600000 ms
  const millisecondsFromMinutes = minutes * 60 * 1000; // 1 minute = 60000 ms

  return millisecondsFromHours + millisecondsFromMinutes;
  // // Adjust hours for AM/PM
  // if (period === 'PM' && hours < 12) {
  //   hours += 12; // Convert PM hour to 24-hour format
  // } else if (period === 'AM' && hours === 12) {
  //   hours = 0; // Convert 12 AM to 0 hours
  // }

  // // Calculate total milliseconds from midnight
  // return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
};

export const timeBased24StringTo12Milliseconds = (timeString) => {
  let [hours, minutes] = timeString.split(':').map(Number);
  let amPm = 'AM';

  if (hours === 0 && minutes === 0) {
    // hours = 12;
    // minutes = 0;
    // amPm = 'AM';
    return 0;
  }
  // Determine AM/PM based on the hour
  else if (hours >= 12) {
    amPm = 'PM';
    if (hours > 12) hours -= 12; // Convert to 12-hour format for PM times
  } else if (hours === 0) {
    hours = 12; // Convert 00:00 (midnight) to 12:00 AM
  }

  // Convert hours and minutes to milliseconds
  const hoursInMs = (amPm === 'PM' ? (hours + 12) % 24 : hours) * 60 * 60 * 1000;
  const minutesInMs = minutes * 60 * 1000;

  // Return total milliseconds
  return hoursInMs + minutesInMs;
};

// export const getLabelForProject = (label, isLowerCase = false) => {
//   if(label === LABEL_OF_PROJECT.)
// };

export function isImageFile(extension) {
  const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'svg'];
  return imageExtensions.includes(extension.toLowerCase());
}

export const compressImageOnly = async (file, customOptions = null) => {
  const options = {
    maxSizeMB: 0.2, // Max size of compressed image
    maxWidthOrHeight: 512, // Max width or height of compressed image
    useWebWorker: true,
  };

  let compressionOptions = customOptions ? customOptions : options;
  const compressedFile = await imageCompression(file, compressionOptions);

  const compressedImageBlobUrl = URL.createObjectURL(compressedFile);

  return {
    compressedFile,
    compressedImageBlobUrl,
  };
};

export function getSettingValue(settingsData, defaultName) {
  return (
    settingsData?.labels?.find((settings) => settings?.default_name === defaultName)?.value || false
  );
}
export function getSettingValueByLabel(settingsData, label) {
  return settingsData?.labels?.find((settings) => settings?.label === label)?.value || false;
}
function normalizeValue(value, upperLimit = 6, lowerLimit = 0) {
  if (value < lowerLimit) {
    return upperLimit + 1 + value;
  } else {
    return value % (upperLimit + 1);
  }
}

export function moveRecurrenceToDays(changeDay, currentRecurrenceData) {
  if (!currentRecurrenceData) {
    return null;
  }

  const updatedRecurrenceData = { ...currentRecurrenceData };

  if (!changeDay) {
    return updatedRecurrenceData;
  }

  if (updatedRecurrenceData.days_of_week.length > 0) {
    updatedRecurrenceData.days_of_week = updatedRecurrenceData.days_of_week.map((day) =>
      normalizeValue(day + changeDay),
    );
  }

  if (updatedRecurrenceData.days_of_month.length > 0) {
    updatedRecurrenceData.days_of_month = updatedRecurrenceData.days_of_month.map((day) =>
      normalizeValue(parseInt(day) + changeDay, 31, 1),
    );
  }

  return updatedRecurrenceData;
}

export function hoursAndMinutesToMilliSeconds(hours, minutes) {
  return hours * 3600000 + minutes * 60000;
}
