import { keepPreviousData, useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { File } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import ReactSelect from 'react-select';
import { CreatableEditableSelect } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import UserDropDown from '../_components/UserDropDown';
import { COMMON_USER_SELECT, CommonDropDownStyle, MENU, USER_LIST_ACTIONS } from '../_constants';
import { FILE_TYPE, sharedUserSelect } from '../_constants/filesystem.constant';
import useDebounce from '../_helpers/useDebounce';
import { clientService, folderService, projectService, userService } from '../_services';
import { teamService } from '../_services/team.service';
import { EditableDescription } from '../Task/TaskComponents';
import { CustomVariableInput } from '../Task/TaskComponents/CustomInputs';
import { removeTagsAndGetContent } from '../Task/taskValidation';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { FileSystemImage, GlobalContext } from '../Utils';
import { OptionTabs } from './OptionTabs';
import { SelectedFiles } from './SelectedFiles';
import { SharedWith } from './SharedWith';

export const DigitalFileAdd = ({
  handleChange = () => {},
  handleDocumentTypeSelect = () => {},
  onFileSelect = () => {},
  selectedFiles,
  fileRemove = () => {},
  fileData,
  setFileData,
  submitted = false,
  fileType,
  fileId = null,
  advanceOptionVisible,
  setAdvanceOptionVisible,
  fileUploadVisible,
  setFileUploadVisible,
}) => {
  const [ownerSearch, setOwnerSearch] = useState(''); // state for  user list search
  const debouncedOwner = useDebounce(ownerSearch, 500);

  const [folderSearch, setFolderSearch] = useState(''); // state for  folder list search
  const debouncedFolderSearch = useDebounce(folderSearch, 500);

  const [activeTab, setActiveTab] = useState('Share'); // advanced option tabs
  const [clientSearch, setClientSearch] = useState(''); // state for  user list search
  const debounceClient = useDebounce(clientSearch, 500);
  const [projectSearch, setProjectSearch] = useState(''); // state for  user list search
  const debounceProject = useDebounce(projectSearch, 500);

  let today = moment().format('YYYY-MM-DD');

  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  // get all owner list
  const { data: ownerData, isLoading: ownerLoading } = useQuery({
    queryKey: ['user-list', debouncedOwner],
    queryFn: () =>
      userService.getRoleBasedUserList({
        searchKey: debouncedOwner,
        action: USER_LIST_ACTIONS.ACTION_FILE_ALLOCATION,
        select: COMMON_USER_SELECT,
      }),
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  // team list
  const { data: teamData } = useQuery({
    queryKey: ['shared-team-list'],
    queryFn: () =>
      teamService.teamList({
        select: ['id', 'name'],
      }),
    select: (data) => data.data.rows,
  });

  // folder list
  const { data: folderData, isLoading: folderLoading } = useQuery({
    queryKey: ['folder-list', debouncedFolderSearch],
    queryFn: () =>
      folderService.folderList({
        select: ['id', 'name'],
        searchKey: debouncedFolderSearch,
      }),
    select: (data) => data.data.rows,
  });

  const { data: usersForShare } = useQuery({
    queryKey: ['user-list-file'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: sharedUserSelect,
        action: USER_LIST_ACTIONS.ACTION_FILE_ALLOCATION,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  const { data: clientData, isLoading: isClientLoading } = useQuery({
    queryKey: ['clientList', debounceClient],
    queryFn: () => clientService.clientList({ searchKey: debounceClient, select: ['id', 'name'] }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  // project list
  const { data: projectData, isLoading: isProjectLoading } = useQuery({
    queryKey: ['project-list', debounceProject],
    queryFn: () =>
      projectService.projectListAdvanced({ searchKey: debounceProject, select: ['id', 'name'] }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  const projectForShareWithAll = projectData ? [{ id: 0, name: 'All' }, ...projectData] : [];

  return (
    <div style={{ maxHeight: 394, overflowY: 'auto', padding: 10 }}>
      {(fileType === FILE_TYPE.PHYSICAL || Boolean(fileId)) && (
        <>
          <div className=' my-2'>
            <div className='mt-10px '>
              <CustomVariableInput
                name='name'
                value={fileData?.name}
                placeholder={`Add File name`}
                onChange={(event) => handleChange(event)}
                className={`milestone-name-input w-100  border-none `}
              />
              <ErrorBlock
                errorMsg={'File name required'}
                hasError={submitted && fileData?.name.toString().trim() === ''}
              />
            </div>
          </div>
          <div className={`form-group w-75 `}>
            {fileId && <label className='mb-1 fz-13px-rem  label_input'>Description</label>}
            <div>
              <EditableDescription
                key={2}
                isEditable={true}
                isUpdate
                handleChange={(val) => {
                  handleChange(val);
                }}
                data={{ note: fileData?.description }}
              >
                <div
                  className={` ${fileId ? 'input-box' : 'task-description-dummy '}  mb-1`}
                  style={{
                    width: '67%',
                    height: 'fit-content',
                    lineBreak: 'anywhere',
                    maxHeight: 100,
                    overflow: 'auto',
                  }}
                >
                  <div className={`${fileId ? '' : 'd-flex'} align-items-center ptr`}>
                    {!removeTagsAndGetContent(draftToHtml(fileData?.description)) ? (
                      <IconLabelSet
                        textClassName=''
                        label={'Add Description'}
                        iconClassName='d-flex justify-content-center align-items-center'
                        icon={<File width={14} height={16} />}
                      />
                    ) : (
                      parse(draftToHtml(fileData?.description))
                    )}
                  </div>
                </div>
              </EditableDescription>
            </div>
          </div>
        </>
      )}
      {fileType === FILE_TYPE.DIGITAL && !fileId && (
        <div>
          <input
            accept={'*/*'}
            multiple
            onChange={(e) => onFileSelect(e.target.files)}
            id='upload'
            type='file'
            style={{ display: 'none' }}
          />
          <div className='file-upload-contain' style={{ padding: '2rem 5px' }}>
            <div className='icon-outer-file'>
              <img src={FileSystemImage.FileUpload} alt='upload' height={45} width={45} />
            </div>
            {/* Drag and Drop file here or */}
            <label className='upload-text' htmlFor='upload'>
              Choose file
            </label>
          </div>
          <ErrorBlock
            errorMsg={'Please upload file'}
            hasError={submitted && selectedFiles?.length == 0 && fileType === FILE_TYPE.DIGITAL}
          />
          <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
            {/* {selectedFilesData} */}
            <SelectedFiles selectedFiles={selectedFiles} fileRemove={fileRemove} />
          </div>
        </div>
      )}
      {fileType === FILE_TYPE.PHYSICAL && (
        <div className='col-md-6'>
          <label className='mb-1 fz-13px-rem  label_input'>Shelf</label>
          <input
            type='text'
            className='input-box'
            style={{ height: '34px' }}
            name='location'
            value={fileData?.location}
            placeholder='Enter location'
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <ErrorBlock
            errorMsg={'Location selection required'}
            hasError={submitted && fileData.location.toString().trim() === ''}
          />
        </div>
      )}
      {/* file related to selection  */}
      <div className='col-md-6'>
        <div className='my-1 fz-13px-rem '>Owner</div>
        <ReactSelect
          placeholder='Choose owner'
          name='owner_id'
          styles={{
            ...CommonDropDownStyle,
            menuList: (styles) => {
              return {
                ...styles,
                maxHeight: '140px',
              };
            },
          }}
          formatOptionLabel={(data, metaData) => (
            <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
          )}
          getOptionValue={(option) => {
            return parseInt(option?.id);
          }}
          menuPlacement='top'
          onInputChange={(inputString) => {
            setOwnerSearch(inputString);
          }}
          filterOption={null}
          inputValue={ownerSearch}
          options={ownerData}
          onChange={(selectedOption) => {
            handleChange({
              target: {
                name: 'owner_id',
                value: selectedOption,
              },
            });
          }}
          isLoading={ownerLoading}
          value={fileData?.owner_id}
        />
        <ErrorBlock
          errorMsg={'Owner selection required'}
          hasError={submitted && !fileData?.owner_id}
        />
      </div>
      {/* end of file related to selection  */}
      {/* document type selection  */}
      <div className='  col-md-6'>
        <div className='my-1 fz-13px-rem '> Document Type</div>
        <CreatableEditableSelect
          documentTypeId={fileData?.document_type_id}
          setDocumentTypeId={handleDocumentTypeSelect}
        />
        <ErrorBlock
          errorMsg={'Document type selection required'}
          hasError={submitted && !fileData?.document_type_id}
        />
      </div>
      {/* end of document type selection  */}
      {fileType === FILE_TYPE.PHYSICAL && (
        <>
          <div className='project-separation my-3' />
          {/* advanced option  */}
          <div
            className='fz-13px-rem d-flex justify-content-between cursor-pointer'
            onClick={() => setFileUploadVisible(!fileUploadVisible)}
          >
            Upload attachments (optional)
            <img
              style={{ height: 8 }}
              src={fileUploadVisible ? FileSystemImage.ArrowUp : FileSystemImage.ArrowDown}
            />
          </div>
          {fileUploadVisible && (
            <div>
              <input
                accept={'*/*'}
                multiple
                onChange={(e) => onFileSelect(e.target.files)}
                id='upload'
                type='file'
                style={{ display: 'none' }}
              />
              <div className='file-upload-contain' style={{ padding: '2rem 5px' }}>
                <div className='icon-outer-file'>
                  <img src={FileSystemImage.FileUpload} alt='upload' height={45} width={45} />
                </div>
                {/* Drag and Drop file here or */}
                <label className='upload-text' htmlFor='upload'>
                  Choose file
                </label>
              </div>
              <ErrorBlock
                errorMsg={'Please upload file'}
                hasError={submitted && selectedFiles.length == 0 && fileType === FILE_TYPE.DIGITAL}
              />
              <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
                {/* {selectedFilesData} */}
                <SelectedFiles selectedFiles={selectedFiles} fileRemove={fileRemove} />
              </div>
            </div>
          )}
        </>
      )}
      <div className='project-separation my-3' />
      {/* advanced option  */}
      <div
        className='fz-13px-rem d-flex justify-content-between cursor-pointer'
        onClick={() => setAdvanceOptionVisible(!advanceOptionVisible)}
      >
        Advanced Option
        <img
          style={{ height: 8 }}
          src={advanceOptionVisible ? FileSystemImage.ArrowUp : FileSystemImage.ArrowDown}
        />
      </div>
      {advanceOptionVisible && (
        <div>
          <OptionTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className='my-4'>
            {activeTab === 'Folder' && (
              <div className='col-md-6'>
                <ReactSelect
                  placeholder='Choose Folder'
                  name='folder_id'
                  styles={CommonDropDownStyle}
                  // menuPortalTarget={document.getElementById('MODAL')}
                  getOptionValue={(option) => {
                    return parseInt(option?.id);
                  }}
                  getOptionLabel={(option) => option?.name}
                  menuPlacement='top'
                  onInputChange={(inputString) => {
                    setFolderSearch(inputString);
                  }}
                  filterOption={null}
                  inputValue={folderSearch}
                  options={folderData}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: {
                        name: 'folder_id',
                        value: selectedOption,
                      },
                    });
                  }}
                  isLoading={folderLoading}
                  value={fileData?.folder_id}
                />
              </div>
            )}
            {activeTab == `${LABEL_OF_CLIENT}` && (
              <div className='col-md-6'>
                <ReactSelect
                  placeholder={`Choose ${LABEL_OF_CLIENT}`}
                  name='client_id'
                  styles={CommonDropDownStyle}
                  // menuPortalTarget={document.getElementById('MODAL')}
                  getOptionValue={(option) => {
                    return parseInt(option?.id);
                  }}
                  menuPlacement='top'
                  onInputChange={(inputString) => {
                    setClientSearch(inputString);
                  }}
                  value={fileData?.client_id}
                  filterOption={null}
                  inputValue={clientSearch}
                  options={clientData}
                  isLoading={isClientLoading}
                  getOptionLabel={(option) => option?.name}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: {
                        name: 'client_id',
                        value: selectedOption,
                      },
                    });
                  }}
                />
              </div>
            )}
            {activeTab === 'Expire & Notification' && (
              <>
                <div className='mb-2'>
                  <label className='mb-1 fz-13px-rem  label_input'>Issued Date</label>
                  <input
                    type='date'
                    className='input-box'
                    style={{ height: '34px' }}
                    placeholder={today}
                    value={moment(fileData?.issued_date).format('YYYY-MM-DD')}
                    name='issued_date'
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
                <div className=''>
                  <label className='mb-1 fz-13px-rem  label_input'>Expiry Date</label>
                  <input
                    type='date'
                    className='input-box'
                    style={{ height: '34px' }}
                    placeholder={today}
                    value={moment(fileData?.expiry_date).format('YYYY-MM-DD')}
                    name='expiry_date'
                    min={fileData?.issued_date}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </>
            )}
            {activeTab === 'Share' && (
              <>
                <SharedWith
                  onAdd={(userOrTeam, type) => {
                    const name = type === 'user' ? 'shared_with_user' : 'shared_with_team';
                    handleChange({
                      target: {
                        value: [...(fileData[name] ?? []), ...userOrTeam],
                        name: name,
                        type: 'shared_with',
                      },
                    });
                  }}
                  onAvatarRemove={(userOrTeam, name) => {
                    setFileData((prev) => ({
                      ...prev,
                      [name]: prev[name].filter(
                        (item) => parseInt(item.id) !== parseInt(userOrTeam.id),
                      ),
                    }));
                  }}
                  userImageList={fileData?.shared_with_user ? fileData?.shared_with_user : []}
                  sharedUserSelect={sharedUserSelect}
                  usersForShare={usersForShare}
                  teamForShare={teamData}
                  teamImageList={fileData?.shared_with_team ? fileData?.shared_with_team : []}
                />
                {hasExist(MENU?.project?.default_name) && (
                  <div className='col-md-6'>
                    <label className='mb-1 fz-13px-rem  label_input'>{LABEL_OF_PROJECT}</label>
                    <ReactSelect
                      placeholder={`Choose ${LABEL_OF_PROJECT}`}
                      name='shared_with_project'
                      isMulti
                      styles={CommonDropDownStyle}
                      getOptionValue={(option) => {
                        return parseInt(option?.id);
                      }}
                      menuPlacement='top'
                      onInputChange={(inputString) => {
                        setProjectSearch(inputString);
                      }}
                      value={fileData?.shared_with_project}
                      filterOption={null}
                      inputValue={projectSearch}
                      options={projectForShareWithAll}
                      isLoading={isProjectLoading}
                      getOptionLabel={(option) => option?.name}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'shared_with_project',
                            value: selectedOption,
                            type: 'shared_with',
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
