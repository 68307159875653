import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import ReactSelect from 'react-select';
import { Table, Td, Th, Tr } from 'react-super-responsive-table';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { CommonDropDownStyle } from '../_constants';
import { permissionService } from '../_services/permission.service';
import { GlobalContext } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';

export default function AdvancedPermission() {
  const { makeAlert, getRolePermission, globalSettingsRefetch } = useContext(GlobalContext);
  // const queryClient = useQueryClient();
  // Fetch permissions using useQuery
  const {
    data: permissionData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['advance-permission'],
    queryFn: permissionService.getAllPermissionList, // Pass the function reference
  });

  // Handle loading state
  if (isLoading) {
    return (
      <div className='w-100 text-center'>
        <LoadingIcon size={60} />
      </div>
    );
  }

  // Group permissions by action_group
  const groupedPermissions = permissionData?.data || {}; // Adjust according to your API response structure

  // const { mutate: handleMutate } = useMutation({
  //   mutationFn: (data) => permissionService.updatePermission(data),
  //  mutationKey:['permission'],
  //   onSuccess: () => {
  //     makeAlert('Updated');
  //     refetch();
  //   },
  // });

  const handleCheckBoxChange = async (permission, role, value, permissionId) => {
    const updatedPermission = {
      ...permission,
      [role]: !value,
    };

    try {
      await permissionService.updatePermission({ id: permissionId, permission: updatedPermission });
      refetch();
      getRolePermission();
      globalSettingsRefetch();
      makeAlert('Updated successfully.');
    } catch (error) {
      console.error('Error updating permission:', error);
      makeAlert('Failed to update permission. Please try again.');
    }
  };

  const handleSelectionChange = async (permission, role, value, permissionId) => {
    const updatedPermission = {
      ...permission,
      [role]: value,
    };

    try {
      await permissionService.updatePermission({ id: permissionId, permission: updatedPermission });
      refetch();
      getRolePermission();
      globalSettingsRefetch();
      makeAlert('Updated successfully.');
      // queryClient.resetQueries('')
    } catch (error) {
      console.error('Error updating permission:', error);
      makeAlert('Failed to update permission. Please try again.');
    }
  };


  return (
    <div className='fade-entry h-100 mt-3'>
      {Object.entries(groupedPermissions).map(([actionGroup, permissions]) => {
        const mainPermission = permissions?.filter((item) => item.isMainPermission);
        const label = permissions?.find((item) => item.isMainPermission)?.name;
        const description = permissions?.find((item) => item.isMainPermission)?.description;
        const isSubExists = permissions?.find((item) => !item.isMainPermission);
        const subPermissions = permissions?.filter((item) => !item.isMainPermission);
 
        return (
          <div
            key={actionGroup}
            className='permission-group mb-3 pb-3'
            style={{ borderBottom: `1px solid var(--border-color)`, width: '75%' }}
          >
            <div className='general_head'>{label}</div>
            <div className='description-text'>{description}</div>

            {mainPermission.map((permission) => (
              <div key={permission.id} className='permission-item mt-2'>
                {/* table structure  */}
                <Table>
                  <Tr className='permission-note' style={{ fontWeight: 500 }}>
                    <Th style={{ fontWeight: 500 }}>Role</Th>
                    <Th style={{ fontWeight: 500 }}>Permission</Th>
                    {isSubExists &&
                      subPermissions?.map((subPermission) => (
                        <Th style={{ fontWeight: 500 }} key={subPermission?.id}>
                          {subPermission?.label}
                        </Th>
                      ))}
                    {!isSubExists && <Th> </Th>}
                  </Tr>
                  {Object.entries(permission.permission)?.map(([key, value]) => (
                    <Tr key={key}>
                      <Td style={{ width: '250px', height: '35px' }}>{key}</Td>
                      {/* {permission?.isOptionBoolean && (
                        <Td style={{ height: '35px' }}>
                          <input
                            className='permission-checkbox'
                            type='checkbox'
                            checked={value}
                            onChange={() =>
                              handleCheckBoxChange(
                                permission.permission,
                                key,
                                value,
                                permission?.id,
                              )
                            }
                          />
                        </Td>
                      )} */}
                      {permission?.isOptionBoolean && (
                        <Td style={{ height: '35px' }}>
                          <input
                            className='permission-checkbox'
                            type='checkbox'
                            checked={value}
                            onChange={() =>
                              handleCheckBoxChange(
                                permission.permission,
                                key,
                                value,
                                permission?.id,
                              )
                            }
                            disabled={permission?.isDisabled[key]}
                          />
                        </Td>
                      )}
                      {!permission?.isOptionBoolean && (
                        <Td style={{ height: '35px' }}>
                          <ReactSelect
                            name='permission'
                            styles={{
                              ...CommonDropDownStyle,
                              control: (styles, { isFocused }) => {
                                return {
                                  ...styles,
                                  backgroundColor: 'var(--bg-primary)',
                                  minHeight: '25px',
                                  width: '200px',
                                  borderColor: isFocused ? '#3454D1' : '#87909E',
                                  boxShadow: isFocused
                                    ? '0 0 0 3px rgba(52, 84, 209, 0.25)'
                                    : styles.boxShadow,
                                  '&:hover': {
                                    borderColor: isFocused
                                      ? '#3454D1'
                                      : styles['&:hover']?.borderColor || styles.borderColor,
                                  },
                                };
                              },
                              menu: (styles) => {
                                return {
                                  ...styles,
                                  width: '200px',
                                };
                              },
                            }}
                            components={{ DropdownIndicator: CustomDropdownIndicator }}
                            getOptionLabel={(option) => {
                              return option?.label;
                            }}
                            getOptionValue={(option) => {
                              return option?.value;
                            }}
                            filterOption={null}
                            options={permission?.options}
                            onChange={(selectedOption) => {
                              handleSelectionChange(
                                permission.permission,
                                key,
                                selectedOption.value,
                                permission?.id,
                              );
                            }}
                            // isLoading={roleLoading}
                            value={
                              permission.options?.find(
                                (option) => option.value === permission?.permission[key],
                              ) || null
                            } // Set the value based on project.company_id
                          />
                        </Td>
                      )}

                      {
                        isSubExists &&
                          value &&
                          subPermissions?.map((subPermission) => (
                            <Td key={subPermission?.id} style={{ height: '35px' }}>
                              {!subPermission?.isOptionBoolean && (
                                <ReactSelect
                                  name='permission'
                                  styles={{
                                    ...CommonDropDownStyle,
                                    control: (styles, { isFocused }) => {
                                      return {
                                        ...styles,
                                        backgroundColor: 'var(--bg-primary)',
                                        minHeight: '25px',
                                        width: '200px',
                                        borderColor: isFocused ? '#3454D1' : '#87909E',
                                        boxShadow: isFocused
                                          ? '0 0 0 3px rgba(52, 84, 209, 0.25)'
                                          : styles.boxShadow,
                                        '&:hover': {
                                          borderColor: isFocused
                                            ? '#3454D1'
                                            : styles['&:hover']?.borderColor || styles.borderColor,
                                        },
                                      };
                                    },
                                    menu: (styles) => {
                                      return {
                                        ...styles,
                                        width: '200px',
                                      };
                                    },
                                  }}
                                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                                  getOptionLabel={(option) => {
                                    return option?.label;
                                  }}
                                  getOptionValue={(option) => {
                                    return option?.value;
                                  }}
                                  filterOption={null}
                                  options={subPermission?.options}
                                  onChange={(selectedOption) => {
                                    handleSelectionChange(
                                      subPermission.permission,
                                      key,
                                      selectedOption.value,
                                      subPermission?.id,
                                    );
                                  }}
                                  // isLoading={roleLoading}
                                  value={
                                    subPermission.options?.find(
                                      (option) => option.value === subPermission?.permission[key],
                                    ) || null
                                  } // Set the value based on project.company_id
                                />
                              )}
                            </Td>
                          ))
                        // <Td>
                        //   {!subPermissions?.isOptionBoolean && (
                        //     <ReactSelect
                        //       name='permission'
                        //       styles={DropDownStyle}
                        //       components={{ DropdownIndicator: CustomDropdownIndicator }}
                        //       getOptionLabel={(option) => {
                        //         return option?.label;
                        //       }}
                        //       getOptionValue={(option) => {
                        //         return option?.value;
                        //       }}
                        //       filterOption={null}
                        //       options={subPermissions?.options}
                        //       // onChange={(selectedOption) => {
                        //       //   handleChange({
                        //       //     target: {
                        //       //       name: 'role',
                        //       //       value: selectedOption.number,
                        //       //     },
                        //       //   });
                        //       // }}
                        //       // isLoading={roleLoading}
                        //       // value={
                        //       //   roleData?.data?.find((option) => option.number === employee?.role) || null
                        //       // } // Set the value based on project.company_id
                        //     />
                        //   )}
                        // </Td>
                      }
                      {!isSubExists && <Td></Td>}
                    </Tr>
                  ))}
                </Table>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
