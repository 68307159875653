import { X } from 'lucide-react';
import React from 'react';
import { Task } from '../Task';
import { Modal } from '../_components';

function TaskSingleViewModal({ handleClose = () => {}, taskId = null, tab = 'description' }) {
  return (
    <Modal.Container>
      <Modal.View className={'task-modal-view'}>
        <div className='d-flex justify-content-end close-button-container'>
          <div
            className='close-button me-2'
            onClick={() => handleClose({ modal: false, data: {} })}
          >
            <X size={20} />
          </div>
        </div>
        <div className='task-body-view'>
          <Task id={taskId} tab={tab} breadCrumbs={false} />
        </div>
      </Modal.View>
    </Modal.Container>
  );
}

export default TaskSingleViewModal;
