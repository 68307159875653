import React, { useState } from 'react';
import { POSITION } from '../../_constants/contextMenu.constants';
import useOutsideClickHandler from '../../Hook/useOutsideClickHandler';
import './clickable-select.css';
/***
 * Will created a clickable picker. Pass the content as children
 * @param props
 * @param props.children  - THe content to render on click
 */
function ClickableSelect({
  children,
  label,
  icon,
  shouldReplace = false,
  contentClassName = '',
  customClass = null,
  errorMsg = '',
  isRequired = false,
  width = 'auto',
  containerWidth = 'auto',
  position = POSITION.RIGHT,
  hasValue = false,
  isHidden = false,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { containerRef } = useOutsideClickHandler({
    onClose: () => setIsOpen(false),
    customClassToCheck: customClass,
  });

  if (isHidden) {
    return null;
  }

  if (shouldReplace) {
    if (!isOpen) {
      return (
        <div
          className=' cursor-pointer temp-border-button d-flex align-items-center justify-content-center'
          style={{ width }}
          ref={containerRef}
          onClick={() => setIsOpen(true)}
        >
          <IconWithLabel label={label} icon={icon} hasValue={hasValue} />
        </div>
      );
    }
    return (
      <div style={{ width: containerWidth }} ref={containerRef}>
        {children}
      </div>
    );
  }

  return (
    <div>
      <div
        className={`position-relative temp-border-button ${
          isOpen ? 'dark-border' : ''
        } cursor-pointer d-flex align-items-center justify-content-center`}
        style={{
          border: isRequired ? '1px solid red' : '0.5px solid var(--border-color)',
          width,
        }}
        ref={containerRef}
        onClick={() => setIsOpen(true)}
      >
        <IconWithLabel label={label} icon={icon} hasValue={hasValue} />
        {isOpen && (
          <div
            className={`${contentClassName} clickable-select-content`}
            style={{
              width: containerWidth,
              left: position === POSITION.RIGHT ? 0 : 'auto',
              right: position === POSITION.LEFT ? 0 : 'auto',
            }}
          >
            {children}
          </div>
        )}
      </div>
      {isRequired && Boolean(errorMsg) && <div className='error-block fz-13px-rem'>{errorMsg}</div>}
    </div>
  );
}

const IconWithLabel = ({ icon, label, hasValue, labelColor = '' }) => {
  return (
    <div
      className={`d-flex align-items-center  ${
        hasValue ? 'selected-clickable-icon-label' : 'clickable-icon-label'
      }`}
      style={{ gap: 7 }}
    >
      <div className='d-flex'>{icon}</div>
      <div
        className={`${hasValue ? 'selected-clickable-label' : 'clickable-label'} no-wrap`}
        style={{ color: labelColor }}
      >
        {label}
      </div>
    </div>
  );
};

export { IconWithLabel };

export default ClickableSelect;
