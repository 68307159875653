/* eslint-disable no-unused-vars */
// AddWidgetModal.js
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../_components';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../../_components/SelectionDropDownIcon';
import { CommonDropDownStyle } from '../../_constants';
import { miscService, projectService } from '../../_services';
import { templateService } from '../../_services/template.service';
import { GlobalContext } from '../../Utils';
import { Cancel, LoadingIcon, Tick } from '../../Utils/SvgIcons';
import './widget.css';
import { widgetIcons } from './widgetconstants';

const AddWidgetModal = ({
  user = {},
  isOpen,
  onClose = () => {},
  onSubmit = () => {},
  isSaving,
  currentLayoutId = null,
  editWidgetData = {},
  isEdit = false,
}) => {
  const { makeAlert, globalSettings } = useContext(GlobalContext);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExistProject =
    menuSettings?.labels && menuSettings?.labels.some((label) => label.default_name === 'project');

  const [widget, setWidget] = useState({
    size: ['1', '2', '3'],
  });

  useEffect(() => {
    if (isEdit) {
      setWidget({
        ...widget,
        ...editWidgetData,
        size: ['1', '2', '3'],
      });
      if (editWidgetData?.project_id) {
        setSelectedProject({ name: editWidgetData?.project_name, id: editWidgetData?.project_id });
      }
      if (editWidgetData?.template_id) {
        setSelectedTemplate({
          name: editWidgetData?.template_name,
          id: editWidgetData?.template_id,
        });
      }
    }
  }, [isEdit]);
  const {
    data: widgetList = [],
    isLoading: widgetListLoading,
    refetch,
  } = useQuery({
    queryKey: ['widgets-presets', currentLayoutId],
    queryFn: () => miscService.getAllWidgetPresets({ id: currentLayoutId }),
    select: (resp) => {
      const res = resp?.data?.map((item) => {
        return {
          ...item,
          label: item?.title,
          value: item?.name,
        };
      });
      return res;
    },
    enabled: user?.id ? true : false,
  });

  const {
    data: projectData,
    error,
    isLoading: projectLoading,
  } = useQuery({
    queryKey: ['projectList-widget'],
    queryFn: () =>
      projectService.projectListAdvanced({
        select: ['id', 'name', 'start_date', 'end_date', 'status'],
        pageVo: {
          pageNo: 1,
          noOfItems: 10,
        },
        // user_id: user.id,
      }),
    select: (data) => {
      const response = data.data.rows;
      return response;
    },
    enabled: widget?.filters?.projectSelect && hasExistProject,
    throwOnError: (err) => {
      makeAlert(err.message, 'error');
    },
  });

  // position to get the current template
  const {
    data: templateData,
    isLoading: templateLoading,
    error: templateFetchError,
  } = useQuery({
    queryKey: ['templateList', hasExistProject, widget?.project_id],
    queryFn: () =>
      templateService.getTemplateList({
        select: ['id', 'name', 'is_default'],
        project_id: widget?.project_id ? widget?.project_id : null,
        pageVo: { sort: 'is_default', sort_order: 'DESC' },
      }),
    select: (data) => data?.data?.rows,
    refetchOnWindowFocus: false,
  });

  const handleWidget = (e) => {
    const { name, value } = e.target;
    if (name == 'size') {
      const position = widget?.position;
      setWidget((prev) => ({ ...prev, position: { ...position, w: parseInt(value) } }));
      return;
    }
    setWidget({ ...widget, [name]: value });
  };

  const handleWidgetSelect = (value) => {
    setWidget(value);
  };

  // handle template and project filters
  const handleProjectAndTemplateSelect = (name, value) => {
    if (name === 'project_id') {
      setWidget((prev) => ({
        ...prev,
        [name]: value?.id,
        project_name: value?.name,
        template_id: null,
      }));
      setSelectedProject(value);
      return;
    }
    setWidget((prev) => ({
      ...prev,
      template_name: value?.name,
      [name]: value?.id,
    }));
    setSelectedTemplate(value);
  };

  const handleWidgetSubmit = () => {
    if (!widget?.position || !widget?.title || !widget?.name) {
      makeAlert('Please fill in all fields', 'error');
      return;
    }
    if (widget?.name == 'status_count' && widget?.project_id && !widget?.template_id) {
      makeAlert('Please select template', 'error');
      return;
    }
    if (!isEdit) {
      if (widget?.project_id) {
        widget.title = widget.title + ` - ${widget?.project_name ? widget?.project_name : ''}`;
      }
      if (widget?.template_id) {
        widget.title = widget.title + ` - ${widget?.template_name ? widget?.template_name : ''}`;
      }
      if (widget?.filters?.userSelect) {
        widget.title =
          widget.title +
          ` ${
            widget?.userType
              ? ' - ' + widget?.userType
              : widget?.project_id || widget?.template_id
              ? ''
              : ' - Team'
          }`;
      }
    }

    onSubmit(widget);
  };

  if (widgetListLoading) {
    return (
      <div className='loading-wrapper'>
        <LoadingIcon size={70} />
      </div>
    );
  }
  if (!isOpen) return null;

  const GetWidgetFormatOptionLabel = ({ data, metadata }) => {
    if (metadata?.context == 'value' && !data?.name) {
      return 'Choose';
    }
    return (
      <div className='widget-format-option-container'>
        {data?.name && (
          <div className='widget-drop-icon-container'>{widgetIcons?.[data?.name]}</div>
        )}
        <div className='widget-format-option-label'>{data.title}</div>
      </div>
    );
  };
  const handleTitleChange = (e) => {
    setWidget({ ...widget, title: e.target.value });
  };

  return (
    <div className='widget-overlay'>
      <div className='modal_header'>
        <h3 className='add-widget-heading'>{isEdit ? 'Update ' : 'Add '}Widget</h3>
      </div>
      <div className='modal_view_content'>
        <div className='form-group mt-2'>
          <label className='widget-label'>{isEdit ? 'Title' : 'Type'}</label>
          {!isEdit ? (
            <SearchableQuerySelect
              queryFn={miscService.getAllWidgetPresets}
              initialValue={widgetList}
              getFunctionParams={(searchKey) => ({
                searchKey,
              })}
              isCustomFunction
              isLoading={widgetListLoading}
              queryKey='widgets-presets'
              formatOptionLabel={(data, metadata) => (
                <GetWidgetFormatOptionLabel data={data} metadata={metadata} />
              )}
              classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
              styles={CommonDropDownStyle}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              placeholder={`Choose `}
              onChange={(val) => {
                handleWidgetSelect(val);
              }}
              isDisabled={isEdit}
              value={[widget]}
              getOptionLabel={(option) => option?.label}
              getOptionValue={(option) => option?.name}
            />
          ) : (
            <input
              className='form-control'
              type='text'
              name='title'
              value={widget?.title}
              onChange={handleTitleChange}
            />
          )}
        </div>
        <div className='form-group mt-2'>
          <label className='widget-label'>Columns</label>
          <div className='columns-options'>
            <button
              value={'1'}
              onClick={(e) => {
                handleWidget(e);
              }}
              name='size'
              className={widget?.position?.w == 1 ? 'active' : ''}
              disabled={!widget?.size?.includes(1)}
              style={{ cursor: widget?.size?.includes(1) ? 'pointer' : 'not-allowed' }}
            >
              1 columns
            </button>
            <button
              value={'2'}
              onClick={(e) => {
                handleWidget(e);
              }}
              name='size'
              className={widget?.position?.w == 2 ? 'active' : ''}
              disabled={!widget?.size?.includes(2)}
              style={{ cursor: widget?.size?.includes(2) ? 'pointer' : 'not-allowed' }}
            >
              2 columns
            </button>
            <button
              value={'3'}
              onClick={(e) => {
                handleWidget(e);
              }}
              name='size'
              className={widget?.position?.w == 3 ? 'active' : ''}
              disabled={!widget.size?.includes(3)}
              style={{ cursor: widget?.size?.includes(3) ? 'pointer' : 'not-allowed' }}
            >
              3 columns
            </button>
          </div>
        </div>
        {projectLoading && (
          <div className='loading-wrapper'>
            <LoadingIcon size={70} />
          </div>
        )}
        {hasExistProject && widget?.filters?.projectSelect && (
          <div className='form-group mt-2'>
            <label className='widget-label'>Project</label>
            <SearchableQuerySelect
              queryFn={projectService.projectListAdvanced}
              initialValue={projectData}
              getFunctionParams={(searchKey) => ({
                searchKey,
                select: ['id', 'name', 'start_date', 'end_date', 'status'],
                pageVo: {
                  pageNo: 1,
                  noOfItems: 10,
                },
              })}
              isCustomFunction
              isLoading={projectLoading}
              value={selectedProject}
              queryKey='projectList-widget'
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
              styles={CommonDropDownStyle}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              placeholder={`Choose `}
              onChange={(val) => {
                handleProjectAndTemplateSelect('project_id', val);
              }}
              isClearable
            />
          </div>
        )}
        {widget?.filters?.templateSelect && (
          <div className='form-group mt-2'>
            <label className='widget-label'>Template</label>
            <SearchableQuerySelect
              queryFn={templateService.getTemplateList}
              initialValue={templateData}
              getFunctionParams={(searchKey) => ({
                searchKey,
                select: ['id', 'name', 'is_default'],
                project_id: widget?.project_id ? widget?.project_id : null,
                pageVo: { sort: 'is_default', sort_order: 'DESC' },
              })}
              isCustomFunction
              value={selectedTemplate}
              isLoading={templateLoading}
              queryKey={['templateList', hasExistProject]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
              styles={CommonDropDownStyle}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              placeholder={`Choose `}
              onChange={(val) => {
                handleProjectAndTemplateSelect('template_id', val);
              }}
              isClearable
            />
          </div>
        )}
        {widget?.filters?.userSelect && (
          <div className='form-group mt-2'>
            <label className='widget-label'>User Type</label>
            <div className='radio-group'>
              <label className='widget-label-radio'>
                <input
                  type='radio'
                  name='userType'
                  value='personal'
                  checked={widget?.userType === 'personal'}
                  onChange={handleWidget}
                />{' '}
                Personal
              </label>
              <label className='widget-label-radio'>
                <input
                  type='radio'
                  name='userType'
                  value='team'
                  checked={widget?.userType === 'team'}
                  onChange={handleWidget}
                />{' '}
                Team
              </label>
            </div>
          </div>
        )}
        {widget.name == 'calendar' && (
          <div className='form-group mt-2'>
            <label className='widget-label'>View types</label>
            <div className='columns-options'>
              <button
                value={'week'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'week' ? 'active' : ''}
              >
                Week
              </button>
              <button
                value={'day'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'day' ? 'active' : ''}
              >
                Day
              </button>
              <button
                value={'month'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'month' ? 'active' : ''}
              >
                Month
              </button>
              <button
                value={'team'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'team' ? 'active' : ''}
              >
                Team
              </button>
            </div>
          </div>
        )}
      </div>

      <div className={`modal_footer`}>
        <div className='d-flex gap-2'>
          <Button.Container className='cancel-btn' handleOk={onClose}>
            <Cancel />
            <Button.Title title='Cancel' />
          </Button.Container>
          <Button.Container type='submit' handleOk={handleWidgetSubmit}>
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isSaving} color='#FFF' size={20} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
};

export default AddWidgetModal;
