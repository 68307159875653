import { ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import IconSelector from './IconSelector';
import './iconInputSelector.css';

function InputWithIcons({
  iconList = [],
  inputValue = undefined,
  onInputChange = () => {},
  onIconChange = () => {},
  findIcon = () => {},
  extractId,
  getIconUrl = () => {},
  inputVisibility = true,
  iconContainerClass = 'icon-container',
  inputIconWrapper = 'input-icon-wrapper',
  ...rest
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const filteredIcon = (icon) => findIcon(icon);
  const selectedIconObject = iconList.find(filteredIcon);
  const selectedIconUrl = getIconUrl
    ? selectedIconObject
      ? getIconUrl(selectedIconObject)
      : getIconUrl(iconList[0]) //Take first index item, if no icon is selected
    : selectedIconObject;

  return (
    <div className={`position-relative d-flex ${inputIconWrapper}`}>
      {/* Icon Selector */}
      <div
        className={`d-flex ${iconContainerClass}`}
        role='button'
        onClick={() => setIsPreviewOpen((prev) => !prev)}
      >
        <img src={selectedIconUrl}  className='sidebar-img-icon'/>
        {/* Arrow to toggle the preview */}
        <ChevronDown size={15} />
      </div>
      {/* Input */}
      {inputVisibility && (
        <input
          value={inputValue}
          type='text'
          className='w-100'
          onChange={onInputChange}
          {...rest}
        />
      )}
      {/* <input value={inputValue} type='text' className='w-100' onChange={onInputChange} {...rest} /> */}
      <IconSelector
        iconList={iconList}
        onIconChange={(icon) => {
          onIconChange(icon);
          setIsPreviewOpen(false);
        }}
        getIconUrl={getIconUrl}
        isVisible={isPreviewOpen}
        extractId={extractId}
        onClose={() => {
          setIsPreviewOpen(false);
        }}
      />
    </div>
  );
}

export default InputWithIcons;
