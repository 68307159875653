const getPriorityLabelStyles = () => {
  return {
    valueContainer: (defaultStyle) => ({ ...defaultStyle, padding: 0 }),
  };
};
const getPriorityLabelClassNames = (hasValue = false) => {
  return {
    menuPortal: () => 'z-index-100 fz-14px react-select-portal',
    menu: () => 'w-fit-content',
    input: () => 'p-0 m-0',
    control: () => 'border-0',
    singleValue: () => (hasValue ? 'selected-clickable-label' : 'clickable-label'),
  };
};

const getAddableAvatarClassNames = () => {
  return {
    control: () => 'stacked-image-add-controller',
    multiValue: () => 'avatar-n-remove-container',
    valueContainer: () => 'select-addable-value-container',
  };
};

const getAddableAvatarStyles = (selectedUsers) => {
  return {
    container: (defaultStyles) => {
      return {
        ...defaultStyles,
        width: '100%',
        height: '100%',
        padding: selectedUsers.length > 0 ? '0 2px' : '0 0 0 15px',
      };
    },
    control: (defaultStyles) => {
      return {
        ...defaultStyles,
        backgroundColor: 'transparent',
        border: 'none',
        height: '100%',
        minHeight: 2,
      };
    },
    indicatorsContainer: () => {
      return { display: 'none' };
    },
    menu: (defaultStyles) => {
      return {
        ...defaultStyles,
        backgroundColor: 'var(--bg-primary)',
        boxShadow: 'none',
        border: 'none',
        width: '100%',
        borderRadius: 0,
        zIndex: '99999',
        margin: `3px 0 0 ${selectedUsers.length > 0 ? '16px' : '-10px'}`,
      };
    },
    valueContainer: (defaultStyles) => {
      return {
        ...defaultStyles,
        flexWrap: 'nowrap',
        overflow: 'auto',
        scrollbarWidth: 'none',
        height: '100%',
        padding: 0,
      };
    },
    multiValue: (defaultStyles) => {
      return {
        ...defaultStyles,
        backgroundColor: 'transparent',
        minWidth: 'none',
        position: 'relative',
        color: 'var(--icon-light)',
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontWeight: '500',
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        cursor: 'pointer',
        color: isFocused ? '#2A2E34' : 'var(--icon-light)',
        '&:hover': {
          color: '#2A2E34',
        },
      };
    },
  };
};

export const reactSelectUtils = {
  getPriorityLabelClassNames,
  getPriorityLabelStyles,
  getAddableAvatarClassNames,
  getAddableAvatarStyles,
};
