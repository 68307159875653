/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Utils/Contexts';
import { Button } from '../_components';
import { settingsService } from '../_services';
import { CHAT_LABELS } from './SettingsLabels';
import { ToggleWithHeading } from './UtilsInSettings';

export function ChatSettings() {
  const [query] = useState({ select: ['name', 'labels'], name: 'chat-settings' });

  const {
    data: chatSettingsData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['chat-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  return (
    <section className='common_contain mt-1'>
      {isLoading && <div>Loading...</div>}
      {isSuccess && <Chatform initialData={chatSettingsData?.labels} />}
    </section>
  );
}

const Chatform = ({ initialData }) => {
  const isMutating = useIsMutating();
  const queryClient = useQueryClient();
  const { makeAlert } = useContext(GlobalContext);

  const [chatSettings, setChatSettings] = useState(initialData || CHAT_LABELS);

  const [hasChanged, setHasChanged] = useState(false);
  useEffect(() => {
    // Check for changes in generalSettings whenever it updates
    setHasChanged(JSON.stringify(chatSettings) !== JSON.stringify(initialData || CHAT_LABELS));
  }, [chatSettings]);

  const handleToggle = (id, value) => {
    setChatSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value: !value } : label)),
    );
  };

  // chat settings update
  const handleSettingUpdate = async (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'chat-settings';

    await settingsService.settingsAdd(newQuery);
    makeAlert('Updated');
    setHasChanged(false);
    return newQuery;
  };

  // --react query fot mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: handleSettingUpdate,

    onSuccess: (data) => {
      queryClient.setQueryData(['chat-settings'], (old) => {
        let oldData = { ...old };
        oldData.data[0] = data;
        return oldData;
      });
    },
  });

  return (
    <>
      {chatSettings?.map((CHAT) => {
        if (CHAT.type === 'toggle') {
          return (
            <ToggleWithHeading
              headStyle={'chat-head'}
              className='sub_container'
              key={CHAT?.id}
              id={CHAT?.id}
              handleToggle={() => handleToggle(CHAT?.id, CHAT.value)}
              isChecked={CHAT.value}
              heading={CHAT.label}
              description={CHAT?.description}
            />
          );
        }
      })}

      {hasChanged && (
        <Button.Container className='sett-save-btn' handleOk={() => handleMutate(chatSettings)}>
          <Button.Title title={'Save'} />
          <Button.Loading isLoading={isMutating} />
        </Button.Container>
      )}
    </>
  );
};
